/** @format */

import axios from "axios";
import { sessionService } from "redux-react-session";
import { logout } from "redux/actions";
import { logoutAdminSession } from "redux/services";
import { hostConfig } from "../config/hostConfig";

const id = localStorage.getItem("id") || "";
const rftk = localStorage.getItem("refresh_token");

export const handleLogout = (e, dispatch) => {
	e.preventDefault();
	if (window.confirm("Are you sure you want to logout?")) {
		dispatch(
			logout(() => {
				localStorage.clear();
				sessionService
					.deleteSession()
					.then(() => sessionService.deleteUser())
					.then(() => {
						window.location.href = "/";
					});
			}),
		);
	}
};

export const logoutGenuinly = async () => {
	axios
		.post(
			`${hostConfig.apiBaseUrl}/api/users/logout/admin`,
			{
				xauth: rftk,
				aid: id,
			},
			{
				headers: {
					"x-auth": rftk,
				},
			},
		)
		.then((res) => {
			if (res.status === 200) {
				localStorage.clear();
				sessionService
					.deleteSession()
					.then(() => sessionService.deleteUser())
					.then(() => {
						window.location.href = "/";
						window.location.reload();
					});
			} else {
				localStorage.clear();
				sessionService
					.deleteSession()
					.then(() => sessionService.deleteUser())
					.then(() => {
						window.location.href = "/";
						window.location.reload();
					});
			}
		});
};
