/** @format */

import React, { useState, useEffect } from "react";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import {
	getWallets,
	getUserRequest,
	updateUserRequest,
	addMoneyToWallet,
} from "../../redux/actions";
import { toastr } from "react-redux-toastr";

// reactstrap components
import {
	Card,
	CardHeader,
	Container,
	Row,
	Button,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Label,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { getTransactionHistory } from "../../redux/actions";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	TablePagination,
} from "@material-ui/core";

const CommissionRequests = (props) => {
	// console.log(props, "props");
	const { walletsList } = props.wallets;
	let walletData = walletsList.filter((wallet) => {
		return wallet?.phone_number;
	});
	const filterOptions = createFilterOptions({
		ignoreCase: false,
		ignoreAccents: false,
	});
	const { transactionHistory, userRequests, transactions, totalCount } = props.transactionHistory;
	// console.log(userRequests, "transactionHistory");
	const dispatch = useDispatch();
	const [userData, setUserData] = useState(userRequests);
	const [idsArray, setIdsArray] = useState([]);

	const [addMoneyArray, setAddMoneyArray] = useState([]);
	const [state, setState] = useState({
		wallet_id: "",
		transaction_mode: "commission",
		transaction_status: "pending",
		transaction_type: "credit",
		amount: null,
		password: null,
		submitted: false,
	});
	const [filter, setFilter] = useState({
		filterS: {
			transaction_type: "",
			transaction_status: "pending",
			transaction_mode: "commission",
			phone_number: "",
			amount: "",
			createdAt: "",
			sortField: "_id",
			pageNumber: page || 1,
			pageSize: rowsPerPage || 10,
			sortOrder: -1,
		},
	});
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		// getUserData({ transaction_type: "credit", transaction_status: "" });
		getUserData({
			...filter.filterS,
			pageNumber: page || 1,
			pageSize: rowsPerPage || 10,
		});
		dispatch(
			getWallets({}, (errors, res) => {
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	}, []);

	useEffect(() => {
		getUserData({
			...filter.filterS,
			pageNumber: page || 1,
			pageSize: rowsPerPage || 10
		});
	}, [page, rowsPerPage]);

	useEffect(() => {
		setUserData({ requestList: userRequests, totalCount: totalCount })
	}, [transactions]);

	const handleChange = (e) => {
		const { id, value, name } = e.target;
		if (name) {
			setState((prevState) => ({
				...prevState,
				[name]: value,
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				[id]: value,
			}));
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setState((prevState) => ({
			...prevState,
			submitted: true,
		}));
		let reqData = {
			wallet_id: state.wallet_id,
			amount: state.amount,
			transaction_status: state.transaction_status,
			transaction_type: state.transaction_type,
			transaction_mode: state.transaction_mode,
		};
		dispatch(
			addMoneyToWallet(reqData, (errors, res) => {
				console.log(res);
				if (res.data) {
					onUpdate(res?.data, "approved");
				}
				toastr.success(
					"Success",
					"Money Requested Successfully Please check and approve",
				);
				// dispatch(
				// 	getUserRequest({}, (errors, res) => {
				// 		dispatch({ type: "LOADING_SUCCESS" });
				// 	}),
				// );
				getUserData({
					...filter.filterS,
					pageNumber: page || 1,
					pageSize: rowsPerPage || 10,
				});
			}),
		);
	};
	const handleSubmitWithdrawMoney = (e) => {
		e.preventDefault();
		setState((prevState) => ({
			...prevState,
			submitted: true,
		}));
		let reqData = {
			wallet_id: state.wallet_id,
			amount: state.amount,
			transaction_status: state.transaction_status,
			transaction_type: "debit",
			transaction_mode: "withdraw",
		};
		dispatch(
			addMoneyToWallet(reqData, (errors, res) => {
				console.log(res);
				if (res.data) {
					onUpdate(res?.data, "approved");
				}
				// toastr.success(
				// 	"Success",
				// 	"Money Requested Successfully Please check and approve",
				// );

				// dispatch(
				// 	getUserRequest({}, (errors, res) => {
				// 		dispatch({ type: "LOADING_SUCCESS" });
				// 	}),
				// );
				getUserData({
					...filter.filterS,
					pageNumber: page || 1,
					pageSize: rowsPerPage || 10,
				});
			}),
		);
	};
	// const handleFilterChange = (e) => {
	//   e.preventDefault();
	//   var filterS = {
	//     status: e.currentTarget.getAttribute("dropdownvalue")
	//   }
	//   setFilter(prevState => ({
	//     ...prevState,
	//     filterS: { ...filterS }
	//   }));
	//   getUserData({
	//     transaction_type: e.currentTarget.getAttribute("dropdownvalue")
	//   })
	// };
	const onUpdate = (transaction, status) => {
		let updatData = {
			transaction_id: transaction._id,
			transaction_type: transaction.transaction_type,
			// transaction_status_admin: status,
			transaction_status: status,
			role: "admin",
		};

		if (idsArray?.length > 0) {
			dispatch(
				updateUserRequest(
					{
						role: "admin",
						idsArray: addMoneyArray,
					},
					(err, res) => {
						if (res.data.error) {
							toastr.success("error", res.data.message);
						} else {
							toastr.success("Success", "Approved", 400);
						}

						setIdsArray([]);
						setAddMoneyArray([]);

						setState({
							wallet_id: "",
							transaction_mode: "add",
							transaction_status: "pending",
							transaction_type: "credit",
							amount: "",
							password: null,
							submitted: false,
						});
						getUserData({
							...filter.filterS,
							pageNumber: page || 1,
							pageSize: rowsPerPage || 10,
						});
					},
				),
			);
		} else {
			dispatch(
				updateUserRequest(updatData, (err, res) => {
					if (res.data.message) {
						toastr.success("error", res.data.message);
					} else {
						toastr.success("Success", "Request Updated Successfully", 400);
					}

					setIdsArray([]);
					setAddMoneyArray([]);
					setState({
						wallet_id: "",
						transaction_mode: "add",
						transaction_status: "pending",
						transaction_type: "credit",
						amount: "",
						password: null,
						submitted: false,
					});
					getUserData({
						...filter.filterS,
						pageNumber: page || 1,
						pageSize: rowsPerPage || 10,
					});
				}),
			);
		}
	};
	const getUserData = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getUserRequest(query, (errors, res) => {
				// let dat = res?.data?.filter((item) => {
				// 	if (item?.transaction_mode === "commission") {
				// 		return item;
				// 	}
				// });
				// setUserData(dat);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};
	const status = {
		debit: "Debit",
		credit: "Credit",
	};
	const handleFilterChange = (e) => {
		e.preventDefault();
		const { id, value } = e.target;
		setFilter((prevState) => ({
			...prevState,
			filterS: {
				...filter.filterS,
				[id]: value,
			},
		}));
	};

	const getuserNamefromWalletId = (list) => {
		let data =
			walletsList?.length > 0 &&
			walletsList?.filter((item) => item?._id === list?.wallet_id?._id);

		var username = "";
		username =
			data?.length > 0 &&
			data[0]?.user_id?.first_name + " " + data[0].user_id?.last_name;
		return username || "NO FOUND";
	};

	const selectRowForAction = (e, dt = []) => {
		if (dt.length > 0) {
			if (e.target.checked) {
				let ids = [];
				let AddM = [];
				dt.forEach((item) => {
					let addMoneyReqData = {
						wallet_id: item.wallet_id?._id,
						amount: item.amount,
						transaction_status: "approved",
						transaction_type: item.transaction_type,
						transaction_mode: item.transaction_mode,
						transaction_id: item._id,
					};
					if (item?.transaction_status === "pending") {
						ids.push(item._id);
						AddM.push(addMoneyReqData);
					}
				});
				ids.length > 0 && setIdsArray(ids);
				AddM.length > 0 && setAddMoneyArray(AddM);
			} else {
				setIdsArray([]);
				setAddMoneyArray([]);
			}
		} else if (dt._id) {
			// console.log(dt);
			let addMoneyData = {
				wallet_id: dt.wallet_id?._id,
				amount: dt.amount,
				transaction_status: "approved",
				transaction_type: dt.transaction_type,
				transaction_mode: dt.transaction_mode,
				transaction_id: dt._id,
			};
			// console.log(id);
			let id = dt?._id;

			e.target.checked && setAddMoneyArray((prev) => [...prev, addMoneyData]);
			!e.target.checked && setAddMoneyArray((prev) => prev.filter((i) => i !== id));

			// ids below
			e.target.checked && setIdsArray((prev) => [...prev, id]);
			!e.target.checked && setIdsArray((prev) => prev.filter((i) => i !== id));
		}
	};

	const handleLoopOverCheckbox = (list) => {
		// console.log(idsArray.filter((i) => i === list?._id));
		let da = idsArray.filter((i) => i === list?._id);
		return (
			<FormGroup check>
				<Label check>
					<Input
						type='checkbox'
						id='checkbox'
						className='form-control'
						checked={da[0] === list?._id ? true : false}
						defaultChecked={da === list?._id ? true : false}
						onChange={(e) => selectRowForAction(e, list)}
					/>
				</Label>
			</FormGroup>
		);
	};

	// console.log("userData=>", userData);
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData.length) : 0;
	return (
		<>
			{/* <Header /> */}
			{/* Page content */}
			<Container className='mt-7' fluid>
				{/* Dark table */}
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-secondary shadow'>
							<CardHeader className='bg-transparent border-0 mt-7'>
								<h3 className='mb-0 text-center'>Commission</h3>
							</CardHeader>
							<CardBody></CardBody>
						</Card>
					</div>
				</Row>
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-default shadow'>
							<CardHeader className='bg-transparent border-0'>
								<h3 className='text-white mb-0'>Commissions Payments</h3>
								<div
									className='d-flex mt-2'
									style={{ flexDirection: "column" }}>
									<InputGroup size='xl' className='ml-2 w-100 mt-2'>
										<Input
											id='phone_number'
											type='text'
											name='phone_number'
											value={filter.filterS.phone_number || ""}
											onChange={handleFilterChange}
											placeholder='Search Phone Number'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											id='amount'
											type='number'
											name='amount'
											value={filter.filterS.amount || ""}
											onChange={handleFilterChange}
											placeholder='Search Amount'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											id='createdAt'
											type='date'
											name='createdAt'
											value={filter.filterS.createdAt || ""}
											onChange={handleFilterChange}
											placeholder='Search for Date'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='select'
											value={filter.filterS.transaction_type}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_type'
											name='transaction_type'
											required>
											<option key='select' value=''>
												Select Transaction Type
											</option>
											{/* <option key='debit' value='debit'>
												Debit
											</option> */}
											<option key='credit' value='credit'>
												Credit
											</option>
										</Input>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='select'
											autoComplete='new-name'
											value={filter.filterS.transaction_mode}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_mode'
											placeholder='Select Payment Method'
											name='transaction_mode'
											required>
											<option key='select' value=''>
												Select Payment Method
											</option>
											<option key='phonepe' value='phonepe'>
												phonepe
											</option>
											<option key='add' value='add'>
												add
											</option>
											<option key='gpay' value='gpay'>
												Gpay
											</option>
											<option key='paytm' value='paytm'>
												Paytm
											</option>
											<option key='card' value='card'>
												Card
											</option>
											<option key='bets' value='bets'>
												Bets
											</option>
											<option key='commission' value='commission'>
												Commission
											</option>
											<option key='refer' value='refer'>
												Referral
											</option>
											<option key='win' value='win'>
												Win
											</option>
										</Input>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='select'
											autoComplete='new-name'
											value={filter.filterS.transaction_status}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_status'
											placeholder='Select Transaction Status'
											name='transaction_status'
											required>
											<option key='' value=''>
												Select Transaction Status
											</option>
											<option key='pending' value='pending'>
												pending
											</option>
											<option key='approved' value='approved'>
												approved
											</option>
											<option key='rejected' value='rejected'>
												rejected
											</option>
										</Input>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='Button'
											onClick={() =>
												getUserData({
													...filter.filterS,
													pageNumber: page || 1,
													pageSize: rowsPerPage || 10,
												})
											}
											className='bg-default text-white'
											value={"Search"}></Input>
									</InputGroup>
								</div>
							</CardHeader>
							<TableContainer>
								<Table
									className='align-items-center table-dark table-flush'
									responsive>
									<TableHead>
										<TableRow>
											<TableCell scope='row' align='center'>
												<FormGroup check>
													<Label check>
														<Input
															type='checkbox'
															id='checkbox2'
															className='form-control'
															onChange={(e) => {
																let dt =
																	userData &&
																		userData?.requestList.length
																		? userData?.requestList
																		// 	.slice(
																		// 	page *
																		// 	rowsPerPage,
																		// 	page *
																		// 	rowsPerPage +
																		// 	rowsPerPage,
																		// )
																		: userData?.requestList;
																selectRowForAction(e, dt);
															}}
														/>{" "}
													</Label>
												</FormGroup>
											</TableCell>
											<TableCell scope='row' align='center'>
												<span className='mb-0 text-sm text-white'>
													Name
												</span>
											</TableCell>
											<TableCell className='text-white'>
												Phone
											</TableCell>

											<TableCell
												className='text-white'
												align='center'>
												Type
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Request Amount
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Payment Mode
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Wallet
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Date
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Status
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												{idsArray.length > 0 ? (
													<button
														className={"btn btn-success"}
														onClick={() =>
															onUpdate(addMoneyArray, "1")
														}>
														Approve{" "}
														{"(" + idsArray.length + ")"}
													</button>
												) : (
													"Action"
												)}
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{userData && userData?.requestList?.length
											? userData?.requestList
												// .slice(
												// 	page * rowsPerPage,
												// 	page * rowsPerPage + rowsPerPage,
												// )
												.map((list, index) => {
													return (
														<TableRow key={index + 1}>
															<TableCell
																scope='row'
																align='center'>
																<FormGroup check>
																	{list?.transaction_status ===
																		"pending" &&
																		handleLoopOverCheckbox(
																			list,
																		)}
																</FormGroup>
															</TableCell>
															<TableCell
																scope='row'
																align='center'>
																<span className='mb-0 text-sm text-white'>
																	{walletsList?.length >
																		0 &&
																		getuserNamefromWalletId(
																			list,
																		)}
																</span>
															</TableCell>
															<TableCell scope='row'>
																<span className='mb-0 ml-2 text-sm text-white'>
																	{
																		list
																			?.wallet_id
																			?.phone_number
																	}
																</span>
															</TableCell>
															{/* <TableCell
																	className='text-white'
																	align='center'>
																	{
																		list?.user
																			?.user_name
																	}
																</TableCell> */}
															<TableCell
																className='text-white'
																align='center'>
																{
																	list?.transaction_mode
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{list?.amount?.toFixed(2)}
															</TableCell>
															<TableCell
																className={`${list?.transaction_type ===
																	"debit"
																	? "text-danger"
																	: "text-success"
																	}`}
																align='center'>
																{
																	list?.transaction_type
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{
																	list?.wallet_id
																		?.total_amount?.toFixed(2)
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{moment(
																	list?.createdAt,
																).format(
																	"DD-MM-YYYY, h:mm a",
																)}
															</TableCell>
															{list?.transaction_status ===
																"rejected" ? (
																<TableCell
																	className='text-danger'
																	align='center'>
																	{
																		list?.transaction_status
																	}
																</TableCell>
															) : list?.transaction_status ===
																"pending" ? (
																<TableCell
																	className='text-warning'
																	align='center'>
																	{
																		list?.transaction_status
																	}
																</TableCell>
															) : (
																<TableCell
																	className='text-success'
																	align='center'>
																	{
																		list?.transaction_status
																	}
																</TableCell>
															)}
															<TableCell>
																<React.Fragment>
																	{list.transaction_status ===
																		"pending" && (
																			<>
																				<button
																					className={
																						"btn btn-success"
																					}
																					onClick={() =>
																						onUpdate(
																							list,
																							"approved",
																						)
																					}>
																					Approve
																				</button>
																				<button
																					className={
																						"btn btn-secondary"
																					}
																					style={{
																						marginLeft: 8,
																					}}
																					onClick={() =>
																						onUpdate(
																							list,
																							"rejected",
																						)
																					}>
																					Reject
																				</button>
																			</>
																		)}
																	{list.transaction_status ===
																		"approved" && (
																			<>
																				<button
																					className={
																						"btn btn-secondary"
																					}
																					style={{
																						marginLeft: 8,
																					}}
																					onClick={() =>
																						onUpdate(
																							list,
																							"rejected",
																						)
																					}>
																					Reject
																				</button>
																			</>
																		)}
																</React.Fragment>
															</TableCell>
														</TableRow>
													);
												})
											: ""}
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td>
												Total :{" "}
												{userData &&
													userData?.requestList?.length &&
													userData?.requestList
														// ?.slice(
														// 	page * rowsPerPage,
														// 	page * rowsPerPage +
														// 	rowsPerPage,
														// )
														.reduce(
															(totalValue, item) =>
																totalValue +
																Number(item?.amount),
															0,
														)
														.toFixed(2)}
											</td>
										</tr>
										{/* {emptyRows > 0 && (
											<TableRow
												style={{
													height: 53 * emptyRows,
												}}>
												<TableCell colSpan={6} />
											</TableRow>
										)} */}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TablePagination
												className='text-white'
												rowsPerPageOptions={[
													5,
													10,
													25,
													50,
													100,
													200,
													500,
													{ label: "All", value: totalCount },
												]}
												colSpan={5}
												count={totalCount}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onRowsPerPageChange={
													handleChangeRowsPerPage
												}
											/>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

function mapStateToProps(state) {
	return {
		user: state.session.user,
		wallets: state.wallets,
		transactionHistory: state.transactionHistory,
	};
}

export default withRouter(connect(mapStateToProps, {})(CommissionRequests));
