/**
 * Imports
 *
 * @format
 */

import { sessionService } from "redux-react-session";
import LogRocket from "logrocket";
import {
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	LOGIN_START,
	LOGOUT_SUCCESS,
	LOGOUT_FAILURE,
	LOGOUT_START,
	LOGOUT,
	REFRESH_SUCCESS,
	REFRESH_FAILURE,
	REFRESH_START,
	SELECTED_USER,
	USER_REGISTRATION_START,
	USER_REGISTRATION_SUCCESS,
	USER_REGISTRATION_FAILURE,
	USER_UPDATE_START,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_FAILURE,
	ADD_MONEY_WALLET_START,
	ADD_MONEY_WALLET_SUCCESS,
	ADD_MONEY_WALLET_FAILURE,
	FORGOT_PASSWORD_START,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_FAILURE,
	USER_GET_START,
	USER_GET_SUCCESS,
	USER_GET_FAILURE,
	USER_TRANSACTIONS_GET_START,
	USER_TRANSACTIONS_GET_SUCCESS,
	USER_TRANSACTIONS_GET_FAILURE,
	USER_DETAIL_GET_FAILURE,
	USER_DETAIL_GET_START,
	USER_DETAIL_GET_SUCCESS,
} from "../actionConstants";

import dispatchActionToReducer, {
	SimpleDispatchActionToReducer,
} from "../actionDispatcher";
import {
	login as loginService,
	logoutAdminSession as logoutAdminSessionService,
	refreshAdminSession as refreshAdminSessionService,
	registerUser as registerUserService,
	forgotPassword as forgotPasswordService,
	updateUser as updateProfileService,
	addMoneyToWallet as addMoneyToWalletService,
	getUser as getUserListService,
	getUserById as getUserListWithIdService,
	getFilteredUserList as getFilteredUserListService,
	getUserTransactions as getUserTransactionsService,
	changeUserStatus as changeUserStatusService,
	deleteUserServices as deleteUserServicess,
} from "../services";

export const login = (email, password, callback) => {
	return dispatchActionToReducer(
		loginService(email, password),
		LOGIN_START,
		LOGIN_SUCCESS,
		LOGIN_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				localStorage.setItem("refresh_token", res.data.refresh_token);
				sessionService
					.saveSession({
						sessionId: `${res.data.token}`,
						sessionToken: `${res.data.token}`,
					})
					.then(() => {
						sessionService
							.saveUser({
								...res.data,
							})
							.then(() => {
								if (callback) callback(res);
							});
					});
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const logoutAdminSessionAction = (data, callback) => {
	return dispatchActionToReducer(
		logoutAdminSessionService(data),
		LOGOUT_START,
		LOGOUT_SUCCESS,
		LOGOUT_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				sessionService.deleteSession().then(() => {
					sessionService
						.saveUser({
							...res.data,
						})
						.then(() => {
							if (callback) callback(res);
						});
				});
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const refreshAdminSessionServiceAction = (data, callback) => {
	return dispatchActionToReducer(
		refreshAdminSessionService(data),
		REFRESH_START,
		REFRESH_SUCCESS,
		REFRESH_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				sessionService
					.saveSession({
						sessionId: `${res.data.token}`,
						sessionToken: `${res.data.token}`,
					})
					.then(() => {
						sessionService
							.saveUser({
								...res.data,
							})
							.then(() => {
								if (callback) console.log("saveuser", res);
							});
					});
			} else {
				console.log("undefined response");
			}
		},
	);
};

export const logout = (callback) => {
	return SimpleDispatchActionToReducer(LOGOUT, null, callback);
};

export function register(body, callback) {
	return dispatchActionToReducer(
		registerUserService(body),
		USER_REGISTRATION_START,
		USER_REGISTRATION_SUCCESS,
		USER_REGISTRATION_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				sessionService
					.saveSession({
						sessionId: `${res.data.token}`,
						sessionToken: `${res.data.token}`,
					})
					.then(() => {
						sessionService
							.saveUser({
								...res.data,
							})
							.then(() => {
								if (callback) callback();
							});
					});
			} else {
				console.log("undefined response");
			}
		},
	);
}
export const updateProfile = (body, callback) => {
	return dispatchActionToReducer(
		updateProfileService(body),
		USER_UPDATE_START,
		USER_UPDATE_SUCCESS,
		USER_UPDATE_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				sessionService
					.saveUser({
						...res.data,
					})
					.then(() => {
						if (callback) callback(error, res);
					});
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const changeStatusOfUser = (body, callback) => {
	return dispatchActionToReducer(
		changeUserStatusService(body),
		USER_UPDATE_START,
		USER_UPDATE_SUCCESS,
		USER_UPDATE_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				sessionService
					.saveUser({
						...res.data,
					})
					.then(() => {
						if (callback) callback(error, res);
					});
			} else {
				console.log("undefined response");
			}
		},
	);
};
export function forgotPassword(email, callback) {
	return dispatchActionToReducer(
		forgotPasswordService(email),
		FORGOT_PASSWORD_START,
		FORGOT_PASSWORD_SUCCESS,
		FORGOT_PASSWORD_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(error, res);
			} else {
				console.log("undefined response");
			}
		},
		//callback
	);
}
export const getUserList = (queryParam, callback) => {
	return dispatchActionToReducer(
		getUserListService(queryParam),
		USER_GET_START,
		USER_GET_SUCCESS,
		USER_GET_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(error, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const getUserListWithId = (queryParam, callback) => {
	return dispatchActionToReducer(
		getUserListWithIdService(queryParam),
		USER_DETAIL_GET_START,
		USER_DETAIL_GET_SUCCESS,
		USER_DETAIL_GET_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(error, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};

export const getFilteredUserList = (reqData, callback) => {
	return dispatchActionToReducer(
		getFilteredUserListService(reqData),
		USER_GET_START,
		USER_GET_SUCCESS,
		USER_GET_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(error, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const getUserTransactions = (reqData, callback) => {
	return dispatchActionToReducer(
		getUserTransactionsService(reqData),
		USER_TRANSACTIONS_GET_START,
		USER_TRANSACTIONS_GET_SUCCESS,
		USER_TRANSACTIONS_GET_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(error, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};

export const setSelectedUser = (data, callback) => {
	return SimpleDispatchActionToReducer(SELECTED_USER, data, callback);
};
export const addMoneyToWallet = (body, callback) => {
	return dispatchActionToReducer(
		addMoneyToWalletService(body),
		ADD_MONEY_WALLET_START,
		ADD_MONEY_WALLET_SUCCESS,
		ADD_MONEY_WALLET_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				sessionService
					.saveUser({
						...res.data,
					})
					.then(() => {
						if (callback) callback(error, res);
					});
			} else {
				console.log("undefined response");
			}
		},
	);
};

export const deleteUserAction = (body, callback) => {
	// console.log("chnageUserStatusAction", body);
	return dispatchActionToReducer(
		deleteUserServicess(body),
		USER_UPDATE_START,
		USER_UPDATE_SUCCESS,
		USER_UPDATE_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				sessionService
					.saveUser({
						...res.data,
					})
					.then(() => {
						if (callback) callback(error, res);
					});
			} else {
				console.log("undefined response");
			}
		},
	);
};
