/** @format */

import axios from "axios";
import { sessionService } from "redux-react-session";
import hostConfig from "../../config/hostConfig";
import "regenerator-runtime/runtime";
import { toastr } from "react-redux-toastr";
import API from "./serviceList";
import { useDispatch } from "react-redux";
import { refreshAdminSession } from "./user";
import { refreshAdminSessionServiceAction } from "redux/actions/userActions";
import instance from "utils/interceptor";

export const apiCall = async ({ method, url, data = null, responseType }) => {
	const id = localStorage.getItem("id");
	const urls = `${hostConfig.apiBaseUrl}/api${url}`;
	// const refreshToken = localStorage.getItem("refresh_token");
	let dt;
	if (id) {
		dt = {
			...data,
			aid: id,
		};
	}

	dt = id ? dt : { ...data };

	function apiPromise(token) {
		const rftk = localStorage.getItem("refresh_token");
		const headers = {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: token,
			"x-auth": rftk,
		};

		// if (url === API.sessionclient) {
		// 	const rftk = localStorage.getItem("refresh_token");
		// 	headers["x-auth"] = rftk || "";
		// }
		// console.log(headers);
		// return instance[method](url, data, { headers }).then(function (response) {
		// 	// Alert.alert(JSON.stringify(response));
		// 	// console.log(`method- POST - ${url} res=`, response);
		// 	// console.log(response);
		// 	return response;
		// });

		return instance({
			method,
			url: urls,
			data: { ...dt },
			responseType,
			crossDomain: true,
			headers: {
				...headers,
			},
		});
	}

	return sessionService
		.loadSession()
		.then((currentSession) => {
			return currentSession.sessionToken;
		})
		.catch((err) => {
			console.log("API ERROR 1:", err);
			throw err;
		})
		.then((token) => {
			return apiPromise(token);
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			if (err && err.response && err.response.data) {
				console.log("API ERROR:", err.response.data);
				toastr.error("Error accessing this data", err);
				throw err.response.data;
			} else {
				throw err;
			}
		});
};

export const apiNoTokenCall = async ({ method, url, data = null, responseType }) => {
	const urls = `${hostConfig.apiBaseUrl}/api${url}`;
	function apiPromise() {
		return axios({
			method,
			url: urls,
			data: { ...data },
			responseType,
			crossDomain: true,
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			},
		});
	}
	return apiPromise()
		.then((res) => {
			console.log("res", res);
			localStorage.setItem("id", res?.data?._id || "");
			return res;
		})
		.catch((err) => {
			console.log("API ERROR:", err.response.data);
			// throw err.response.data.errors;
			throw err.response.data;
		});
};

function encodeUriComponentWithKey(key, value) {
	return `${key}=${encodeURIComponent(value)}`;
}
function createQueryParamComponent(key, value) {
	let csv = "";
	if (value) {
		if (Array.isArray(value)) {
			csv = value
				.map((valueItem) => encodeUriComponentWithKey(key, valueItem))
				.join("&");
		} else {
			csv = `${key}=${encodeURIComponent(value)}`;
		}
	}
	return csv;
}
export function createQueryParams(params) {
	const queryParams = Object.keys(params)
		.map((key) => createQueryParamComponent(key, params[key]))
		// Remove the null, zero, undefined and empty string values
		.filter(Boolean);
	if (queryParams.length) {
		return `?${queryParams.join("&")}`;
	}
	return "";
}
