/** @format */

import { apiCall, createQueryParams } from "../index";
import API from "../serviceList";

export function getTransactionHistory(queryParm) {
	let query = createQueryParams(queryParm);
	return apiCall({
		method: "GET",
		url: `${API.getTransactionHistory}${query}`,
	});
}
export function getUpiListData(queryParm) {
	let query = createQueryParams(queryParm);
	return apiCall({
		method: "GET",
		url: `${API.getUpiDataList}${query}`,
	});
}
export function getNewsListData(queryParm) {
	let query = createQueryParams(queryParm);
	return apiCall({
		method: "GET",
		url: `${API.getNewsDataList}${query}`,
	});
}
export function createNewsFirst(queryParm) {
	return apiCall({
		method: "POST",
		url: `${API.createNewsFirst}`,
		data: queryParm,
	});
}
export function createMessagesFirst(queryParm) {
	return apiCall({
		method: "POST",
		url: `${API.createMessagesFirst}`,
		data: queryParm,
	});
}
export function getMessagesListData(queryParm) {
	let query = createQueryParams(queryParm);
	return apiCall({
		method: "GET",
		url: `${API.getMessageService}${query}`,
	});
}
export function getCommissionList(queryParm) {
	let query = createQueryParams(queryParm);
	return apiCall({
		method: "GET",
		url: `${API.getCommissionList}${query}`,
	});
}
export function updateCommissionList(queryParm) {
	// let query = createQueryParams(queryParm);
	return apiCall({
		method: "POST",
		url: `${API.updateCommissionList}`,
		data: queryParm,
	});
}
export function deleteUpiIdData(reqData) {
	return apiCall({
		method: "DELETE",
		url: `${API.deleteUpiIdDataApi}`,
		data: reqData,
	});
}
export function updateUPIDataRequest(reqData) {
	return apiCall({
		method: "POST",
		url: `${API.updateUpiIdDataApi}`,
		data: reqData,
	});
}
export function createUpiId(requestData) {
	return apiCall({
		method: "POST",
		url: `${API.createUpiId}`,
		data: requestData,
	});
}
export function updateMessageService(requestData) {
	return apiCall({
		method: "POST",
		url: `${API.updateMessageService}`,
		data: requestData,
	});
}
export function updateNewsService(requestData) {
	return apiCall({
		method: "POST",
		url: `${API.updateNewsService}`,
		data: requestData,
	});
}
export function updateUserRequest(userData) {
	return apiCall({
		method: "PUT",
		url: `${API.approveRequest}`,
		data: userData,
	});
}
