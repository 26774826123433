/** @format */

import React, { useState, useEffect } from "react";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import {
	getWallets,
	updateUPIDataRequest,
	createUpidActions,
	getUpiDataList,
	deleteUpiData,
} from "../../redux/actions";
import { toastr } from "react-redux-toastr";

// reactstrap components
import {
	Card,
	CardHeader,
	Container,
	Row,
	Button,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { getTransactionHistory } from "../../redux/actions";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	TablePagination,
} from "@material-ui/core";

const AddUpiId = (props) => {
	// console.log(props, "props");
	const { walletsList } = props.wallets;
	let walletData = walletsList.filter((wallet) => {
		return wallet?.phone_number;
	});
	const filterOptions = createFilterOptions({
		ignoreCase: false,
		ignoreAccents: false,
	});
	// const { transactionHistory, userRequests } = props.transactionHistory;
	// console.log(userRequests, "transactionHistory");
	const dispatch = useDispatch();
	const [upiData, setUPIData] = useState([]);
	const [state, setState] = useState({
		submitted: false,
		upi_id: "",
		upi_name: "",
		phone: "",
	});
	const [filter, setFilter] = useState({
		filterS: {
			sttus: false,
			upi_id: "",
			upi_name: "",
			created_at: "",
			updated_at: "",
		},
	});
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		getUpiData({});
		dispatch(
			getWallets({}, (errors, res) => {
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	}, []);
	const handleChange = (e) => {
		const { id, value, name } = e.target;
		if (name) {
			setState((prevState) => ({
				...prevState,
				[name]: value,
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				[id]: value,
			}));
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setState((prevState) => ({
			...prevState,
			submitted: true,
		}));
		let reqData = {
			upi_id: state.upi_id,
			upi_name: state.upi_name,
			phone: state?.phone,
		};
		dispatch(
			createUpidActions(reqData, (res, errors) => {
				toastr.success(
					"Success",
					"UPI Added Requested Successfully Please check and Active",
				);
				dispatch(
					getUpiDataList({}, (errors, res) => {
						dispatch({ type: "LOADING_SUCCESS" });
					}),
				);
			}),
		);
	};

	const onUpdate = (e, list, status) => {
		let updatData = {
			...list,
			status: status,
		};
		dispatch(
			updateUPIDataRequest(updatData, (err, res) => {
				if (res.data.message) {
					toastr.success("error", res.data.message);
				} else {
					toastr.success("Success", "Request Updated Successfully");
				}
				getUpiData();
			}),
		);
	};
	const getUpiData = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getUpiDataList(query, (errors, res) => {
				// console.log(res);
				setUPIData(res?.data?.response);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};
	const status = {
		debit: "Debit",
		credit: "Credit",
	};
	const handleFilterChange = (e) => {
		e.preventDefault();
		const { id, value } = e.target;
		setFilter((prevState) => ({
			...prevState,
			filterS: {
				...filter.filterS,
				[id]: value,
			},
		}));
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - upiData.length) : 0;

	const onDelete = (e, ids) => {
		// console.log(e);

		e.preventDefault();
		let id = {
			_id: ids,
		};
		dispatch(
			deleteUpiData(id, (errors, res) => {
				if (res.data.message) {
					toastr.success("error", res.data.message);
				} else {
					toastr.success("Success", "UPI Deleted Successfully");
				}
				getUpiData({});
			}),
		);
	};

	return (
		<>
			{/* <Header /> */}
			{/* Page content */}
			<Container className='mt-7' fluid>
				{/* Dark table */}
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-secondary shadow'>
							<CardHeader className='bg-transparent border-0 mt-7'>
								<h3 className='mb-0 text-center'>
									Add UPI For User Account
								</h3>
							</CardHeader>
							<CardBody>
								<Form role='form'>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<TextField
												fullWidth
												type='text'
												variant='outlined'
												id='upi_name'
												placeholder='UPI Name'
												name='upi_name'
												value={state.upi_name}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.upi_name && (
											<div className='error'>Nme is required</div>
										)}
									</FormGroup>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<TextField
												fullWidth
												type='text'
												variant='outlined'
												id='upi_id'
												placeholder='Enter UPI Id'
												name='upi_id'
												value={state.upi_id}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.upi_id && (
											<div className='error'>
												Amount is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<TextField
												fullWidth
												type='text'
												variant='outlined'
												id='phone'
												placeholder='Enter Phone'
												name='phone'
												value={state.phone}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.phone && (
											<div className='error'>Phone is required</div>
										)}
									</FormGroup>
									<FormGroup className='mb-3'>
										{/* <InputGroup className='input-group-alternative'>
											<Select
												fullWidth
												type='select'
												value={state.transaction_type}
												onChange={handleChange}
												inputProps={{
													id: "transaction_type",
													"aria-label": "Without label",
												}}
												variant='outlined'
												id='transaction_type'
												name='transaction_type'>
												<option value='none' disabled>
													Select Transaction Type
												</option>
												<option value='credit'>Credit</option>
												<option value='debit'> Debit</option>
											</Select>
										</InputGroup> */}
										{state.submitted &&
											!state.transaction_type !== "none" && (
												<div className='error'>
													Transaction Type is required
												</div>
											)}
									</FormGroup>

									<div className='text-center'>
										<Button
											disabled={
												!(state.upi_id && state.upi_name)
												// state.transaction_type !== "none" &&
											}
											onClick={handleSubmit}
											className='my-4'
											color='primary'
											style={{
												backgroundColor: "#9b5de5",
												border: "none",
											}}
											type='button'>
											ADD UPI
										</Button>
									</div>
								</Form>
							</CardBody>
						</Card>
					</div>
				</Row>
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-default shadow'>
							<CardHeader className='bg-transparent border-0'>
								<h3 className='text-white mb-0'>Added UPIs</h3>
								<div
									className='d-flex mt-2'
									style={{ flexDirection: "column" }}>
									<InputGroup size='xl' className='ml-2 mt-2 w-100'>
										<Input
											id='phone_number'
											type='text'
											name='phone_number'
											value={filter.filterS.phone_number || ""}
											onChange={handleFilterChange}
											placeholder='Search Phone Number'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											id='amount'
											type='number'
											name='amount'
											value={filter.filterS.amount || ""}
											onChange={handleFilterChange}
											placeholder='Search Amount'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											id='createdAt'
											type='date'
											name='createdAt'
											value={filter.filterS.createdAt || ""}
											onChange={handleFilterChange}
											placeholder='Search for Date'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									{/* <InputGroup size='xl' className='ml-2 mt-2 w-100 ml-2'>
										<Input
											type='select'
											value={filter.filterS.transaction_type}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_type'
											name='transaction_type'
											required>
											<option key='select' value=''>
												Select Transaction Type
											</option>
											<option key='gpay' value='debit'>
												Debit
											</option>
											<option key='paytm' value='credit'>
												Credit
											</option>
										</Input>
									</InputGroup> */}
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											type='select'
											autoComplete='new-name'
											value={filter.filterS.transaction_mode}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_mode'
											placeholder='Select Payment Method'
											name='transaction_mode'
											required>
											<option key='select' value=''>
												Select Payment Method
											</option>
											<option key='gpay' value='gpay'>
												Gpay
											</option>
											<option key='paytm' value='paytm'>
												Paytm
											</option>
											<option key='card' value='card'>
												Card
											</option>
											<option key='bets' value='bets'>
												Bets
											</option>
											<option key='refer' value='refer'>
												Referral
											</option>
											<option key='win' value='win'>
												Win
											</option>
										</Input>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											type='select'
											autoComplete='new-name'
											value={filter.filterS.transaction_status}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_status'
											placeholder='Select Transaction Status'
											name='transaction_status'
											required>
											<option key='' value=''>
												Select Transaction Status
											</option>
											<option key='pending' value='pending'>
												pending
											</option>
											<option key='approved' value='approved'>
												approved
											</option>
											<option key='rejected' value='rejected'>
												rejected
											</option>
										</Input>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											type='Button'
											onClick={() =>
												getUpiData({ ...filter.filterS })
											}
											className='bg-default text-white'
											value={"Search"}></Input>
									</InputGroup>
								</div>
							</CardHeader>
							<TableContainer>
								<Table
									className='align-items-center table-dark table-flush'
									responsive>
									<TableHead>
										<TableRow>
											<TableCell
												className='text-white'
												align='center'>
												No.
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												UPI NAME
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												PHONE
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												UPI ID
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Created
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Updated
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Status
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Action
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{upiData && upiData.length
											? upiData
													.slice(
														page * rowsPerPage,
														page * rowsPerPage + rowsPerPage,
													)
													.map((list, index) => {
														return (
															<TableRow key={index + 1}>
																<TableCell
																	className='text-white'
																	align='center'>
																	{index + 1}
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	{list?.upi_name}
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	{list?.phone}
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	{list?.upi_id}
																</TableCell>

																<TableCell
																	className='text-white'
																	align='center'>
																	{moment(
																		list?.created_at,
																	).format(
																		"DD-MM-YYYY, h:mm a",
																	)}
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	{moment(
																		list?.updated_at,
																	).format(
																		"DD-MM-YYYY, h:mm a",
																	)}
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	<p
																		className={`${
																			list?.status ===
																			"1"
																				? "text-success"
																				: "text-danger"
																		}`}>
																		{list?.status ===
																		"1"
																			? "Active"
																			: "Inactive"}
																	</p>
																</TableCell>
																<TableCell>
																	<React.Fragment>
																		{list?.status !==
																		"1" ? (
																			<button
																				className={
																					"btn btn-primary"
																				}
																				onClick={(
																					e,
																				) =>
																					onUpdate(
																						e,
																						list,
																						"1",
																					)
																				}>
																				STATUS
																			</button>
																		) : (
																			<button
																				className={
																					"btn btn-primary"
																				}
																				style={{
																					marginLeft: 8,
																				}}
																				onClick={(
																					e,
																				) =>
																					onUpdate(
																						e,
																						list,
																						"0",
																					)
																				}>
																				STATUS
																			</button>
																		)}
																		<button
																			className={
																				"btn btn-danger"
																			}
																			style={{
																				marginLeft: 8,
																			}}
																			onClick={(
																				e,
																			) =>
																				onDelete(
																					e,
																					list._id,
																				)
																			}>
																			Delete
																		</button>
																	</React.Fragment>
																</TableCell>
															</TableRow>
														);
													})
											: ""}
										{emptyRows > 0 && (
											<TableRow
												style={{
													height: 53 * emptyRows,
												}}>
												<TableCell colSpan={6} />
											</TableRow>
										)}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TablePagination
												className='text-white'
												rowsPerPageOptions={[
													5,
													10,
													25,
													{ label: "All", value: -1 },
												]}
												colSpan={5}
												count={upiData.length}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onRowsPerPageChange={
													handleChangeRowsPerPage
												}
											/>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

function mapStateToProps(state) {
	return {
		user: state.session.user,
		wallets: state.wallets,
		transactionHistory: state.transactionHistory,
	};
}

export default withRouter(connect(mapStateToProps, {})(AddUpiId));
