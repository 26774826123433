/**
 *  Imports
 */
import {
	GET_TRANSACTIONS_HISTORY_SUCCESS,
	SET_TRANSACTIONS_HISTORY_DATA,
	GET_USER_REQUEST_SUCCESS,
	UPDATE_USER_REQUEST_SUCCESS,
} from "../actionConstants";

/** Initial State  */
const INITIAL_STATE = {
	transactionHistory: [],
	transactionHistoryEnd: [],
	selectedTransactionHistory: {},
	userRequests: [],
	updateRequest: {},
	totalCount: 0,
	totalCountEnd: 0
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_TRANSACTIONS_HISTORY_SUCCESS:
			return { ...state, transactionHistory: [...action.data.response], transactionHistoryEnd: [...action.data.response], totalCountEnd: action.data.totalCount };
		case SET_TRANSACTIONS_HISTORY_DATA:
			return { ...state, selectedTransactionHistory: { ...action.data } };
		case GET_USER_REQUEST_SUCCESS:
			return { ...state, userRequests: [...action.data.response], transactions: [...action.data.response], totalCount: action.data.totalCount };
		case UPDATE_USER_REQUEST_SUCCESS:
			return { ...state, updateRequest: { ...action.data } };
		default:
			return state;
	}
}
