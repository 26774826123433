/** @format */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import {
	getTodayResult,
	getWallets,
	getTransactionHistory,
	getUserRequest,
	getGamesBets,
	getGames,
	getGameResults,
} from "../../redux/actions";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import {
	CardHeader,
	Button,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Label,
} from "reactstrap";
import axios from "axios";

const Header = (props) => {
	const { todayResult } = props.games;
	const { walletsList } = props.wallets;
	const [totalAmount, setTotalAmount] = useState(0);
	const [totalAddLAmount, setTotalAddedAmount] = useState(0);
	const [totalWithdrwalAmount, setTotalWithdrwalAmount] = useState(0);
	const [totalPendingWithdrwalAmount, setTotalPendingWithdrwalAmount] = useState(0);
	const [totalBiddingAmount, setTotalBiddingAmount] = useState(0);
	const [allGamesWinning, setAllGamesWinning] = useState([]);
	const [totalCommissionAmount, setTotalCommissionAmount] = useState(0);
	const [totalWinAmount, setTotalWinAmount] = useState(0);
	const [pendingRequest, setPendingRequest] = useState(0);
	const [allGameBets, setAllGameBets] = useState([]);
	const [allGameResult, setAllGameResult] = useState([]);
	const [gameTime, setGameTime] = useState({
		startTime: "",
		endTime: "",
	});
	const [lastBid, setLastBid] = useState("");
	const [lastResult, setLastResult] = useState("");
	const [games, setGames] = useState([]);
	let currentDate = moment().format("YYYY-MM-DD");
	let prevDay = moment().subtract(1, "day").format("YYYY-MM-DD");
	let nextDay = moment().add(1, "day").format("YYYY-MM-DD");
	let startTime = moment(currentDate + "T06:00").format("HH:mm:ss");
	let endTime = moment(currentDate + "T05:30").format("HH:mm:ss");

	const [filter, setFilter] = useState({
		filterS: {
			transaction_type: "",
			transaction_status: "",
			// transaction_mode: "add",
			startDate: moment(prevDay + " " + startTime).format("YYYY-MM-DD HH:mm:ss"),
			endDate: moment(currentDate + " " + endTime).format("YYYY-MM-DD HH:mm:ss"),
			createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
		},
	});

	const dispatch = useDispatch();
	// useEffect(() => {
	// 	getAllAnalaytics({ ...filter.filterS });
	// }, [filter.filterS]);

	useEffect(() => {
		console.log(moment().format("HH"));
		if (moment().format("HH") >= 6) {
			setFilter({
				filterS: {
					...filter?.filterS,
					startDate: moment(prevDay + " " + startTime).format(
						"YYYY-MM-DD HH:mm:ss",
					),
					endDate: moment(nextDay + " " + endTime).format(
						"YYYY-MM-DD HH:mm:ss",
					),
				},
			});
		}
		getGameResultData();
		dispatch({ type: "LOADING_START" });
		dispatch(
			getTodayResult({}, (errors, res) => {
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
		// getAllAnalaytics({ ...filter.filterS });
		getCustomerBalance();
		getAllAddMoneyRequest({
			transaction_type: "credit",
			transaction_mode: "add",
			transaction_status: "approved",
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});
		getAllWithdrawMoneyRequest({
			transaction_type: "debit",
			transaction_status: "approved",
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});
		getAllWithdrawMoneyPendingRequest({
			transaction_type: "debit",
			transaction_status: "pending",
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});
		getTotalBiddingAmount({
			transaction_type: "debit",
			transaction_mode: "bets",
			// transaction_status: "",
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});
		getTotalCommissionAmount({
			transaction_type: "credit",
			transaction_mode: "commission",
			// transaction_status: "",
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});
		getTotalWinningAmount({
			transaction_type: "credit",
			transaction_mode: "win",
			// transaction_status: "",
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});
		getTotalPendingRequest({
			transaction_status: "pending",
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});

		getGamesBetAmount({});
		getGamesResultWinningAmmount({});
	}, []);

	const getAllAddMoneyRequest = (query) => {
		dispatch(
			getUserRequest(query, (errors, res) => {
				// let total = res.data.length + pendingRequest;

				// setPendingRequest(total);
				dispatch({ type: "LOADING_SUCCESS" });
				// console.log(res);
				let totalAmount = 0;
				res?.data?.forEach((item) => {
					totalAmount += item?.amount;
				});
				setTotalAddedAmount(totalAmount);
			}),
		);
	};

	const getAllWithdrawMoneyRequest = (query) => {
		dispatch(
			getUserRequest(query, (errors, res) => {
				// let total = res.data.length + pendingRequest;

				// setPendingRequest(total);
				dispatch({ type: "LOADING_SUCCESS" });
				// console.log(res);
				let totalAmount = 0;
				res?.data?.forEach((item) => {
					if (
						item?.transaction_mode === "withdraw" ||
						item?.transaction_mode === "paytm" ||
						item?.transaction_mode === "phonepe" ||
						item?.transaction_mode === "card" ||
						item?.transaction_mode === "gpay"
					) {
						totalAmount += item?.amount;
					}
				});
				setTotalWithdrwalAmount(totalAmount);
			}),
		);
	};
	const getAllWithdrawMoneyPendingRequest = (query) => {
		dispatch(
			getUserRequest(query, (errors, res) => {
				// let total = res.data.length + pendingRequest;

				// setPendingRequest(total);
				dispatch({ type: "LOADING_SUCCESS" });
				// console.log(res);
				let totalAmount = 0;
				res?.data?.forEach((item) => {
					if (
						item?.transaction_mode === "withdraw" ||
						item?.transaction_mode === "paytm" ||
						item?.transaction_mode === "phonepe" ||
						item?.transaction_mode === "card" ||
						item?.transaction_mode === "gpay"
					) {
						totalAmount += item?.amount;
					}
				});
				setTotalPendingWithdrwalAmount(totalAmount);
			}),
		);
	};
	const getTotalBiddingAmount = (query) => {
		dispatch(
			getUserRequest(query, (errors, res) => {
				// let total = res.data.length + pendingRequest;

				// setPendingRequest(total);
				dispatch({ type: "LOADING_SUCCESS" });
				// console.log(res);
				let totalAmount = 0;
				res?.data?.forEach((item) => {
					if (item?.transaction_mode === "bets") {
						totalAmount += item?.amount;
					}
				});
				setTotalBiddingAmount(totalAmount);
			}),
		);
	};
	const getTotalCommissionAmount = (query) => {
		dispatch(
			getUserRequest(query, (errors, res) => {
				// let total = res.data.length + pendingRequest;

				// setPendingRequest(total);
				dispatch({ type: "LOADING_SUCCESS" });
				// console.log(res);
				let totalAmount = 0;
				res?.data?.forEach((item) => {
					if (item?.transaction_mode === "commission") {
						totalAmount += item?.amount;
					}
				});
				setTotalCommissionAmount(totalAmount.toFixed(2));
			}),
		);
	};
	const getTotalWinningAmount = (query) => {
		dispatch(
			getUserRequest(query, (errors, res) => {
				// let total = res.data.length + pendingRequest;

				// setPendingRequest(total);
				dispatch({ type: "LOADING_SUCCESS" });
				// console.log(res);
				let totalAmount = 0;
				let allGamesWinnings = [];
				res?.data?.forEach((item) => {
					if (item?.transaction_mode === "win") {
						totalAmount += item?.amount;
					}
					allGamesWinnings.push({
						transaction_mode: item.transaction_mode,
						transaction_status: item.transaction_status,
						amount: item.amount,
						game_id: item.game_id,
						game_name: item.game_name,
					});
				});
				setAllGamesWinning(allGamesWinnings);
				setTotalWinAmount(totalAmount);
			}),
		);
	};
	const getCustomerBalance = () => {
		dispatch(
			getWallets({}, (errors, res) => {
				let total = 0;
				res.data.forEach((wallet) => {
					total = wallet.total_amount ? wallet.total_amount + total : total;
				});
				setTotalAmount(total);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};

	const getTotalPendingRequest = (query) => {
		dispatch(
			getUserRequest(query, (errors, res) => {
				// console.log(errors, res);

				let total = res.data.length;
				setPendingRequest(total);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};

	// const getCommissionDetails = (amount) => {
	// 	axios
	// 		.get(`/api/commission/getCommissionList?status=0`)
	// 		.then((res) => {
	// 			console.log(res.data);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// };

	const handleFilterChange = (e) => {
		e.preventDefault();
		const { id, value } = e.target;
		setFilter((prevState) => ({
			...prevState,
			filterS: {
				...filter.filterS,
				[id]: value,
			},
		}));
	};

	const getAllAnalaytics = (query = {}) => {
		getCustomerBalance();
		getAllAddMoneyRequest({
			transaction_type: "credit",
			transaction_mode: "add",
			transaction_status: filter?.filterS?.transaction_status,
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});
		getAllWithdrawMoneyRequest({
			transaction_type: "debit",
			transaction_status: "approved",
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});
		getAllWithdrawMoneyPendingRequest({
			transaction_type: "debit",
			transaction_status: "pending",
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});
		getTotalBiddingAmount({
			transaction_type: "debit",
			transaction_mode: "bets",
			transaction_status: filter?.filterS?.transaction_status,
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});
		getTotalCommissionAmount({
			transaction_type: "credit",
			transaction_mode: "commission",
			transaction_status: filter?.filterS?.transaction_status,
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});
		getTotalWinningAmount({
			transaction_type: "credit",
			transaction_mode: "win",
			// transaction_status: filter?.filterS?.transaction_status,
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});
		getTotalPendingRequest({
			transaction_status: "pending",
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});

		getGamesBetAmount({
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});

		getGameResultData();
		getGamesResultWinningAmmount({
			startDate: filter.filterS.startDate,
			endDate: filter.filterS.endDate,
		});
	};

	const getGameResultData = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getGames(query, (errors, res) => {
				// console.log("getGames=>", res);
				setGames(res?.data?.response);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};

	const getGamesBetAmount = (query = {}) => {
		// let totalAmountSpend = 0;
		dispatch({ type: "LOADING_START" });
		dispatch(
			getGamesBets(query, (errors, res) => {
				// console.log("getGamesBets=>", res?.data);
				setAllGameBets(res?.data);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);

		// return totalAmountSpend;
	};
	const getGamesResultWinningAmmount = (query = {}) => {
		// let totalAmountSpend = 0;
		dispatch({ type: "LOADING_START" });
		dispatch(
			getGameResults(query, (errors, res) => {
				setAllGameResult(res?.data);
				// res.data.forEach((item) => {
				// 	if (item.winning_amount) {
				// 	}
				// });

				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);

		// return totalAmountSpend;
	};

	const handleGameTotalBid = (gameId) => {
		let totalAmountSpend = 0;
		allGameBets.forEach((item) => {
			// console.log("item", item);
			if (item.game_id === gameId) {
				// console.log(item);
				totalAmountSpend += item.total_ammount_spend;
			}
		});
		return totalAmountSpend;
	};
	// console.log("allGameResult", allGameResult);
	const handleGameTotalWin = (gameId) => {
		let totalAmountSpend = 0;
		allGamesWinning.forEach((item) => {
			// console.log("item", item, gameId);
			if (item.game_id === gameId && item.amount !== 0) {
				totalAmountSpend += item.amount;
			}
		});
		return totalAmountSpend;
	};

	const getAllGameDetails = (gamesArr) => {
		return gamesArr?.map((game, ind) => {
			// console.log("game", game);
			let bidAmount = handleGameTotalBid(game._id)
				? handleGameTotalBid(game._id)
				: 0;
			let winAmount = handleGameTotalWin(game._id)
				? handleGameTotalWin(game._id)
				: 0;
			return (
				<Col lg='6' xl='3' key={ind + 1}>
					<Card className='card-stats mb-4 mb-xl-0 mt-4  bg-light'>
						<CardBody>
							<Row>
								<Link to={`/admin/gameBets/${game._id}`}>
									<div className='col'>
										<CardTitle
											tag='h5'
											className='text-uppercase text-black mb-0'>
											{game?.game_name}
										</CardTitle>
										<br />
										<span className='h3 text-black mb-0'>
											Total Bidding: {bidAmount}
										</span>
										<br />
										<span className='h3 text-danger mb-0'>
											Total Winning: {winAmount}
										</span>
										<br />
										<span className='h3 text-success mb-0'>
											Total Profit:{" "}
											{Number(bidAmount) - Number(winAmount)}
										</span>
										<br />
										<span className='h3 text-success mb-0'>
											Last Result:{" "}
											{
												game?.today_game_result[
													game?.today_game_result.length - 1
												]?.winning_bet_number
											}
										</span>
										{/* <br />
									<span className='h3 text-success mb-0'>
										Last Bid: {lastBid}
									</span> */}
									</div>
								</Link>
								{/* <Col className='col-auto'>
									<div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
										<i className='fas fa-chart-bar' />
									</div>
								</Col> */}
							</Row>
							<p className='mt-3 mb-0 text-muted text-sm'>
								<span className='text-success mr-2'>
									{/* <i className="fa fa-arrow-up" /> 3.48% */}
								</span>{" "}
								{/* <span className="text-nowrap">Since last month</span> */}
							</p>
						</CardBody>
					</Card>
				</Col>
			);
		});
	};

	return (
		<>
			<div className='header bg-gradient-info pb-8 pt-5 pt-md-8'>
				<Container fluid>
					<div className='header-body'>
						{/* Card stats */}
						<Row>
							<Col lg='6' xl='3'>
								<FormGroup>
									<label>START</label>
									<InputGroup size='xl' className='w-100 mt-2'>
										<InputGroupAddon
											addonType='prepend'
											className='d-inline'>
											<Button className='bg-default shadow'>
												<i className='ni ni-calendar-grid-58 text-white' />
											</Button>
										</InputGroupAddon>
										<Input
											type='datetime-local'
											autoComplete='new-sdate'
											id='startDate'
											placeholder='Start Date'
											name='startDate'
											// disabled={true}
											value={filter.filterS.startDate}
											onChange={handleFilterChange}
											required
										/>
									</InputGroup>
								</FormGroup>
							</Col>
							<Col lg='6' xl='3'>
								<FormGroup>
									<label>END</label>
									<InputGroup size='xl' className='w-100 mt-2'>
										<InputGroupAddon
											addonType='prepend'
											className='d-inline'>
											<Button className='bg-default shadow'>
												<i className='ni ni-calendar-grid-58 text-white' />
											</Button>
										</InputGroupAddon>
										<Input
											type='datetime-local'
											autoComplete='new-sdate'
											id='endDate'
											placeholder='End Date'
											name='endDate'
											// disabled={true}
											// value={state.endDate}

											value={filter.filterS.endDate}
											onChange={handleFilterChange}
											required
										/>
									</InputGroup>
								</FormGroup>
							</Col>
							<Col lg='6' xl='3'>
								<FormGroup>
									<label>TYPE</label>
									<InputGroup size='xl' className='w-100 mt-2'>
										<Input
											type='select'
											autoComplete='new-name'
											value={filter.filterS.transaction_type}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_type'
											placeholder='Select Transaction Status'
											name='transaction_type'
											required>
											<option key='' value=''>
												Select Transaction Type
											</option>
											<option key='credit' value='credit'>
												credit
											</option>
											<option key='debit' value='debit'>
												debit
											</option>
										</Input>
									</InputGroup>
									{filter.filterS.submitted &&
										!filter.filterS.transaction_type !== "none" && (
											<div className='error'>
												Transaction Type is required
											</div>
										)}
								</FormGroup>
							</Col>
							<Col lg='6' xl='3'>
								<FormGroup>
									<label>STATUS</label>
									<InputGroup size='xl' className='w-100 mt-2'>
										<Input
											type='select'
											autoComplete='new-name'
											value={filter.filterS.transaction_status}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_status'
											placeholder='Select Transaction Status'
											name='transaction_status'
											required>
											<option key='' value=''>
												Select Transaction Status
											</option>
											<option key='pending' value='pending'>
												pending
											</option>
											<option key='approved' value='approved'>
												approved
											</option>
											<option key='rejected' value='rejected'>
												rejected
											</option>
										</Input>
									</InputGroup>
								</FormGroup>
							</Col>
							<Col lg='6' xl='3'>
								<FormGroup>
									<label>&nbsp;</label>
									<InputGroup size='xl' className='mt-2 w-100'>
										<Input
											type='Button'
											onClick={() =>
												getAllAnalaytics({ ...filter.filterS })
											}
											className='bg-default text-white'
											value={"Search"}></Input>
									</InputGroup>
								</FormGroup>
							</Col>
						</Row>

						<Row className='pt-5'>
							<Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Customer Balance
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													{totalAmount}
												</span>
											</div>
											<Col className='col-auto'>
												<Link to='/admin/wallet'>
													<div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
														<i className='fas fa-chart-bar' />
													</div>
												</Link>
											</Col>
										</Row>
										<p className='mt-3 mb-0 text-muted text-sm'>
											<span className='text-success mr-2'>
												{/* <i className="fa fa-arrow-up" /> 3.48% */}
											</span>{" "}
											{/* <span className="text-nowrap">Since last month</span> */}
										</p>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Add Money
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													{" "}
													{totalAddLAmount}
												</span>
											</div>
											<Col className='col-auto'>
												<Link to='/admin/paymentRequest'>
													<div className='icon icon-shape bg-warning text-white rounded-circle shadow'>
														<i className='fas fa-chart-bar' />
													</div>
												</Link>
											</Col>
										</Row>
										<p className='mt-3 mb-0 text-muted text-sm'>
											<span className='text-danger mr-2'>
												{/* <i className="fas fa-arrow-down" /> 3.48% */}
											</span>{" "}
											{/* <span className="text-nowrap">Since last week</span> */}
										</p>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Withdraw Money
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													{totalWithdrwalAmount}
												</span>
											</div>
											<Col className='col-auto'>
												<Link to='/admin/withdrawRequest'>
													<div className='icon icon-shape bg-yellow text-white rounded-circle shadow'>
														<i className='fas fa-chart-bar' />
													</div>
												</Link>
											</Col>
										</Row>
										<p className='mt-3 mb-0 text-muted text-sm'>
											<span className='text-nowrap'>
												{todayResult.length
													? moment(
														todayResult[0].created_at,
													).format("DD-MM-YYYY, h:mm a")
													: ""}
											</span>
										</p>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Total Bidding
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													{totalBiddingAmount}
												</span>
											</div>
											<Col className='col-auto'>
												<Link to='/admin/gameBets'>
													<div className='icon icon-shape bg-info text-white rounded-circle shadow'>
														<i className='fas fa-chart-bar' />
													</div>
												</Link>
											</Col>
										</Row>
										<p className='mt-3 mb-0 text-muted text-sm'>
											<span className='text-success mr-2'>
												{/* <i className="fas fa-arrow-up" /> 12% */}
											</span>{" "}
											{/* <span className="text-nowrap">Since last month</span> */}
										</p>
									</CardBody>
								</Card>
							</Col>
						</Row>
						<Row className='pt-5'>
							<Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Latest Result
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													{todayResult.length
														? todayResult[0]
															?.winning_bet_number
														: "-"}
												</span>
												<br />
												<span className='h4 font-weight-bold mb-0'>
													{todayResult[0]?.game_id?.game_name}
												</span>
											</div>
											<Col className='col-auto'>
												<Link to='/admin/gameResults'>
													<div className='icon icon-shape bg-yellow text-white rounded-circle shadow'>
														<i className='fas fa-users' />
													</div>
												</Link>
											</Col>
										</Row>
										<p className='mt-3 mb-0 text-muted text-sm'>
											<span className='text-nowrap'>
												{todayResult.length
													? moment(
														todayResult[0].created_at,
													).format("DD-MM-YYYY, h:mm a")
													: ""}
											</span>
										</p>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Last Bidding
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													{todayResult.length
														? todayResult[0]?.last_user_bid
														: "-"}
												</span>
												<br />
												<span className='h4 font-weight-bold mb-0'>
													{todayResult
														? todayResult[0]?.game_id
															?.game_name
														: "-"}
												</span>
											</div>
											<Col className='col-auto'>
												<Link to='/admin/allbets'>
													<div className='icon icon-shape bg-info text-white rounded-circle shadow'>
														<i className='fas fa-percent' />
													</div>
												</Link>
											</Col>
										</Row>
										<p className='mt-3 mb-0 text-muted text-sm'>
											<span className='text-nowrap'>
												{todayResult.length
													? moment(
														todayResult[0].created_at,
													).format("DD-MM-YYYY, h:mm a")
													: ""}
											</span>
										</p>
									</CardBody>
								</Card>
							</Col>
							{/* <Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Last Winning
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													{todayResult.length
														? todayResult[0]?.winning_amount
														: 0}
												</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-info text-white rounded-circle shadow'>
													<i className='fas fa-percent' />
												</div>
											</Col>
										</Row>
										<p className='mt-3 mb-0 text-muted text-sm'>
											<span className='text-nowrap'>
												{todayResult.length
													? moment(
															todayResult[0].created_at,
													  ).format("DD-MM-YYYY, h:mm a")
													: "-"}
											</span>
										</p>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Last Commission
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													49,65%
												</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-info text-white rounded-circle shadow'>
													<i className='fas fa-percent' />
												</div>
											</Col>
										</Row>
										<p className='mt-3 mb-0 text-muted text-sm'>
											<span className='text-success mr-2'>
												<i className='fas fa-arrow-up' /> 12%
											</span>{" "}
											<span className='text-nowrap'>
												Since last month
											</span>
										</p>
									</CardBody>
								</Card>
							</Col> */}
							<Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Total Profit
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													{totalBiddingAmount - totalWinAmount}
												</span>
											</div>
											<Col className='col-auto'>
												<Link to='/admin/withdrawRequest'>
													<div className='icon icon-shape bg-info text-white rounded-circle shadow'>
														<i className='fas fa-chart-bar' />
													</div>
												</Link>
											</Col>
										</Row>
										<p className='mt-3 mb-0 text-muted text-sm'>
											<span className='text-nowrap'>
												{todayResult.length
													? moment(
														todayResult[0].created_at,
													).format("DD-MM-YYYY, h:mm a")
													: ""}
											</span>
										</p>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0 mt-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Pending Requests and Money
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													{pendingRequest || 0}
												</span>
												<br />
												<span className='h2 font-weight-bold mb-0'>
													{totalPendingWithdrwalAmount || 0}
												</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-info text-white rounded-circle shadow'>
													<i className='fas fa-chart-bar' />
												</div>
											</Col>
										</Row>
										<p className='mt-3 mb-0 text-muted text-sm'>
											<span className='text-nowrap'>
												{todayResult.length
													? moment(
														todayResult[0].created_at,
													).format("DD-MM-YYYY, h:mm a")
													: "-"}
											</span>
										</p>
									</CardBody>
								</Card>
							</Col>
						</Row>
						<Row className='pt-5'>
							<Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Total Commission
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													{totalCommissionAmount}
												</span>
											</div>
											<Col className='col-auto'>
												<Link to='/admin/commissionRequest'>
													<div className='icon icon-shape bg-info text-white rounded-circle shadow'>
														<i className='fas fa-chart-bar' />
													</div>
												</Link>
											</Col>
										</Row>
										<p className='mt-3 mb-0 text-muted text-sm'>
											<span className='text-success mr-2'>
												{/* <i className="fas fa-arrow-up" /> 12% */}
											</span>{" "}
											{/* <span className="text-nowrap">Since last month</span> */}
										</p>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Total Winning
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													{totalWinAmount}
												</span>
											</div>
											<Col className='col-auto'>
												<Link to='/admin/winners'>
													<div className='icon icon-shape bg-info text-white rounded-circle shadow'>
														<i className='fas fa-chart-bar' />
													</div>
												</Link>
											</Col>
										</Row>
										<p className='mt-3 mb-0 text-muted text-sm'>
											<span className='text-success mr-2'>
												<i className='fas fa-chart-bar' />
											</span>{" "}
											<span className='text-nowrap'>
												{/* Since last month */}
											</span>
										</p>
									</CardBody>
								</Card>
							</Col>
						</Row>
						<Row>
							{/* <Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0 mt-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Total Bidding
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													{totalBiddingAmount}
												</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-info text-white rounded-circle shadow'>
													<i className='fas fa-chart-bar' />
												</div>
											</Col>
										</Row>
										<p className='mt-3 mb-0 text-muted text-sm'>
											<span className='text-nowrap'>
												{todayResult.length
													? moment(
															todayResult[0].created_at,
													  ).format("DD-MM-YYYY, h:mm a")
													: ""}
											</span>
										</p>
									</CardBody>
								</Card>
							</Col> */}

							{getAllGameDetails(games)}
						</Row>
					</div>
				</Container>
			</div>
		</>
	);
};
function mapStateToProps(state) {
	return {
		games: state.games,
		wallets: state.wallets,
	};
}

export default withRouter(connect(mapStateToProps, {})(Header));
