/** @format */
/** @format */

import React, { useState, useEffect } from "react";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import {
	getWallets,
	updateUPIDataRequest,
	createUpidActions,
	deleteUpiData,
	updateMessageAction,
	getMessagesDataListAction,
	createMessagesFirstAction,
} from "../../redux/actions";
import { toastr } from "react-redux-toastr";

// reactstrap components
import {
	Card,
	CardHeader,
	Container,
	Row,
	Button,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { getTransactionHistory } from "../../redux/actions";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	TablePagination,
} from "@material-ui/core";

const UpdateMessages = (props) => {
	// console.log(props, "props");
	const { walletsList } = props.wallets;
	let walletData = walletsList.filter((wallet) => {
		return wallet?.phone_number;
	});
	const filterOptions = createFilterOptions({
		ignoreCase: false,
		ignoreAccents: false,
	});
	// const { transactionHistory, userRequests } = props.transactionHistory;
	// console.log(userRequests, "transactionHistory");

	const dispatch = useDispatch();
	const [upiData, setUPIData] = useState([]);
	let currentDate = moment().format("YYYY-MM-DD");
	let prevDay = moment().subtract(1, "day").format("YYYY-MM-DD");
	let nextDay = moment().add(1, "day").format("YYYY-MM-DD");
	let startTimes = moment(currentDate + "T06:00").format("HH:mm:ss");
	let endTime = moment(currentDate + "T05:30").format("HH:mm:ss");
	const [state, setState] = useState({
		submitted: false,
		download_msg: "",
		commission_msg: "",
		offer_msg: "",
		share_earn_msg: "",
		notice_msg: "",
		min_add_money: 0,
		min_withdraw_money: 0,
		max_withdraw_money: 0,
		whatsapp_number: "",
		_id: "",
		cus_min_balance: 0,
		start_time: moment(prevDay + " " + startTimes).format("YYYY-MM-DD HH:mm:ss"),
		close_time: moment(currentDate + " " + endTime).format("YYYY-MM-DD HH:mm:ss"),
		withdraw_count: 0,
	});
	const [filter, setFilter] = useState({
		filterS: {
			sttus: false,
			upi_id: "",
			upi_name: "",
			created_at: "",
			updated_at: "",
		},
	});
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		getMessagesData({});
		// dispatch(
		// 	getWallets({}, (errors, res) => {
		// 		dispatch({ type: "LOADING_SUCCESS" });
		// 	}),
		// );
	}, []);
	const handleChange = (e) => {
		const { id, value, name } = e.target;
		if (name) {
			setState((prevState) => ({
				...prevState,
				[name]: value,
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				[id]: value,
			}));
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setState((prevState) => ({
			...prevState,
			submitted: true,
		}));
		let reqData = {
			...state,
		};
		dispatch(
			// createUpidActions(reqData, (res, errors) => {
			updateMessageAction(reqData, (res, errors) => {
				toastr.success("Success", "Udpated Requested Successfully");
				dispatch(
					getMessagesDataListAction({}, (errors, res) => {
						dispatch({ type: "LOADING_SUCCESS" });
					}),
				);
			}),
		);
	};
	const handlecreateMessages = (e) => {
		e.preventDefault();
		setState((prevState) => ({
			...prevState,
			submitted: true,
		}));
		let reqData = {
			download_msg: state.download_msg,
			commission_msg: state.commission_msg,
			offer_msg: state.offer_msg,
			share_earn_msg: state.share_earn_msg,
			notice_msg: state.notice_msg,
			min_add_money: state.min_add_money,
			min_withdraw_money: state.min_withdraw_money,
			max_withdraw_money: state.max_withdraw_money,
			whatsapp_number: state.whatsapp_number,
			cus_min_balance: state.cus_min_balance,
			start_time: state.start_time,
			close_time: state.close_time,
			withdraw_count: state.withdraw_count,
		};
		dispatch(
			// createUpidActions(reqData, (res, errors) => {
			createMessagesFirstAction(reqData, (res, errors) => {
				toastr.success("Success", "Created Requested Successfully");
				dispatch(
					getMessagesDataListAction({}, (errors, res) => {
						dispatch({ type: "LOADING_SUCCESS" });
					}),
				);
			}),
		);
	};

	const onUpdate = (e, list, status) => {
		let updatData = {
			...list,
			status: status,
		};
		dispatch(
			updateUPIDataRequest(updatData, (err, res) => {
				if (res.data.message) {
					toastr.success("error", res.data.message);
				} else {
					toastr.success("Success", "Request Updated Successfully");
				}
				getMessagesData({});
			}),
		);
	};
	const getMessagesData = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getMessagesDataListAction(query, (errors, res) => {
				// console.log(res);
				// setUPIData(res?.data?.response);
				let response = res?.data?.response[0];
				if (response) {

					setState((prevState) => ({
						...prevState,
						...response,
						start_time: moment(response?.start_time).format("YYYY-MM-DD HH:mm:ss"),
						close_time: moment(response?.close_time).format("YYYY-MM-DD HH:mm:ss"),

					}));
				}

				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};
	const status = {
		debit: "Debit",
		credit: "Credit",
	};
	const handleFilterChange = (e) => {
		e.preventDefault();
		const { id, value } = e.target;
		setFilter((prevState) => ({
			...prevState,
			filterS: {
				...filter.filterS,
				[id]: value,
			},
		}));
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - upiData.length) : 0;

	const onDelete = (e, ids) => {
		// console.log(e);

		e.preventDefault();
		let id = {
			_id: ids,
		};
		dispatch(
			deleteUpiData(id, (errors, res) => {
				if (res.data.message) {
					toastr.success("error", res.data.message);
				} else {
					toastr.success("Success", "UPI Deleted Successfully");
				}
				getMessagesData({});
			}),
		);
	};

	return (
		<>
			{/* <Header /> */}
			{/* Page content */}
			<Container className='mt-7' fluid>
				{/* Dark table */}
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-secondary shadow'>
							<CardHeader className='bg-transparent border-0 mt-7'>
								<h3 className='mb-0 text-center'>
									Update Messages For User Account
								</h3>
							</CardHeader>
							<CardBody>
								<Form role='form'>
									<FormGroup>
										<label>Download</label>

										<Input
											className='form-control-alternative'
											variant='outlined'
											id='download_msg'
											placeholder='Download Message'
											name='download_msg'
											rows='4'
											defaultValue=''
											type='textarea'
											value={state.download_msg}
											onChange={handleChange}
										/>

										{state.submitted && !state.download_msg && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<label>Commision</label>
										<Input
											className='form-control-alternative'
											variant='outlined'
											id='commission_msg'
											placeholder='commission Message'
											name='commission_msg'
											rows='4'
											defaultValue=''
											type='textarea'
											value={state.commission_msg}
											onChange={handleChange}
										/>

										{state.submitted && !state.commission_msg && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<label>Offer</label>
										<Input
											className='form-control-alternative'
											variant='outlined'
											id='offer_msg'
											placeholder='Offer Message'
											name='offer_msg'
											rows='4'
											defaultValue=''
											type='textarea'
											value={state.offer_msg}
											onChange={handleChange}
										/>

										{state.submitted && !state.offer_msg && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<label>Share and Earn</label>
										<Input
											className='form-control-alternative'
											variant='outlined'
											id='share_earn_msg'
											placeholder='share_and_earn Message'
											name='share_earn_msg'
											rows='4'
											defaultValue=''
											type='textarea'
											value={state.share_earn_msg}
											onChange={handleChange}
										/>

										{state.submitted && !state.share_earn_msg && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<label>Notice WhatsApp</label>
										<Input
											className='form-control-alternative'
											variant='outlined'
											id='notice_msg'
											placeholder='share_and_earn Message'
											name='notice_msg'
											rows='4'
											defaultValue=''
											type='textarea'
											value={state.notice_msg}
											onChange={handleChange}
										/>

										{state.submitted && !state.notice_msg && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<label>Minimum Add Money</label>
											<TextField
												fullWidth
												type='text'
												variant='outlined'
												id='min_add_money'
												placeholder='Minimum Add Money'
												name='min_add_money'
												value={state.min_add_money}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.min_add_money && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<label>Minimum Withdraw Money</label>
											<TextField
												fullWidth
												type='text'
												variant='outlined'
												id='min_withdraw_money'
												placeholder='Minimum Withdraw Money'
												name='min_withdraw_money'
												value={state.min_withdraw_money}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.min_withdraw_money && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<label>Maximum Withdraw Money</label>
											<TextField
												fullWidth
												type='text'
												variant='outlined'
												id='max_withdraw_money'
												placeholder='Maximum Withdraw Money'
												name='max_withdraw_money'
												value={state.max_withdraw_money}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.max_withdraw_money && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<label>WhatsApp Number (Help)</label>
											<TextField
												fullWidth
												type='text'
												variant='outlined'
												id='whatsapp_number'
												placeholder='WhatsApp Number'
												name='whatsapp_number'
												value={state.whatsapp_number}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.whatsapp_number && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<label>Website Link</label>
											<TextField
												fullWidth
												type='text'
												variant='outlined'
												id='website_url'
												placeholder='Website Link'
												name='website_url'
												value={state.website_url}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.website_url && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<label>Customer Minimum Balance</label>
											<TextField
												fullWidth
												type='text'
												variant='outlined'
												id='cus_min_balance'
												placeholder='Customer Minimum Balance'
												name='cus_min_balance'
												value={state.cus_min_balance}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.cus_min_balance && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<label>Start Withdraw Time</label>
											<TextField
												fullWidth
												type='datetime-local'
												variant='outlined'
												id='start_time'
												placeholder='Close Withdraw Time'
												name='start_time'
												value={state.start_time}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.start_time && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<label>Close Withdraw Time</label>
											<TextField
												fullWidth
												type='datetime-local'
												variant='outlined'
												id='close_time'
												placeholder='Close Withdraw Time'
												name='close_time'
												value={state.close_time}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.close_time && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<label>Withdraw Count</label>
											<TextField
												fullWidth
												type='text'
												variant='outlined'
												id='withdraw_count'
												placeholder='Withdraw Count'
												name='withdraw_count'
												value={state.withdraw_count}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.withdraw_count && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>

									<div className='text-center'>
										{state?._id !== "" ? (
											<Button
												disabled={
													!(
														state.max_withdraw_money &&
														state.whatsapp_number
													)
													// state.transaction_type !== "none" &&
												}
												onClick={handleSubmit}
												className='my-4'
												color='primary'
												style={{
													backgroundColor: "#9b5de5",
													border: "none",
												}}
												type='button'>
												UPDATE
											</Button>
										) : (
											<Button
												disabled={
													!(
														state.max_withdraw_money &&
														state.whatsapp_number
													)
													// state.transaction_type !== "none" &&
												}
												onClick={handlecreateMessages}
												className='my-4'
												color='primary'
												style={{
													backgroundColor: "#9b5de5",
													border: "none",
												}}
												type='button'>
												CREATE
											</Button>
										)}
									</div>
								</Form>
							</CardBody>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

function mapStateToProps(state) {
	return {
		user: state.session.user,
		wallets: state.wallets,
		transactionHistory: state.transactionHistory,
	};
}

export default withRouter(connect(mapStateToProps, {})(UpdateMessages));
