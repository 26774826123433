/** @format */

import { apiCall, createQueryParams } from "../index";
import API from "../serviceList";

export function getTransactionHistory(queryParm) {
	let query = createQueryParams(queryParm);
	return apiCall({
		method: "GET",
		url: `${API.getTransactionHistory}${query}`,
	});
}
export function deleteCommission(queryParm) {
	// let query = createQueryParams(queryParm);
	return apiCall({
		method: "GET",
		url: `${API.deleteCommission}/${queryParm}`,
	});
}

export function deleteTransactions(reqData) {
	// let query = createQueryParams(queryParm);
	return apiCall({
		method: "DELETE",
		url: `${API.deleteTransactions}`,
		data: reqData,
	});
}
export function getUserRequest(queryParm) {
	let query = createQueryParams(queryParm);
	return apiCall({
		method: "GET",
		url: `${API.getUserRequest}${query}`,
	});
}
export function getDashboardServices(queryParm) {
	let query = createQueryParams(queryParm);
	return apiCall({
		method: "GET",
		url: `${API.getDashboardApi}${query}`,
	});
}
export function updateUserRequest(userData) {
	return apiCall({
		method: "PUT",
		url: `${API.approveRequest}`,
		data: userData,
	});
}
