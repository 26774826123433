/** @format */

import axios from "axios";
import hostConfig from "./../config/hostConfig";
import { sessionService } from "redux-react-session";
import { logoutGenuinly } from "./../common";

const rftk = localStorage.getItem("refresh_token");
const id = localStorage.getItem("id") || "";

let instance = axios.create({
	baseURL: hostConfig?.apiBaseUrl,
	timeout: 120000,
	// headers: {
	// 	Accept: "application/json",
	// 	"Content-Type": "application/json",
	// 	// Authorization: token,
	// 	"x-auth": rftk,
	// },
});

// Add a request interceptor
instance.interceptors.request.use(
	async (config) => {
		// console.log("config ne", config);
		return config;
	},
	function (error) {
		return Promise.reject(error);
	},
);

// Add a response interceptor
instance.interceptors.response.use(
	function (response) {
		// console.log('response', response);
		return response;
	},
	// function (error) {
	// 	console.log("error", error);
	// 	return Promise.reject(error);
	// },
	async function (err) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		const originalConfig = err.config;
		// console.log(err.response);
		if (err.response) {
			// Access Token was expired
			if (err.response.status === 403 && !originalConfig._retry) {
				originalConfig._retry = true;

				try {
					axios
						.post(
							`${hostConfig.apiBaseUrl}/api/users/sessionrefadmin`,
							{
								xauth: rftk,
								aid: id,
							},
							{
								headers: {
									"x-auth": rftk,
								},
							},
						)
						.then((res) => {
							if (res.status === 200 && res?.data?.token !== undefined) {
								sessionService.saveSession({
									sessionId: `${res.data.token}`,
									sessionToken: `${res.data.token}`,
								});
							} else {
								logoutGenuinly();
							}
						})
						.catch((err) => {
							logoutGenuinly();
							localStorage.clear();
							console.log(err);
						});

					return axios(originalConfig);
				} catch (_error) {
					if (_error.response && _error.response.data) {
						return Promise.reject(_error.response.data);
					}

					return Promise.reject(_error);
				}
			}

			if (err.response.status === 403 && err.response.data) {
				return Promise.reject(err.response.data);
			}
		}
		return Promise.reject(err);
	},
);

export default instance;
