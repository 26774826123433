/** @format */

import { sessionService } from "redux-react-session";
import {
	GET_GAMES_START,
	GET_GAMES_SUCCESS,
	GET_GAMES_FAILURE,
	GET_GAMES_BETS_START,
	GET_GAMES_BETS_SUCCESS,
	GET_GAMES_BETS_FAILURE,
	ADD_GAMES_START,
	ADD_GAMES_SUCCESS,
	ADD_GAMES_FAILURE,
	UPDATE_GAMES_START,
	UPDATE_GAMES_SUCCESS,
	UPDATE_GAMES_FAILURE,
	UPDATE_GAME_RESULTS_START,
	UPDATE_GAME_RESULTS_SUCCESS,
	UPDATE_GAME_RESULTS_FAILURE,
	GET_GAME_RESULTS_START,
	GET_GAME_RESULTS_SUCCESS,
	GET_GAME_RESULTS_FAILURE,
	GET_GAME_DELETE_START,
	GET_GAME_DELETE_SUCCESS,
	GET_GAME_DELETE_FAILURE,
	GET_TODAY_RESULT_START,
	GET_TODAY_RESULT_SUCCESS,
	GET_TODAY_RESULT_FAILURE,
	GET_GAMES_BETS_START_WITH_USER,
	GET_GAMES_BETS_SUCCESS_WITH_USER,
	GET_GAMES_BETS_FAILURE_WITH_USER,
} from "../actionConstants";

import dispatchActionToReducer, {
	SimpleDispatchActionToReducer,
} from "../actionDispatcher";

import {
	getGames as getGamesService,
	getTodayResult as getTodayResultService,
	addGame as addGameService,
	updateAddedGame as updateAddedGameService,
	getGamesBets as getGamesBetsService,
	getGameBetsWithUserId as getGameBetsWithUserIdService,
	updateGameResults as updateGameResultsService,
	getGamesResult as getGamesResultService,
	removeGameResults as removeGameResultsService,
	deleteGamesById as deleteGamesByIdService,
} from "../services";

export const getGames = (query, callback) => {
	return dispatchActionToReducer(
		getGamesService(query),
		GET_GAMES_START,
		GET_GAMES_SUCCESS,
		GET_GAMES_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const getTodayResult = (query, callback) => {
	return dispatchActionToReducer(
		getTodayResultService(query),
		GET_TODAY_RESULT_START,
		GET_TODAY_RESULT_SUCCESS,
		GET_TODAY_RESULT_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};

export function addGame(body, callback) {
	return dispatchActionToReducer(
		addGameService(body),
		ADD_GAMES_START,
		ADD_GAMES_SUCCESS,
		ADD_GAMES_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(error, res);
			} else {
				console.log("undefined response");
			}
		},
	);
}
export function updateAddedGameAction(body, callback) {
	return dispatchActionToReducer(
		updateAddedGameService(body),
		UPDATE_GAMES_START,
		UPDATE_GAMES_SUCCESS,
		UPDATE_GAMES_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(error, res);
			} else {
				console.log("undefined response");
			}
		},
	);
}

export function updateGameResults(body, callback) {
	return dispatchActionToReducer(
		updateGameResultsService(body),
		UPDATE_GAME_RESULTS_START,
		UPDATE_GAME_RESULTS_SUCCESS,
		UPDATE_GAME_RESULTS_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(error, res);
			} else {
				console.log("undefined response");
			}
		},
	);
}

export const getGamesBets = (query, callback) => {
	return dispatchActionToReducer(
		getGamesBetsService(query),
		GET_GAMES_BETS_START,
		GET_GAMES_BETS_SUCCESS,
		GET_GAMES_BETS_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const getGameBetsWithUserId = (query, callback) => {
	return dispatchActionToReducer(
		getGameBetsWithUserIdService(query),
		GET_GAMES_BETS_START_WITH_USER,
		GET_GAMES_BETS_SUCCESS_WITH_USER,
		GET_GAMES_BETS_FAILURE_WITH_USER,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const getGameResults = (query, callback) => {
	return dispatchActionToReducer(
		getGamesResultService(query),
		GET_GAME_RESULTS_START,
		GET_GAME_RESULTS_SUCCESS,
		GET_GAME_RESULTS_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const removeGameResults = (query, callback) => {
	return dispatchActionToReducer(
		removeGameResultsService(query),
		GET_GAME_RESULTS_START,
		GET_GAME_RESULTS_SUCCESS,
		GET_GAME_RESULTS_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const deleteGamesById = (query, callback) => {
	return dispatchActionToReducer(
		deleteGamesByIdService(query),
		GET_GAME_DELETE_START,
		GET_GAME_DELETE_SUCCESS,
		GET_GAME_DELETE_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
