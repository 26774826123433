/** @format */

import React, { useState, useEffect } from "react";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import {
	getWallets,
	getUserRequest,
	updateUserRequest,
	addMoneyToWallet,
} from "../../redux/actions";
import { toastr } from "react-redux-toastr";

// reactstrap components
import {
	Card,
	CardHeader,
	Container,
	Row,
	Button,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { getTransactionHistory } from "../../redux/actions";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	TablePagination,
} from "@material-ui/core";

const WithdrawRequest = (props) => {
	// console.log(props, "props");
	const { walletsList } = props.wallets;
	let walletData = walletsList.filter((wallet) => {
		return wallet?.phone_number;
	});
	const filterOptions = createFilterOptions({
		ignoreCase: false,
		ignoreAccents: false,
	});
	const { transactionHistory, userRequests, transactions, totalCount } = props.transactionHistory;
	// console.log(userRequests, "transactionHistory");
	const dispatch = useDispatch();
	const [userData, setUserData] = useState(userRequests);
	const [state, setState] = useState({
		wallet_id: "",
		transaction_mode: "withdraw",
		transaction_type: "debit",
		amount: null,
		submitted: false,
	});
	const [filter, setFilter] = useState({
		filterS: {
			transaction_mode: "withdraw",
			transaction_type: "debit",
			transaction_status: "pending",
			phone_number: "",
			amount: "",
			createdAt: "",
			sortField: "_id",
			pageNumber: page || 1,
			pageSize: rowsPerPage || 10,
			sortOrder: -1,
		},
	});
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		// getUserData({ transaction_type: "debit", transaction_status: "pending" });
		getUserData({
			...filter.filterS,
			pageNumber: page || 1,
			pageSize: rowsPerPage || 10,
		});
		dispatch(
			getWallets({}, (errors, res) => {
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	}, []);

	useEffect(() => {
		getUserData({
			...filter.filterS,
			pageNumber: page || 1,
			pageSize: rowsPerPage || 10,
		});
	}, [page, rowsPerPage]);

	useEffect(() => {
		setUserData({ requestList: userRequests, totalCount: totalCount })
	}, [transactions]);

	const handleChange = (e) => {
		const { id, value, name } = e.target;
		if (name) {
			setState((prevState) => ({
				...prevState,
				[name]: value,
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				[id]: value,
			}));
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setState((prevState) => ({
			...prevState,
			submitted: true,
		}));
		let reqData = {
			wallet_id: state.wallet_id?._id,
			amount: state.amount,
			transaction_type: state.transaction_type,
			transaction_mode: state.transaction_mode,
		};
		dispatch(
			addMoneyToWallet(reqData, (err, res) => {
				if (res.data.error) {
					toastr.success("error", res.data.message, 500);
				} else {
					toastr.success("success", res.data.message);
				}
				// toastr.success(
				// 	"Success",
				// 	"Money Requested Successfully Please check and approve",
				// );
				// dispatch(
				// 	getUserRequest({}, (errors, res) => {
				// 		dispatch({ type: "LOADING_SUCCESS" });
				// 	}),
				// );
				getUserData({
					...filter.filterS,
					pageNumber: page || 1,
					pageSize: rowsPerPage || 10,
				});
			}),
		);
	};
	// const handleFilterChange = (e) => {
	//   e.preventDefault();
	//   var filterS = {
	//     status: e.currentTarget.getAttribute("dropdownvalue")
	//   }
	//   setFilter(prevState => ({
	//     ...prevState,
	//     filterS: { ...filterS }
	//   }));
	//   getUserData({
	//     transaction_type: e.currentTarget.getAttribute("dropdownvalue")
	//   })
	// };
	const onUpdate = (transaction, status) => {
		let updatData = {
			transaction_id: transaction._id,
			transaction_type: transaction.transaction_type,
			// transaction_status_admin: status,
			transaction_status: status,
			// transaction_status: transaction.transaction_status,
			role: "admin",
		};
		dispatch(
			updateUserRequest(updatData, (err, res) => {
				if (res.data.message) {
					toastr.success("error", res.data.message);
				} else {
					toastr.success("Success", "Request Updated Successfully");
				}
				// getUserData({ transaction_type: "debit" });
				getUserData({
					...filter.filterS,
					pageNumber: page || 1,
					pageSize: rowsPerPage || 10,
				});
			}),
		);
	};


	const getUserData = (query = {
		...filter.filterS,
		pageNumber: page || 1,
		pageSize: rowsPerPage || 10,
	}) => {
		// console.log(query);
		dispatch({ type: "LOADING_START" });
		dispatch(
			getUserRequest(query, (errors, res) => {
				// let dat = res?.data?.filter((item) => {
				// 	if (
				// 		item?.transaction_type === "debit" &&
				// 		item?.transaction_mode !== "bets" &&
				// 		item?.transaction_mode !== "win"
				// 	) {
				// 		return item;
				// 	}
				// });
				// setUserData(dat);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};
	const status = {
		debit: "Debit",
		credit: "Credit",
	};
	const handleFilterChange = (e) => {
		e.preventDefault();
		const { id, value } = e.target;
		setFilter((prevState) => ({
			...prevState,
			filterS: {
				...filter.filterS,
				[id]: value,
			},
		}));
	};

	const getuserNamefromWalletId = (list) => {
		let data =
			walletsList?.length > 0 &&
			walletsList?.filter((item) => item?._id === list?.wallet_id?._id);

		var username = "";
		username =
			data?.length > 0 &&
			data[0]?.user_id?.first_name + " " + data[0].user_id?.last_name;
		return username || "NO FOUND";
	};
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData.length) : 0;
	return (
		<>
			{/* Page content */}
			<Container className='mt-7' fluid>
				{/* Dark table */}
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-secondary shadow'>
							<CardHeader className='bg-transparent border-0 mt-7'>
								<h3 className='mb-0 text-center'>
									Withdraw Money From User Account
								</h3>
							</CardHeader>
							<CardBody>
								<Form role='form'>
									<FormGroup className='mb-3'>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-ui-04' />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												type='select'
												autoComplete='new-name'
												value={state.transaction_mode}
												onChange={handleChange}
												className='form-control'
												id='transaction_mode'
												placeholder='Select Payment Mode'
												name='transaction_mode'
												disabled
												required>
												<option value={""}>
													Select Payment Mode
												</option>
												<option value={"withdraw"}>
													withdraw
												</option>
												<option value={"phonepe"}>Phonepe</option>
												<option value={"gpay"}>Gpay</option>
												<option value={"paytm"}>Paytm</option>
												<option value={"card"}>Card</option>
												<option value={"bets"}>Bets</option>
												<option value={"refer"}>Referral</option>
											</Input>
										</InputGroup>
										{state.submitted && !state.transaction_mode && (
											<div className='error'>
												Transaction Mode is required
											</div>
										)}
									</FormGroup>
									<FormGroup className='mb-3'>
										<InputGroup className='input-group-alternative'>
											<Autocomplete
												filterOptions={filterOptions}
												id='wallet_id'
												options={walletData}
												value={state.wallet_id}
												onChange={(option, value) => {
													setState((prevState) => ({
														...prevState,
														wallet_id: value,
													}));
												}}
												renderOption={(option) => (
													<React.Fragment>
														<Grid item xs>
															{option?.user_id?.first_name +
																option?.user_id
																	?.last_name}
															<Typography
																variant='body2'
																color='textSecondary'>
																{option?.phone_number}
															</Typography>
														</Grid>
													</React.Fragment>
												)}
												fullWidth={true}
												getOptionLabel={(option) =>
													option?.phone_number
														? option?.phone_number
														: ""
												}
												renderInput={(params) => (
													<TextField
														style={{ color: "white" }}
														{...params}
														label='Select Phone Number'
														variant='outlined'
														fullWidth
													/>
												)}
											/>
										</InputGroup>
										{state.submitted && !state.wallet_id && (
											<div className='error'>
												Wallet name is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<TextField
												fullWidth
												type='number'
												variant='outlined'
												id='amount'
												placeholder='Enter amount'
												name='amount'
												value={state.amount}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.amount && (
											<div className='error'>
												Amount is required
											</div>
										)}
									</FormGroup>
									<FormGroup className='mb-3'>
										{/* <InputGroup className='input-group-alternative'>
											<Select
												fullWidth
												type='select'
												value={state.transaction_type}
												onChange={handleChange}
												inputProps={{
													id: "transaction_type",
													"aria-label": "Without label",
												}}
												variant='outlined'
												id='transaction_type'
												name='transaction_type'>
												<option value='none' disabled>
													Select Transaction Type
												</option>
												<option value='credit'>Credit</option>
												<option value='debit'> Debit</option>
											</Select>
										</InputGroup> */}
										{/* {state.submitted &&
											!state.transaction_type !== "none" && (
												<div className='error'>
													Transaction Type is required
												</div>
											)} */}
									</FormGroup>

									<div className='text-center'>
										<Button
											disabled={
												!(state.amount && state.wallet_id)
												// state.transaction_type !== "none" &&
											}
											onClick={handleSubmit}
											className='my-4'
											// color='primary'
											style={{
												backgroundColor: "#00BBF9",
												border: "none",
											}}
											type='button'>
											{" "}
											withdraw
										</Button>
									</div>
								</Form>
							</CardBody>
						</Card>
					</div>
				</Row>
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-default shadow'>
							<CardHeader className='bg-transparent border-0'>
								<h3 className='text-white mb-0'>Withdraw Payments</h3>
								<div
									className='d-flex mt-2'
									style={{ flexDirection: "column" }}>
									<InputGroup size='xl' className='ml-2 w-100 mt-2'>
										<Input
											id='phone_number'
											type='text'
											name='phone_number'
											value={filter.filterS.phone_number || ""}
											onChange={handleFilterChange}
											placeholder='Search Phone Number'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											id='amount'
											type='number'
											name='amount'
											value={filter.filterS.amount || ""}
											onChange={handleFilterChange}
											placeholder='Search Amount'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											id='createdAt'
											type='date'
											name='createdAt'
											value={filter.filterS.createdAt || ""}
											onChange={handleFilterChange}
											placeholder='Search for Date'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='select'
											value={filter.filterS.transaction_type}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_type'
											name='transaction_type'
											required>
											<option key='select' value=''>
												Select Transaction Type
											</option>
											<option key='debit' value='debit'>
												Debit
											</option>
											{/* <option key='credit' value='credit'>
												Credit
											</option> */}
										</Input>
									</InputGroup>

									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='select'
											autoComplete='new-name'
											value={filter.filterS.transaction_mode}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_mode'
											placeholder='Select Payment Method'
											name='transaction_mode'
											required>
											<option key='select' value=''>
												Select Payment Method
											</option>
											<option key='withdraw' value='withdraw'>
												withdraw
											</option>
											<option key='phonepe' value='phonepe'>
												Phonepe
											</option>
											<option key='gpay' value='gpay'>
												Gpay
											</option>
											<option key='paytm' value='paytm'>
												Paytm
											</option>
											<option key='card' value='card'>
												Card
											</option>
											<option key='bets' value='bets'>
												Bets
											</option>

											<option key='win' value='win'>
												Win
											</option>
										</Input>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='select'
											autoComplete='new-name'
											value={filter.filterS.transaction_status}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_status'
											placeholder='Select Transaction Status'
											name='transaction_status'
											required>
											<option key='' value=''>
												Select Transaction Status
											</option>
											<option key='pending' value='pending'>
												pending
											</option>
											<option key='approved' value='approved'>
												approved
											</option>
											<option key='rejected' value='rejected'>
												rejected
											</option>
										</Input>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='Button'
											onClick={() =>
												getUserData({
													...filter.filterS,
													pageNumber: page || 1,
													pageSize: rowsPerPage || 10,
												})
											}
											className='bg-default text-white'
											value={"Search"}></Input>
									</InputGroup>
								</div>
							</CardHeader>
							<TableContainer>
								<Table
									className='align-items-center table-dark table-flush'
									responsive>
									<TableHead>
										<TableRow>
											<TableCell
												className='text-white'
												align='center'>
												Name
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Phone
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Type
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Request Amount
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Payment Mode
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Wallet
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Date
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Status
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Action
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{userData && userData?.requestList?.length
											? userData?.requestList
												// .slice(
												// 	page * rowsPerPage,
												// 	page * rowsPerPage + rowsPerPage,
												// )
												.map((list, index) => {
													return (
														<TableRow key={index}>
															<TableCell
																scope='row'
																align='center'>
																<span className='mb-0 text-sm text-white'>
																	{walletsList?.length >
																		0 &&
																		getuserNamefromWalletId(
																			list,
																		)}
																</span>
															</TableCell>
															<TableCell
																scope='row'
																align='center'>
																<span className='mb-0 text-sm text-white'>
																	{
																		list
																			?.wallet_id
																			?.phone_number
																	}
																</span>
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{
																	list?.transaction_mode + "=" + list?.googlePayNumer
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{list?.amount}
															</TableCell>
															<TableCell
																className={`${list?.transaction_type ===
																	"debit"
																	? "text-danger"
																	: "text-success"
																	}`}
																align='center'>
																{
																	list?.transaction_type
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{
																	list?.wallet_id
																		?.total_amount?.toFixed(2) || 0
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{moment(
																	list?.createdAt,
																).format(
																	"DD-MM-YYYY, h:mm a",
																)}
															</TableCell>
															{list?.transaction_status ===
																"rejected" ? (
																<TableCell
																	className='text-danger'
																	align='center'>
																	{
																		list?.transaction_status
																	}
																</TableCell>
															) : list?.transaction_status ===
																"pending" ? (
																<TableCell
																	className='text-warning'
																	align='center'>
																	{
																		list?.transaction_status
																	}
																</TableCell>
															) : (
																<TableCell
																	className='text-success'
																	align='center'>
																	{
																		list?.transaction_status
																	}
																</TableCell>
															)}
															<TableCell>
																<React.Fragment>
																	{list.transaction_status ===
																		"pending" && (
																			<>
																				<button
																					className={
																						"btn btn-success"
																					}
																					onClick={() =>
																						onUpdate(
																							list,
																							"approved",
																						)
																					}>
																					Approve
																				</button>

																				<button
																					className={
																						"btn btn-secondary"
																					}
																					style={{
																						marginLeft: 8,
																					}}
																					onClick={() =>
																						onUpdate(
																							list,
																							"rejected",
																						)
																					}>
																					Reject
																				</button>
																			</>
																		)}
																	{list.transaction_status ===
																		"approved" && (
																			<>
																				<button
																					className={
																						"btn btn-secondary"
																					}
																					style={{
																						marginLeft: 8,
																					}}
																					onClick={() =>
																						onUpdate(
																							list,
																							"rejected",
																						)
																					}>
																					Reject
																				</button>
																			</>
																		)}
																</React.Fragment>
															</TableCell>
														</TableRow>
													);
												})
											: ""}
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td>
												Total :{" "}
												{userData &&
													userData?.requestList?.length &&
													userData?.requestList
														// ?.slice(
														// 	page * rowsPerPage,
														// 	page * rowsPerPage +
														// 	rowsPerPage,
														// )
														.reduce(
															(totalValue, item) =>
																totalValue +
																Number(item?.amount),
															0,
														)
														.toFixed(2)}
											</td>
										</tr>
										{/* {emptyRows > 0 && (
											<TableRow
												style={{
													height: 53 * emptyRows,
												}}>
												<TableCell colSpan={6} />
											</TableRow>
										)} */}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TablePagination
												className='text-white'
												rowsPerPageOptions={[
													5,
													10,
													25,
													50,
													100,
													// { label: "All", value: -1 },
												]}
												colSpan={5}
												count={totalCount}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onRowsPerPageChange={
													handleChangeRowsPerPage
												}
											/>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
							{/* <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Payment Mode</th>
                    <th scope="col">Date</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {userData && userData.length ?
                    userData.map((list, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">
                            <span className="mb-0 text-sm">{list?.wallet_id?.phone_number}</span>
                          </th>
                          <td>{list?.transaction_mode}</td>
                          <td>{list?.amount}</td>
                          <td>{list?.transaction_type}</td>
                          <td>{moment(list?.createdAt).format('DD-MM-YYYY, h:mm a')}</td>
                          <td>
                            <React.Fragment>
                              <button
                                className={"btn-success"}
                                onClick={() => onUpdate(list, 'approved')}
                              >
                                Approve
                              </button>

                              <button
                                className={"btn-secondary"}
                                style={{ marginLeft: 8 }}
                                onClick={() => onUpdate(list, 'rejected')}
                              >
                                Reject
                              </button>
                            </React.Fragment>
                          </td>
                        </tr>
                      )
                    }) : ''
                  }
                </tbody>
              </Table> */}
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

function mapStateToProps(state) {
	return {
		user: state.session.user,
		wallets: state.wallets,
		transactionHistory: state.transactionHistory,
	};
}

export default withRouter(connect(mapStateToProps, {})(WithdrawRequest));
