/** @format */

const API = {
	session: "/network/session",
	network: "/network/get",
	sessionclient: "/users/sessionrefadmin",
	login: "/users/asasignin",
	userlogout: "/users/logout/admin",
	register: "/users/signup",
	updateUser: "/users/updateUserProfile",
	addMoneyToWalletUser: "/transaction/requestMoney",
	addMoneyToWallet: "/transaction/requestMoneyAdmin",
	usersList: "/users/findUser",
	usersListByIdAs: "/users/userslistWithId",
	usersListById: "/users/userslistWithIdAdmin",
	softdeleteuser: "/users/softdeleteuser",
	deleteuserhard: "/users/deleteuserhard",
	usersFilteredList: "/users/usersFilteredList",
	usersTransactionsList: "/subscription_transactions/usersTransactionsList",
	forgotpassword: "/users/forgotpassword",
	getJobSeekerQuestionsList: "/job_seekers_questions/getJobSeekerQuestionsList",
	getQuestions: "/questions/getQuestions",
	createQuestions: "/questions/createQuestion",
	updateQuestion: "/questions/updateQuestion",
	createSurvey: "/survey/createSurvey",
	getOffers: "/offers/getOffers",
	getOffersStatusCount: "/offers/getOffersCount_accStatus",
	createOffer: "/offers/createOffer",
	acceptOffer: "/offers/acceptOffer",
	createShortlisted: "/shortList/createShortlisted",
	getShortlisted: "/shortList/getShortlisted",
	removeShortlisted: "/shortList/removeShortlisted",
	getMessages: "/chat/getMessages",
	addMessages: "/chat/addMessages",
	getWallets: "/wallets/getWallets",
	updateWallets: "/wallets/updateWallet/:id",
	getTransactionHistory: "/transaction/getTransactionsHistory",
	getGameBets: "/game_bets/getGameBets",
	getGameBetsWithUserId: "/game_bets/getGameBetsWithUserId",
	getGames: "/games/getGames",
	getTodayResult: "/game_results/getLatestResult",
	addGame: "/games/createGame",
	updateAddedGame: "/games/updateGameById",
	getGamesResult: "/game_results/getResults",
	removeGameResults: "/game_results/removeGameResults",
	deleteGamesWithId: "/games/removeGame",
	getGamesBets: "/game_bets/getGameBets",
	getUserRequest: "/transaction/getPendingRequest",
	getDashboardApi: "/transaction/dashboard_analytics",
	getUpiDataList: "/upi/getUpiIds",
	createUpiId: "/upi/createUpi",
	deleteUpiIdDataApi: "/upi/removeUpi",
	approveRequest: "/transaction/approveRequest",
	deleteTransactions: "/transaction/removeTransactionHistory",
	updateUpiIdDataApi: "/upi/updateUpi",
	updateNewsService: "/news/updateNewsService",
	getNewsDataList: "/news/getNewsList",
	createNewsFirst: "/news/createNews",
	createMessagesFirst: "/messages/createMessages",
	updateMessageService: "/messages/updateMessageService",
	getMessageService: "/messages/getMessageList",
	updateGameResults: "/games/updateGame",
	getCommissionList: "/commission/getCommissionList",
	updateCommissionList: "/commission/updateCommission",
	deleteCommission: "/commission/deleteCommission",
	deleteGameBetsApi: "/game_bets/removeGameBets",
};

export default API;
