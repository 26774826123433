/** @format */

import { sessionService } from "redux-react-session";
import {
	GET_TRANSACTIONS_HISTORY_START,
	GET_TRANSACTIONS_HISTORY_SUCCESS,
	GET_TRANSACTIONS_HISTORY_FAILURE,
	SET_TRANSACTIONS_HISTORY_DATA,
	GET_USER_REQUEST_START,
	GET_USER_REQUEST_SUCCESS,
	GET_USER_REQUEST_FAILURE,

	GET_DASHBOARD_REQUEST_START,
	GET_DASHBOARD_REQUEST_SUCCESS,
	GET_DASHBOARD_REQUEST_FAILURE,

	UPDATE_USER_REQUEST_START,
	UPDATE_USER_REQUEST_SUCCESS,
	UPDATE_USER_REQUEST_FAILURE,
	GET_COMMISSION_DELETE_START,
	GET_COMMISSION_DELETE_SUCCESS,
	GET_COMMISSION_DELETE_FAILURE,
	DELETE_TRANSACTIONS_HISTORY_FAILURE,
	DELETE_TRANSACTIONS_HISTORY_START,
	DELETE_TRANSACTIONS_HISTORY_SUCCESS,
} from "../actionConstants";

import dispatchActionToReducer, {
	SimpleDispatchActionToReducer,
} from "../actionDispatcher";

import {
	getTransactionHistory as getTransactionHistoryService,
	deleteTransactions as deleteTransactionWithId,
	getUserRequest as getUserRequestService,
	getDashboardServices as getDashboardService,
	updateUserRequest as updateUserRequestService,
	deleteCommission as deleteCommissionService,
} from "../services";

export const setTransactionHistory = (data, callback) => {
	return SimpleDispatchActionToReducer(SET_TRANSACTIONS_HISTORY_DATA, data, callback);
};

export const getTransactionHistory = (query, callback) => {
	return dispatchActionToReducer(
		getTransactionHistoryService(query),
		GET_TRANSACTIONS_HISTORY_START,
		GET_TRANSACTIONS_HISTORY_SUCCESS,
		GET_TRANSACTIONS_HISTORY_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const deleteTransactionHistoryWithId = (query, callback) => {
	return dispatchActionToReducer(
		deleteTransactionWithId(query),
		DELETE_TRANSACTIONS_HISTORY_FAILURE,
		DELETE_TRANSACTIONS_HISTORY_START,
		DELETE_TRANSACTIONS_HISTORY_SUCCESS,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};

export const getUserRequest = (query, callback) => {
	return dispatchActionToReducer(
		getUserRequestService(query),
		GET_USER_REQUEST_START,
		GET_USER_REQUEST_SUCCESS,
		GET_USER_REQUEST_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const getDashboard = (query, callback) => {
	return dispatchActionToReducer(
		getDashboardService(query),
		GET_DASHBOARD_REQUEST_START,
		GET_DASHBOARD_REQUEST_SUCCESS,
		GET_DASHBOARD_REQUEST_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};

export const updateUserRequest = (updatData, callback) => {
	return dispatchActionToReducer(
		updateUserRequestService(updatData),
		UPDATE_USER_REQUEST_START,
		UPDATE_USER_REQUEST_SUCCESS,
		UPDATE_USER_REQUEST_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const deleteCommissionAction = (updatData, callback) => {
	return dispatchActionToReducer(
		deleteCommissionService(updatData),
		GET_COMMISSION_DELETE_START,
		GET_COMMISSION_DELETE_SUCCESS,
		GET_COMMISSION_DELETE_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
