/** @format */

import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { Link, withRouter, useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
// reactstrap components
import {
	Card,
	CardHeader,
	Container,
	Row,
	Button,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Label,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import {
	getGameResults,
	updateGameResults,
	getGames,
	removeGameResults,
} from "../../redux/actions";
import {
	TableBody,
	TableCell,
	Table,
	TablePagination,
	TableRow,
	TableContainer,
	TableHead,
	TableFooter,
} from "@material-ui/core";

const GameResults = (props) => {
	// console.log(props, "PROPSSSSSS");
	const { gameResults, gamesList } = props.games;
	const dispatch = useDispatch();
	const [gamesSet, setGames] = useState([]);
	const [state, setState] = useState({
		game_name: "",
		winning_bet_number: "",
		winning_amount: "",
		submitted: false,
		next_date_status: "0",
		createdDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
		startDate: moment().format("YYYY-MM-DD HH:mm:ss"),
		endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
		result_time: moment().format("YYYY-MM-DD HH:mm:ss"),
	});
	const [filter, setFilter] = useState({
		filterS: {
			game_id: "",
			winning_bet_number: "",
			winning_amount: "",
			// pageNumber: 1
		},
	});
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getGames((errors, res) => {
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
		getGameResultData({
			...filter.filterS
		})
	}, []);
	useEffect(() => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getGames((errors, res) => {
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
		getGameResultData({
			...filter.filterS
		})
	}, []);

	const handleFilterChange = (e) => {
		e.preventDefault();
		const { id, value } = e.target;
		setFilter((prevState) => ({
			...prevState,
			filterS: {
				...filter.filterS,
				[id]: value,
			},
		}));
	};

	const getGameResultData = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getGameResults(query, (errors, res) => {
				setGames(res?.response);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};

	const handleChange = (e) => {
		const { id, value } = e.target;
		// let

		if (id === "game_name" && value !== "") {
			console.log("game", value);
			let findGame = gamesList.find((game) => game._id === value);
			// console.log("findGame", findGame);
			let next = findGame.next_date_status === "1" ? true : false;

			let startTime = moment(findGame.start_date).format("HH:mm:ss");
			let endTime = moment(findGame.end_date).format("HH:mm:ss");
			let resultTime = moment(findGame.result_time).format("HH:mm:ss");
			// console.log("start", startTime);
			// console.log("end", endTime);
			let currentDate = moment().format("YYYY-MM-DD");
			// console.log("currentDate", currentDate);
			let yesterDay = moment().subtract(1, "day").format("YYYY-MM-DD");
			// console.log("yesterDay", yesterDay);
			let dates = next ? yesterDay : currentDate;

			let startDate = moment(dates + " " + startTime).format("YYYY-MM-DD HH:mm:ss");

			let endDate = moment(currentDate + " " + endTime).format(
				"YYYY-MM-DD HH:mm:ss",
			);
			let resultDate = moment(currentDate + " " + resultTime).format(
				"YYYY-MM-DD HH:mm:ss",
			);

			// console.log("startDate", startDate);
			let result_time = resultDate || moment().format("YYYY-MM-DD HH:mm:ss");
			setState((prevState) => ({
				...prevState,
				[id]: value,
				startDate: startDate,
				endDate: endDate,
				result_time: result_time,
			}));
		} else {
			// console.log(new Date(moment(value).format("YYYY,MM,DD")));
			setState((prevState) => ({
				...prevState,
				[id]: value,
			}));
		}
		// console.log(id, value);
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setState((prevState) => ({
			...prevState,
			submitted: true,
		}));
		// cosnole.log(state);
		let dt = new Date(moment(state.result_time).format("YYYY,MM,DD"));
		// console.log(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
		let createdAt = `${dt.getFullYear()},${dt.getMonth() + 1},${dt.getDate()}`;

		let reqData = {
			_id: state.game_name,
			role: "admin",
			createdAt: createdAt,
			updatedAt: createdAt,
			startDate: state.startDate,
			endDate: state.endDate,
			today_game_result: {
				game_bet_id: state.game_name,
				winning_bet_number: state.winning_bet_number,
				winning_amount: state.winning_amount,
				createdDate: createdAt,
			},
		};
		if (reqData) {
			dispatch({ type: "LOADING_START" });
			dispatch(
				updateGameResults(reqData, (res, errors) => {
					// console.log(res);
					toastr.success("Success", "Game Result Added");
					setState({
						game_name: "",
						winning_bet_number: "",
						winning_amount: "",
						submitted: true,
					});
					getGameResultData({
						...filter.filterS
					});
					dispatch({ type: "LOADING_SUCCESS" });
				}),
			);
		}
	};
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - gameResults.length) : 0;

	const handleSendWelcomeEmail = (e) => {
		// console.log(e);
	};
	const handleStatusChange = (e) => {
		// console.log(e);
	};

	const handleDeleteGameResult = (e) => {
		// e.preventDefault();
		if (window.confirm("Are you sure you want to Delete?")) {
			let query = { _id: e } || {};
			dispatch({ type: "LOADING_START" });
			dispatch(
				removeGameResults(query, (errors, res) => {
					// console.log(res);
					if (res.status === 200) {
						toastr.success("Success", "Game Result Deleted");
						getGameResultData({
							...filter.filterS
						});
					}
					// setGames(res.response);

					dispatch({ type: "LOADING_SUCCESS" });
				}),
			);
		}

		// removeGameResults
	};

	// console.log(gamesList);

	return (
		<>
			{/* <Header /> */}
			{/* Page content */}
			<Container className='mt-7' fluid>
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-default shadow'>
							<CardHeader className='bg-transparent border-0 mt-7'>
								<h3 className='text-white mb-0 text-center'>
									Add Game Result
								</h3>
							</CardHeader>
							<CardBody>
								<Form role='form'>
									<FormGroup className='mb-3'>
										<label>Game</label>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-ui-04' />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												type='select'
												autoComplete='new-name'
												value={state.game_name}
												onChange={(e) => handleChange(e)}
												className='form-control'
												id='game_name'
												placeholder='Select Game'
												name='game_name'
												required>
												<option key={"no"} value=''>
													Select Game
												</option>
												{gamesList && gamesList.length
													? gamesList.map((list, index) => {
														return (
															<option
																key={index}
																value={list._id}>
																{list?.game_name}
															</option>
														);
													})
													: ""}
											</Input>
										</InputGroup>
										{state.submitted && !state.game_name && (
											<div className='error'>
												Game name is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										Winning Bet Number
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-money-coins' />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												type='number'
												autoComplete='new-wbetno'
												className='form-control'
												id='winning_bet_number'
												placeholder='Enter winning bet number'
												name='password'
												value={state.winning_bet_number}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.winning_bet_number && (
											<div className='error'>
												Winning Bet Number is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<label>Start Time</label>
										<InputGroup size='xl' className='w-100 mt-2'>
											<InputGroupAddon
												addonType='prepend'
												className='d-inline'>
												<Button className='bg-default shadow'>
													<i className='ni ni-calendar-grid-58 text-white' />
												</Button>
											</InputGroupAddon>
											<Input
												type='datetime-local'
												autoComplete='new-sdate'
												id='startDate'
												placeholder='Start Date'
												name='startDate'
												value={state.startDate}
												onChange={handleChange}
												required
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<label>End Time</label>
										<InputGroup size='xl' className='w-100 mt-2'>
											<InputGroupAddon
												addonType='prepend'
												className='d-inline'>
												<Button className='bg-default shadow'>
													<i className='ni ni-calendar-grid-58 text-white' />
												</Button>
											</InputGroupAddon>
											<Input
												type='datetime-local'
												autoComplete='new-sdate'
												id='endDate'
												placeholder='Start Date'
												name='endDate'
												// disabled={true}
												value={state.endDate}
												onChange={handleChange}
												required
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<label>Result Time</label>
										<InputGroup size='xl' className='w-100 mt-2'>
											<InputGroupAddon
												addonType='prepend'
												className='d-inline'>
												<Button className='bg-default shadow'>
													<i className='ni ni-calendar-grid-58 text-white' />
												</Button>
											</InputGroupAddon>
											<Input
												type='datetime-local'
												autoComplete='new-sdate'
												id='result_time'
												placeholder='Start Date'
												name='result_time'
												// disabled={true}
												value={state.result_time}
												onChange={handleChange}
												required
											/>
										</InputGroup>
									</FormGroup>
									{/* <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-money-coins" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="number"
                        autoComplete="new-wamount"
                        className="form-control"
                        id="winning_amount"
                        placeholder="Winning Amount"
                        name="winning_amount"
                        value={state.winning_amount}
                        onChange={handleChange}
                        required
                      />
                    </InputGroup>
                    {
                      state.submitted && !state.winning_amount &&
                      <div className="error">Winning Amount is required</div>
                    }
                  </FormGroup> */}
									<div className='text-center'>
										<Button
											disabled={
												!(
													state.game_name &&
													state.winning_bet_number &&
													state.result_time
												)
											}
											onClick={handleSubmit}
											className='my-4'
											// color='primary'
											style={{
												backgroundColor: "#00F5d4",
												border: "none",
											}}
											type='button'>
											Add Result
										</Button>
									</div>
								</Form>
							</CardBody>
						</Card>
					</div>
				</Row>

				{/* Dark table */}
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-default shadow'>
							<CardHeader className='bg-transparent border-0'>
								<h3 className='text-white mb-0'>Games Result List</h3>
								<div
									className='d-flex mt-2'
									style={{ flexDirection: "column" }}>
									<InputGroup size='xl' className='ml-2 mt-2 w-100'>
										<Input
											type='select'
											autoComplete='new-name'
											value={filter.filterS.game_id}
											onChange={handleFilterChange}
											id='game_id'
											placeholder='Select Game'
											name='game_id'
											required>
											<option key='select' value=''>
												Select Game
											</option>
											{gamesList && gamesList.length
												? gamesList.map((list, index) => {
													return (
														<option
															key={index}
															value={list._id}>
															{list?.game_name}
														</option>
													);
												})
												: ""}
										</Input>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											id='winning_bet_number'
											type='text'
											name='winning_bet_number'
											value={filter.filterS.winning_bet_number}
											onChange={handleFilterChange}
											placeholder='Search Winning Bet Number'
										/>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											type='Button'
											onClick={() =>
												getGameResultData({
													...filter.filterS,
													pageNumber: page || 1,
													pageSize: rowsPerPage || 10,
												})
											}
											className='bg-default text-white'
											value={"Search"}></Input>
									</InputGroup>
								</div>
							</CardHeader>
							<TableContainer>
								<Table
									className='align-items-center table-dark table-flush'
									responsive>
									<TableHead>
										<TableRow>
											<TableCell className='text-white'>
												Game Name
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Winning Bet Number
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Result Date
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Action
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{gameResults && gameResults.length
											? gameResults
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage,
												)
												.map((list, index) => {
													return (
														<TableRow>
															<TableCell className='text-white'>
																{
																	list?.game_id
																		?.game_name
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{
																	list?.winning_bet_number
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{moment(
																	new Date(
																		list?.updated_at,
																	),
																).format(
																	"DD-MM-YYYY, h:mm:ss a",
																)}
															</TableCell>
															{/* <TableCell
																	className='text-white'
																	align='center'>
																	<Button
																		onClick={() =>
																			handleStatusChange(
																				list?._id,
																				list?.status,
																			)
																		}
																		variant='contained'
																		color={
																			list?.status ===
																			"active"
																				? "success"
																				: "danger"
																		}>
																		{list?.status}
																	</Button>
																</TableCell> */}
															<TableCell
																className='text-white'
																align='center'>
																<Button
																	onClick={() =>
																		handleDeleteGameResult(
																			list?._id,
																		)
																	}
																	variant='contained'
																	color='danger'>
																	Delete
																</Button>
																<Button
																	onClick={() =>
																		handleStatusChange(
																			list?._id,
																			list?.status,
																		)
																	}
																	variant='contained'
																	color='primary'>
																	Status
																</Button>
																<Button
																	onClick={(e) =>
																		handleSendWelcomeEmail(
																			e,
																			list?.phone_number,
																		)
																	}
																	variant='contained'
																	color='secondary'>
																	Reject
																</Button>
															</TableCell>
														</TableRow>
													);
												})
											: ""}
										{/* {emptyRows > 0 && (
											<TableRow
												style={{
													height: 53 * emptyRows,
												}}>
												<TableCell colSpan={6} />
											</TableRow>
										)} */}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TablePagination
												className='text-white'
												rowsPerPageOptions={[
													5,
													10,
													25,
													{ label: "All", value: -1 },
												]}
												colSpan={5}
												count={gameResults.length}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onRowsPerPageChange={
													handleChangeRowsPerPage
												}
											/>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
							{/* <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Game Name</th>
                    <th scope="col">Winning Bet Number</th>
                    <th scope="col">Winning Amount</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {gameResults && gameResults.length ?
                    gameResults.map((list, index) => {
                      return (
                        <tr>
                          <td>{list?.game_id?.game_name}</td>
                          <td>{list?.winning_bet_number}</td>
                          <td>{list?.winning_amount}</td>
                        </tr>
                      )
                    }) : ''
                  }
                </tbody>
              </Table> */}
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

function mapStateToProps(state) {
	return {
		user: state.session.user,
		games: state.games,
	};
}

export default withRouter(connect(mapStateToProps, {})(GameResults));
