/**
 * /*eslint-disable
 *
 * @format
 */

import configObj from "config/hostConfig";
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink, Button } from "reactstrap";

const Footer = () => {
	return (
		<footer className='footer'>
			<Row className='align-items-center justify-content-xl-between'>
				<Col xl='6'>
					<div className='copyright text-center text-xl-left text-muted'>
						© {new Date().getFullYear()}{" "}
						<a
							className='font-weight-bold ml-1'
							href='/admin/index'
							// rel='noopener noreferrer'
							// target='_blank'
						>
							{configObj?.appname || ""}
						</a>
					</div>
				</Col>

				<Col xl='6'>
					<Nav className='nav-footer justify-content-between'>
						<Button style={{ backgroundColor: "#00BBF9", border: "none" }}>
							<NavItem>
								<Link
									to='/admin/paymentRequest'
									className='text-white bold letter-spacing-2'
									// rel='noopener noreferrer'
									// target='_blank'
								>
									MONEY
								</Link>
							</NavItem>
						</Button>
						<Button style={{ backgroundColor: "#00F5d4", border: "none" }}>
							<NavItem>
								<Link
									to='/admin/gameResults'
									className='text-dark'

									// rel='noopener noreferrer'
									// color='secondary'
									// target='_blank'
								>
									RESULT
								</Link>
							</NavItem>
						</Button>
						{"  "}

						<NavItem>
							<NavLink
								href=''
								// rel='noopener noreferrer'
								// target='_blank'
							></NavLink>
						</NavItem>
					</Nav>
				</Col>
			</Row>
		</footer>
	);
};

export default Footer;
