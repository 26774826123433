/** @format */

import { sessionService } from "redux-react-session";
import {
	GET_GAME_BETS_START,
	GET_GAME_BETS_SUCCESS,
	GET_GAME_BETS_FAILURE,
	GET_GAME_BET_DELETE_START,
	GET_GAME_BET_DELETE_SUCCESS,
	GET_GAME_BET_DELETE_FAILURE,
} from "../actionConstants";

import dispatchActionToReducer, {
	SimpleDispatchActionToReducer,
} from "../actionDispatcher";

import {
	getGameBets as getGameBetsService,
	deleteGameBets as deleteGameBetsService,
} from "../services";

export const getGameBets = (query, callback) => {
	return dispatchActionToReducer(
		getGameBetsService(query),
		GET_GAME_BETS_START,
		GET_GAME_BETS_SUCCESS,
		GET_GAME_BETS_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};

export const deleteGameBetsAction = (updatData, callback) => {
	return dispatchActionToReducer(
		deleteGameBetsService(updatData),
		GET_GAME_BET_DELETE_START,
		GET_GAME_BET_DELETE_SUCCESS,
		GET_GAME_BET_DELETE_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
