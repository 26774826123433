/** @format */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	TablePagination,
} from "@material-ui/core";
import moment from "moment";

function rand() {
	return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
	const top = 50 + rand();
	const left = 50 + rand();

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}

const useStyles = makeStyles((theme) => ({
	paper: {
		position: "absolute",
	},
}));

export default function SimpleModal(props) {
	const classes = useStyles();
	// getModalStyle is not a pure function, we roll the style only on the first render
	const [modalStyle] = React.useState(getModalStyle);

	const handleOpen = () => {
		props.setOpen(true);
	};

	const handleClose = () => {
		props?.setOpen(false);
		props?.setModalData([]);
	};

	const body = (
		<div style={modalStyle} className={classes.paper}>
			<TableContainer>
				<Table className='align-items-center table-dark' responsive>
					<TableHead>
						<TableRow>
							<TableCell className='text-white' align='center'>
								Play Time
							</TableCell>
							<TableCell className='text-white' align='center'>
								TYPE
							</TableCell>
							<TableCell className='text-white' align='center'>
								Number
							</TableCell>
							<TableCell className='text-white' align='center'>
								Amount
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props?.data?.map((item) => {
							return (
								<TableRow>
									<TableCell className='text-white' align='center'>
										{moment(item?.play_time).format(
											"DD-MM-YYYY, hh:mm a",
										)}
									</TableCell>
									<TableCell className='text-white' align='center'>
										{item?.bet_type}
									</TableCell>
									<TableCell className='text-white' align='center'>
										{item?.winr_bet_number}
									</TableCell>
									<TableCell className='text-white' align='center'>
										{item?.winr_bet_amount}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);

	return (
		<div>
			{/* <button type='button' onClick={handleOpen}>
				Open Modal
			</button> */}
			<Modal
				open={props.open}
				onClose={handleClose}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'>
				{body}
			</Modal>
		</div>
	);
}
