/** @format */

import { sessionService } from "redux-react-session";
import {
	GET_TRANSACTIONS_HISTORY_START,
	GET_TRANSACTIONS_HISTORY_SUCCESS,
	GET_TRANSACTIONS_HISTORY_FAILURE,
	SET_TRANSACTIONS_HISTORY_DATA,
	GET_USER_REQUEST_START,
	GET_USER_REQUEST_SUCCESS,
	GET_USER_REQUEST_FAILURE,
	UPDATE_USER_REQUEST_START,
	UPDATE_USER_REQUEST_SUCCESS,
	UPDATE_USER_REQUEST_FAILURE,
	GET_UPI_LIST_FAILURE,
	GET_UPI_LIST_SUCCESS,
	GET_UPI_LIST_START,
	CREATE_UPI_ID_START,
	CREATE_UPI_LIST_SUCCESS,
	CREATE_UPI_LIST_FAILURE,
	DELETE_UPI_LIST_START,
	DELETE_UPI_LIST_SUCCESS,
	DELETE_UPI_LIST_FAILURE,
	UPDATE_UPI_LIST_START,
	UPDATE_UPI_LIST_SUCCESS,
	UPDATE_UPI_LIST_FAILURE,
	UPDATE_NEWS_LIST_START,
	UPDATE_NEWS_LIST_SUCCESS,
	UPDATE_NEWS_LIST_FAILURE,
	UPDATE_MESSAGE_LIST_START,
	UPDATE_MESSAGE_LIST_SUCCESS,
	UPDATE_MESSAGE_LIST_FAILURE,
	GET_NEWS_LIST_FAILURE,
	GET_NEWS_LIST_SUCCESS,
	GET_NEWS_LIST_START,
	GET_MESSAGE_LIST_FAILURE,
	GET_MESSAGE_LIST_SUCCESS,
	GET_MESSAGE_LIST_START,
	GET_COMMISSION_LIST_FAILURE,
	GET_COMMISSION_LIST_SUCCESS,
	GET_COMMISSION_LIST_START,
	UPDATE_COMMISSION_LIST_FAILURE,
	UPDATE_COMMISSION_LIST_SUCCESS,
	UPDATE_COMMISSION_LIST_START,
	CREATE_NEWS_LIST_FAILURE,
	CREATE_NEWS_LIST_SUCCESS,
	CREATE_NEWS_LIST_START,
	CREATE_MESSAGES_LIST_FAILURE,
	CREATE_MESSAGES_LIST_SUCCESS,
	CREATE_MESSAGES_LIST_START,
} from "../actionConstants";

import dispatchActionToReducer, {
	SimpleDispatchActionToReducer,
} from "../actionDispatcher";

import {
	getTransactionHistory as getTransactionHistoryService,
	getUserRequest as getUserRequestService,
	updateUserRequest as updateUserRequestService,
	updateUPIDataRequest as updateUPIDataRequestService,
	getUpiListData as getUpiListDataService,
	getNewsListData as getNewsListDataService,
	createNewsFirst as createNewsFirstServcice,
	createMessagesFirst as createMessagesFirstServcice,
	getCommissionList as getCommissionListService,
	updateCommissionList as updateCommissionListService,
	getMessagesListData as getMessagesListDataService,
	createUpiId as createUpiIdService,
	deleteUpiIdData as deleteUpiIdDataService,
	updateMessageService as updateMessageServiceService,
	updateNewsService as updateNewsServiceService,
} from "../services";

export const setTransactionHistory = (data, callback) => {
	return SimpleDispatchActionToReducer(SET_TRANSACTIONS_HISTORY_DATA, data, callback);
};

export const getUpiDataList = (query, callback) => {
	return dispatchActionToReducer(
		getUpiListDataService(query),
		GET_UPI_LIST_FAILURE,
		GET_UPI_LIST_SUCCESS,
		GET_UPI_LIST_START,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const getNewsDataListAction = (query, callback) => {
	return dispatchActionToReducer(
		getNewsListDataService(query),
		GET_NEWS_LIST_FAILURE,
		GET_NEWS_LIST_SUCCESS,
		GET_NEWS_LIST_START,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const createNewsFirstAction = (query, callback) => {
	return dispatchActionToReducer(
		createNewsFirstServcice(query),
		CREATE_NEWS_LIST_FAILURE,
		CREATE_NEWS_LIST_SUCCESS,
		CREATE_NEWS_LIST_START,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const createMessagesFirstAction = (query, callback) => {
	return dispatchActionToReducer(
		createMessagesFirstServcice(query),
		CREATE_MESSAGES_LIST_FAILURE,
		CREATE_MESSAGES_LIST_SUCCESS,
		CREATE_MESSAGES_LIST_START,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const getCommissionListAction = (query, callback) => {
	return dispatchActionToReducer(
		getCommissionListService(query),
		GET_COMMISSION_LIST_FAILURE,
		GET_COMMISSION_LIST_SUCCESS,
		GET_COMMISSION_LIST_START,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const updateCommissionListAction = (query, callback) => {
	return dispatchActionToReducer(
		updateCommissionListService(query),
		UPDATE_COMMISSION_LIST_FAILURE,
		UPDATE_COMMISSION_LIST_SUCCESS,
		UPDATE_COMMISSION_LIST_START,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};

export const getMessagesDataListAction = (query, callback) => {
	return dispatchActionToReducer(
		getMessagesListDataService(query),
		GET_MESSAGE_LIST_FAILURE,
		GET_MESSAGE_LIST_SUCCESS,
		GET_MESSAGE_LIST_START,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};

export const deleteUpiData = (reqData, callback) => {
	return dispatchActionToReducer(
		deleteUpiIdDataService(reqData),
		DELETE_UPI_LIST_START,
		DELETE_UPI_LIST_SUCCESS,
		DELETE_UPI_LIST_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const updateUPIDataRequest = (reqData, callback) => {
	return dispatchActionToReducer(
		updateUPIDataRequestService(reqData),
		UPDATE_UPI_LIST_START,
		UPDATE_UPI_LIST_SUCCESS,
		UPDATE_UPI_LIST_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};

export const createUpidActions = (reqData, callback) => {
	return dispatchActionToReducer(
		createUpiIdService(reqData),
		CREATE_UPI_ID_START,
		CREATE_UPI_LIST_SUCCESS,
		CREATE_UPI_LIST_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const updateMessageAction = (reqData, callback) => {
	return dispatchActionToReducer(
		updateMessageServiceService(reqData),
		UPDATE_MESSAGE_LIST_START,
		UPDATE_MESSAGE_LIST_SUCCESS,
		UPDATE_MESSAGE_LIST_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
export const updateNewsAction = (reqData, callback) => {
	return dispatchActionToReducer(
		updateNewsServiceService(reqData),
		UPDATE_NEWS_LIST_START,
		UPDATE_NEWS_LIST_SUCCESS,
		UPDATE_NEWS_LIST_FAILURE,
		(error, res) => {
			if (error) return;
			else if (res !== undefined) {
				if (callback) callback(null, res);
			} else {
				console.log("undefined response");
			}
		},
	);
};
