/** @format */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
// reactstrap components
import {
	Card,
	CardHeader,
	Container,
	Row,
	Button,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import {
	addGame,
	getGames,
	deleteGamesById,
	updateAddedGameAction,
} from "../../redux/actions";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	TablePagination,
} from "@material-ui/core";
import { Tab } from "bootstrap";
import { toastr } from "react-redux-toastr";

const Game = (props) => {
	const { gamesList } = props.games;
	const dispatch = useDispatch();
	const [gameData, setGameData] = useState([...gamesList]);
	const [state, setState] = useState({
		game_name: "",
		game_text_color: "",
		start_date: Date.now(),
		end_date: Date.now(),
		result_time: Date.now(),
		next_date_status: "0",
		win_rate: 90,
		commission: 0,
		color: "",
		submitted: false,
	});
	const [page, setPage] = React.useState(0);
	const [isUpdate, setIsUpdate] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	const [filter, setFilter] = useState({
		filterS: {
			status: "",
		},
	});

	const [searchText, setSearchText] = useState("");

	useEffect(() => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getGames({}, (errors, res) => {
				setGameData(res?.data?.response);
				// console.log(res.data?.response);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	}, []);

	const getSearchGame = (e) => {
		e.preventDefault();
		setSearchText(e.target.value);
		if (e.target.value) {
			let games = gameData?.filter((game) => {
				let search = e.target.value.toLowerCase();
				return (
					game.game_name &&
					JSON.stringify(game.game_name).toLowerCase().includes(search)
				);
			});
			setGameData([...games]);
		} else {
			setGameData([...gamesList]);
		}
	};

	const handleFilterChange = (e) => {
		e.preventDefault();
		const { id, value } = e.target;
		setFilter((prevState) => ({
			...prevState,
			filterS: {
				...filter.filterS,
				[id]: value,
			},
		}));
		// console.log(filter.filterS, "STATUSSS");
	};

	const getGameData = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getGames(query, (errors, res) => {
				console.log(res);
				setGameData(res?.data?.response);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};

	const handleChange = (e) => {
		const { id, value } = e.target;
		if (id === id) {
			setState((prevState) => ({
				...prevState,
				[id]: value,
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				[id]: value,
			}));
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setState((prevState) => ({
			...prevState,
			submitted: false,
		}));
		let reqData = {
			game_name: state.game_name,
			start_date: state.start_date,
			end_date: state.end_date,
			result_time: state.result_time,
			next_date_status: state.next_date_status,
			color: state.color,
			game_text_color: state.game_text_color,
			win_rate: state.win_rate,
			commission: state.commission,
		};
		dispatch({ type: "LOADING_START" });
		if (reqData) {
			if (isUpdate) {
				reqData._id = state?._id;
				dispatch(
					updateAddedGameAction(reqData, (errors, res) => {
						if (res && res.status == 200) {
							// console.log(res);
							dispatch({ type: "LOADING_SUCCESS" });
							setIsUpdate(false);
							toastr.success("Game updated successfully");
							getGameData();
							setState({
								game_name: "",
								start_date: Date.now(),
								end_date: Date.now(),
								result_time: Date.now(),
								next_date_status: "0",
								submitted: false,
							});
						}
					}),
				);
			} else {
				dispatch(
					addGame(reqData, (errors, res) => {
						if (res && res.status == 200) {
							// console.log(res);
							dispatch({ type: "LOADING_SUCCESS" });
							toastr.success("Game added successfully");
							getGameData();
							setState((prevState) => ({
								...prevState,
								submitted: true,
							}));
						}
					}),
				);
			}
		}
	};

	const updateGameByID = (state) => {
		// e.preventDefault();
		setState((prevState) => ({
			...prevState,
			submitted: false,
		}));
		let reqData = {
			// ...state,
			game_name: state.game_name,
			// start_date: state.start_date,
			// end_date: state.end_date,
			// next_date_status: state.next_date_status,
			_id: state._id,
			status: state?.status === "active" ? "inactive" : "active",
			// next_date_status: state?.next_date_status,
		};
		dispatch({ type: "LOADING_START" });
		if (reqData) {
			dispatch(
				updateAddedGameAction(reqData, (errors, res) => {
					if (res && res.status == 200) {
						// console.log(res);
						dispatch({ type: "LOADING_SUCCESS" });
						toastr.success("Game updated successfully");
						getGameData();
						setState((prevState) => ({
							...prevState,
							submitted: true,
						}));
					}
				}),
			);
		}
	};
	const updateGameByOpenAndCloseWithId = (state, stats) => {
		// e.preventDefault();
		setState((prevState) => ({
			...prevState,
			submitted: false,
		}));
		let reqData = {
			// ...state,
			game_name: state.game_name,
			is_active: stats === "close" ? 0 : 1,
			// start_date: state.start_date,
			// end_date: state.end_date,
			// next_date_status: state.next_date_status,
			_id: state._id,
			status: stats === "close" ? "inactive" : "active",
			// next_date_status: state?.next_date_status,
		};
		dispatch({ type: "LOADING_START" });
		if (reqData) {
			dispatch(
				updateAddedGameAction(reqData, (errors, res) => {
					if (res && res.status == 200) {
						// console.log(res);
						dispatch({ type: "LOADING_SUCCESS" });
						toastr.success("Game updated successfully");
						getGameData();
						setState((prevState) => ({
							...prevState,
							submitted: true,
						}));
					}
				}),
			);
		}
	};

	const handleDeleteGame = (e, id) => {
		// removeGame
		// console.log(e);
		// deleteGamesById
		let query = { _id: id } || {};
		dispatch({ type: "LOADING_START" });
		dispatch(
			deleteGamesById(query, (errors, res) => {
				console.log(errors);
				if (errors) {
					dispatch({ type: "LOADING_SUCCESS" });
				}
				setGameData(res?.data);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);

		dispatch({ type: "LOADING_SUCCESS" });
	};

	const handleEditGame = (e, data) => {
		setIsUpdate(true);
		setState({
			...data,
		});
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - gameData?.length) : 0;

	return (
		<>
			{/* <Header /> */}
			{/* Page content */}
			<Container className='mt-7' fluid>
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-default shadow'>
							<CardHeader className='bg-transparent border-0 mt-7'>
								<h3 className='text-white mb-0 text-center'>Add Game</h3>
							</CardHeader>
							<CardBody>
								<Form role='form'>
									<FormGroup className='mb-3'>
										<label>Game Name </label>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-ui-04' />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												type='text'
												autoComplete='new-name'
												value={state.game_name}
												onChange={handleChange}
												className='form-control'
												id='game_name'
												placeholder='Enter Game Name'
												name='game_name'
												required
											/>
										</InputGroup>
										{state.submitted && !state.game_name && (
											<div className='error'>
												Game name is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<label>Start Time</label>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-calendar-grid-58' />
												</InputGroupText>
											</InputGroupAddon>
											<input
												type='datetime-local'
												autoComplete='new-sdate'
												className='form-control'
												id='start_date'
												placeholder='Enter Start Date'
												name='password'
												value={state.start_date}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.start_date && (
											<div className='error'>
												Start Date is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<label>End Time</label>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-calendar-grid-58' />
												</InputGroupText>
											</InputGroupAddon>
											<input
												type='datetime-local'
												autoComplete='new-edate'
												className='form-control'
												id='end_date'
												placeholder='Enter End Date'
												name='end_date'
												value={state.end_date}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.end_date && (
											<div className='error'>
												End Date is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<label>Result Time</label>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-calendar-grid-58' />
												</InputGroupText>
											</InputGroupAddon>
											<input
												type='datetime-local'
												autoComplete='new-edate'
												className='form-control'
												id='result_time'
												placeholder='Enter End Date'
												name='result_time'
												value={state.result_time}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.result_time && (
											<div className='error'>
												End Date is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<label>Win Rate Per Rupess</label>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-calendar-grid-58' />
												</InputGroupText>
											</InputGroupAddon>
											<input
												type='text'
												className='form-control'
												id='win_rate'
												placeholder='Enter Wining Price'
												name='win_rate'
												value={state.win_rate}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.win_rate && (
											<div className='error'>
												Win rate is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<label>Commission Percentage</label>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-calendar-grid-58' />
												</InputGroupText>
											</InputGroupAddon>
											<input
												type='text'
												className='form-control'
												id='commission'
												placeholder='Enter Commission Percentage'
												name='commission'
												value={state.commission}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{state.submitted && !state.commission && (
											<div className='error'>
												Commission is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<label>
											Game Color {"Hex"}
											<span
												style={{
													color: state.color,
												}}>
												#FF00FF
											</span>
										</label>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-calendar-grid-58' />
												</InputGroupText>
											</InputGroupAddon>
											<input
												type='text'
												className='form-control'
												id='color'
												placeholder='Enter Color in Hex format'
												name='color'
												value={state.color}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{/* {state.submitted && !state.color && (
											<div className='error'>Color is required</div>
										)} */}
									</FormGroup>
									<FormGroup>
										<label>
											{" "}
											Text Color{" "}
											<span
												style={{
													color: state.game_text_color,
												}}>
												#FF00FF
											</span>
										</label>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-calendar-grid-58' />
												</InputGroupText>
											</InputGroupAddon>
											<input
												type='text'
												className='form-control'
												id='game_text_color'
												placeholder='Enter Color in Hex format'
												name='game_text_color'
												value={state.game_text_color}
												onChange={handleChange}
												required
											/>
										</InputGroup>
										{/* {state.submitted && !state.color && (
											<div className='error'>Color is required</div>
										)} */}
									</FormGroup>
									<FormGroup className='mb-3'>
										<label>
											Game Stop Time Next Day (YES) for Same Day
											(NO)
										</label>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-ui-04' />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												type='select'
												autoComplete='new-name'
												value={state.next_date_status}
												onChange={handleChange}
												className='form-control'
												id='next_date_status'
												placeholder='Select Payment Mode'
												name='next_date_status'
												required>
												<option value={""}>
													Select Next Day
												</option>
												<option value={"1"}>YES</option>
												<option value={"0"}>NO</option>
											</Input>
										</InputGroup>
										{state.submitted && !state.next_date_status && (
											<div className='error'>
												Next Date is required
											</div>
										)}
									</FormGroup>
									<div className='text-center'>
										<Button
											disabled={
												!(
													state.game_name &&
													state.start_date &&
													state.end_date &&
													!isUpdate
												)
											}
											onClick={handleSubmit}
											className='my-4'
											color='primary'
											type='button'>
											Add Game
										</Button>

										<Button
											disabled={
												!(
													state.game_name &&
													state.start_date &&
													state.end_date &&
													isUpdate
												)
											}
											onClick={handleSubmit}
											className='my-4 bg-success'
											color='primary'
											type='button'>
											Update Game
										</Button>
									</div>
								</Form>
							</CardBody>
						</Card>
					</div>
				</Row>

				{/* Dark table */}
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-default shadow'>
							<CardHeader className='bg-transparent border-0'>
								<h3 className='text-white mb-0'>Games List</h3>
								<div
									className='d-flex mt-2'
									style={{ flexDirection: "column" }}>
									<InputGroup size='xl' className='ml-2 mt-2 w-100'>
										<Input
											type='text'
											name=''
											value={searchText || ""}
											onChange={getSearchGame}
											placeholder='Search.....'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											type='select'
											value={filter.filterS.status}
											onChange={handleFilterChange}
											id='status'
											name='status'
											required>
											<option key='select' value=''>
												Select Status
											</option>
											<option key='active' value='active'>
												Active
											</option>
											<option key='inactive' value='inactive'>
												Inactive
											</option>
										</Input>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											type='Button'
											onClick={() =>
												getGameData({ ...filter.filterS })
											}
											className='bg-default text-white'
											value={"Search"}></Input>
									</InputGroup>
								</div>
							</CardHeader>
							<TableContainer>
								<Table
									className='align-items-center table-dark table-flush'
									responsive>
									<TableHead>
										<TableRow>
											<TableCell className='text-white'>
												Game Name
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Start Date
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												End Date
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Result Time
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Game
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Text
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Win Rate
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Commission
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												{" "}
												Next Day
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												{" "}
												Status
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												{" "}
												Acion
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{gameData && gameData?.length
											? gameData
													.slice(
														page * rowsPerPage,
														page * rowsPerPage + rowsPerPage,
													)
													.map((list, index) => {
														return (
															<TableRow key={index + 1}>
																<TableCell className='text-white'>
																	{list.game_name}
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	{moment(
																		list.start_date,
																	).format(
																		"DD-MM-YY, h:mm a",
																	)}
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	{moment(
																		list.end_date,
																	).format(
																		"DD-MM-YY, h:mm a",
																	)}
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	{moment(
																		list.result_time,
																	).format(
																		"DD-MM-YY, h:mm a",
																	)}
																</TableCell>
																<TableCell
																	className=''
																	style={{
																		color: list.color,
																	}}
																	align='center'>
																	{list.color}
																</TableCell>
																<TableCell
																	className=''
																	style={{
																		color: list.game_text_color,
																	}}
																	align='center'>
																	{list.game_text_color}
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	{list.win_rate}
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	{list.commission}
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	<Button
																		className={
																			list.next_date_status ===
																			"1"
																				? "bg-success text-white"
																				: "bg-danger text-white"
																		}>
																		{list?.next_date_status ===
																		"1"
																			? "Y"
																			: "N"}
																	</Button>
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	<Button
																		className={
																			list.status ===
																			"active"
																				? "bg-success text-white"
																				: "bg-danger text-white"
																		}>
																		{list?.status
																			? (list?.status).toUpperCase()
																			: "-"}
																	</Button>
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	<Button
																		onClick={() =>
																			updateGameByID(
																				list,
																			)
																		}>
																		STA
																	</Button>
																	<Button
																		className='bg-danger'
																		onClick={(e) =>
																			handleDeleteGame(
																				e,
																				list._id,
																			)
																		}>
																		DEL
																	</Button>
																	{list?.status ===
																	"inactive" ? (
																		<Button
																			className='bg-success'
																			onClick={(
																				e,
																			) =>
																				updateGameByOpenAndCloseWithId(
																					list,
																					"open",
																				)
																			}>
																			OPEN
																		</Button>
																	) : (
																		<Button
																			className='bg-danger'
																			onClick={(
																				e,
																			) =>
																				updateGameByOpenAndCloseWithId(
																					list,
																					"close",
																				)
																			}>
																			CLOSE
																		</Button>
																	)}
																	<Button
																		className='bg-gray'
																		onClick={(e) =>
																			handleEditGame(
																				e,
																				list,
																			)
																		}>
																		EDIT
																	</Button>
																</TableCell>
															</TableRow>
														);
													})
											: ""}
										{emptyRows > 0 && (
											<TableRow
												style={{
													height: 53 * emptyRows,
												}}>
												<TableCell colSpan={6} />
											</TableRow>
										)}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TablePagination
												className='text-white'
												rowsPerPageOptions={[
													5,
													10,
													25,
													{ label: "All", value: -1 },
												]}
												colSpan={5}
												count={gameData?.length}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onRowsPerPageChange={
													handleChangeRowsPerPage
												}
											/>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
							{/* <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Game Name</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col" >Status </th>
                  </tr>
                </thead>
                <tbody>
                  {gameData && gameData.length ?
                    gameData.map((list, index) => {
                      return (
                        <tr>
                          <td>{list.game_name}</td>
                          <td>{moment(list.start_date).format('DD-MM-YY, h:mm a')}</td>
                          <td>{moment(list.end_date).format('DD-MM-YY, h:mm a')}</td>
                          <td>{list?.status ? (list?.status).toUpperCase() : '-'}</td>
                        </tr>
                      )
                    }) : ''
                  }
                </tbody>
              </Table> */}
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

function mapStateToProps(state) {
	return {
		user: state.session.user,
		games: state.games,
	};
}

export default withRouter(connect(mapStateToProps, {})(Game));
