/** @format */

import React, { useState, useEffect } from "react";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import {
	getWallets,
	getUserRequest,
	updateUserRequest,
	addMoneyToWallet,
	getGames,
} from "../../redux/actions";
import { toastr } from "react-redux-toastr";

// reactstrap components
import {
	Card,
	CardHeader,
	Container,
	Row,
	Button,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { getTransactionHistory } from "../../redux/actions";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	TablePagination,
} from "@material-ui/core";
import SimpleModal from "./Modal";

const PaymentRequest = (props) => {
	// console.log(props, "props");
	const { walletsList } = props.wallets;
	let walletData = walletsList.filter((wallet) => {
		return wallet?.phone_number;
	});
	const filterOptions = createFilterOptions({
		ignoreCase: false,
		ignoreAccents: false,
	});
	const { transactionHistory, userRequests, transactions, totalCount } = props.transactionHistory;
	// console.log(userRequests, "transactionHistory");
	const dispatch = useDispatch();
	const [userData, setUserData] = useState(userRequests);
	const [modalData, setModalData] = useState([]);
	const [games, setGames] = useState([]);
	const [state, setState] = useState({
		wallet_id: "",
		transaction_mode: "win",
		transaction_status: "pending",
		transaction_type: "credit",
		amount: null,
		password: null,
		submitted: false,
	});

	let currentDate = moment().format("YYYY-MM-DD");
	let prevDay = moment().subtract(1, "day").format("YYYY-MM-DD");
	let nextDay = moment().add(1, "day").format("YYYY-MM-DD");
	let startTime = moment(currentDate + "T06:00").format("HH:mm:ss");
	let endTime = moment(currentDate + "T05:30").format("HH:mm:ss");

	const [filter, setFilter] = useState({
		filterS: {
			transaction_mode: "win",
			transaction_status: "pending",
			transaction_type: "credit",
			phone_number: "",
			amount: "",
			// createdAt: "",
			sortField: "_id",
			pageNumber: page || 1,
			pageSize: rowsPerPage || 10,
			sortOrder: -1,
			// createdDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
			startDate: moment(prevDay + " " + startTime).format("YYYY-MM-DD HH:mm:ss"),
			endDate: moment(nextDay + " " + endTime).format("YYYY-MM-DD HH:mm:ss"),
		},
	});
	const [page, setPage] = React.useState(0);
	const [open, setOpen] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {

		// getUserData({ transaction_type: "credit", transaction_status: "" });
		getUserData({ ...filter.filterS });
		// dispatch(
		// 	getWallets({}, (errors, res) => {
		// 		dispatch({ type: "LOADING_SUCCESS" });
		// 	}),
		// );
		getAllGames();
		if (moment().format("HH") >= 6) {
			setFilter({
				filterS: {
					...filter?.filterS,
					startDate: moment(currentDate + " " + startTime).format(
						"YYYY-MM-DD HH:mm:ss",
					),
					endDate: moment(nextDay + " " + endTime).format(
						"YYYY-MM-DD HH:mm:ss",
					),
				},
			});
		} else {
			setFilter({
				filterS: {
					...filter?.filterS,
					startDate: moment(prevDay + " " + startTime).format(
						"YYYY-MM-DD HH:mm:ss",
					),
					endDate: moment(currentDate + " " + endTime).format(
						"YYYY-MM-DD HH:mm:ss",
					),
				},
			});
		}
	}, []);
	// const handleChange = (e) => {
	// 	const { id, value, name } = e.target;
	// 	if (name) {
	// 		setState((prevState) => ({
	// 			...prevState,
	// 			[name]: value,
	// 		}));
	// 	} else {
	// 		setState((prevState) => ({
	// 			...prevState,
	// 			[id]: value,
	// 		}));
	// 	}
	// };
	const handleSubmit = (e) => {
		e.preventDefault();
		setState((prevState) => ({
			...prevState,
			submitted: true,
		}));
		let reqData = {
			wallet_id: state.wallet_id,
			amount: state.amount,
			transaction_status: state.transaction_status,
			transaction_type: state.transaction_type,
			transaction_mode: state.transaction_mode,
		};
		dispatch(
			addMoneyToWallet(reqData, (errors, res) => {
				// console.log(res);
				if (res.data) {
					onUpdate(res?.data, "approved");
				}
				toastr.success(
					"Success",
					"Money Requested Successfully Please check and approve",
				);
				// dispatch(
				// 	getUserRequest({}, (errors, res) => {
				// 		dispatch({ type: "LOADING_SUCCESS" });
				// 	}),
				// );
				getUserData({
					transaction_type: "credit",
					transaction_status: "",
				});
			}),
		);
	};
	const handleSubmitWithdrawMoney = (e) => {
		e.preventDefault();
		setState((prevState) => ({
			...prevState,
			submitted: true,
		}));
		let reqData = {
			wallet_id: state.wallet_id,
			amount: state.amount,
			transaction_status: state.transaction_status,
			transaction_type: "debit",
			transaction_mode: "withdraw",
		};
		dispatch(
			addMoneyToWallet(reqData, (errors, res) => {

				if (res.data) {
					onUpdate(res?.data, "approved");
				}
				// toastr.success(
				// 	"Success",
				// 	"Money Requested Successfully Please check and approve",
				// );

				// dispatch(
				// 	getUserRequest({}, (errors, res) => {
				// 		dispatch({ type: "LOADING_SUCCESS" });
				// 	}),
				// );
				getUserData({
					transaction_type: "credit",
					transaction_status: "",
				});
			}),
		);
	};
	// const handleFilterChange = (e) => {
	//   e.preventDefault();
	//   var filterS = {
	//     status: e.currentTarget.getAttribute("dropdownvalue")
	//   }
	//   setFilter(prevState => ({
	//     ...prevState,
	//     filterS: { ...filterS }
	//   }));
	//   getUserData({
	//     transaction_type: e.currentTarget.getAttribute("dropdownvalue")
	//   })
	// };
	const onUpdate = (transaction, status) => {
		let updatData = {
			transaction_id: transaction._id,
			transaction_type: transaction.transaction_type,
			// transaction_status_admin: status,
			transaction_status: status,
			role: "admin",
		};
		dispatch(
			updateUserRequest(updatData, (err, res) => {
				if (res.data.message) {
					toastr.success("error", res.data.message);
				} else {
					toastr.success("Success", "Request Updated Successfully", 400);
				}
				getUserData({
					...filter.filterS,
					pageNumber: page || 1,
					pageSize: rowsPerPage || 10
				});
			}),
		);
	};



	const getUserData = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getUserRequest(query, (errors, res) => {
				// console.log(res)
				// let dat = res?.data?.filter((item) => {
				// 	if (item?.transaction_mode === "win") {
				// 		return item;
				// 	}
				// });
				if (res.data) {
					setUserData({ requestList: res.data.response, totalCount: res.data.totalCount })

				}
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};

	useEffect(() => {
		getUserData({
			...filter.filterS,
			pageNumber: page || 1,
			pageSize: rowsPerPage || 10
		});
	}, [page, rowsPerPage]);

	useEffect(() => {
		setUserData({ requestList: userRequests, totalCount: totalCount })
	}, [transactions]);

	const status = {
		debit: "Debit",
		credit: "Credit",
	};
	// const handleFilterChange = (e) => {
	// 	e.preventDefault();
	// 	const { id, value } = e.target;
	// 	// console.log(id, value);
	// 	// if (id == "phone_number ") {
	// 	// 	const val = userData.requestList?.filter((i) => i.wallet_id.phone_number == value && i.wallet_id._id)[0]
	// 	// 	console.log(val)
	// 	// 	setFilter((prevState) => ({
	// 	// 		...prevState,
	// 	// 		filterS: {
	// 	// 			...filter.filterS,
	// 	// 			"wallet_id": val,
	// 	// 		},
	// 	// 	}));
	// 	// } else {
	// 	setFilter((prevState) => ({
	// 		...prevState,
	// 		filterS: {
	// 			...filter.filterS,
	// 			[id]: value,
	// 		},
	// 	}));
	// 	// }
	// };
	const getAllGames = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getGames(query, (errors, res) => {
				// console.log("getGames=>", res);
				setGames(res?.data?.response);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};

	const handleFilterChange = (e) => {
		const { id, value } = e.target;
		// let
		// console.log(id, value);
		if (id === "game_id" && value !== "") {
			// console.log("game", value);
			let findGame = games.find((game) => game._id === value);
			// console.log("findGame", findGame);
			let next = findGame.next_date_status === "1" ? true : false;

			let startTime = moment(findGame.start_date).format("HH:mm:ss");
			let endTime = moment(findGame.end_date).format("HH:mm:ss");
			let resultTime = moment(findGame.result_time).format("HH:mm:ss");
			// console.log("start", startTime);
			// console.log("end", endTime);
			let currentDate = moment().format("YYYY-MM-DD");
			// console.log("currentDate", currentDate);
			let yesterDay = moment().subtract(1, "day").format("YYYY-MM-DD");
			// console.log("yesterDay", yesterDay);
			let dates = next ? yesterDay : currentDate;

			let startDate = moment(dates + " " + startTime).format("YYYY-MM-DD HH:mm:ss");

			let endDate = moment(currentDate + " " + endTime).format(
				"YYYY-MM-DD HH:mm:ss",
			);
			let resultDate = moment(currentDate + " " + resultTime).format(
				"YYYY-MM-DD HH:mm:ss",
			);

			console.log("startDate", startDate);
			let result_time = resultDate || moment().format("YYYY-MM-DD HH:mm:ss");
			// setState((prevState) => ({
			// 	...prevState,
			// 	[id]: value,
			// 	startDate: startDate,
			// 	endDate: endDate,
			// 	result_time: result_time,
			// }));

			setFilter((prevState) => ({
				...prevState,
				filterS: {
					...filter.filterS,
					[id]: value,
					startDate: startDate,
					endDate: endDate,
					// result_time: result_time,
				},
			}));
		} else {
			// console.log(new Date(moment(value).format("YYYY,MM,DD")));
			// setState((prevState) => ({
			// 	...prevState,
			// 	[id]: value,
			// }));
			setFilter((prevState) => ({
				...prevState,
				filterS: {
					...filter.filterS,
					[id]: value,
				},
			}));
		}
		// console.log(id, value);
	};

	const getuserNamefromWalletId = (list) => {
		let data =
			walletsList?.length > 0 &&
			walletsList?.filter((item) => item?._id === list?.wallet_id?._id);

		var username = "";
		username =
			data?.length > 0 &&
			data[0]?.user_id?.first_name + " " + data[0].user_id?.last_name;
		return username || "NO FOUND";
	};

	const history = useHistory();

	const handleNavidateToSummary = (e, user_id) => {
		// console.log("list", list);
		history.push(`/admin/summary/${user_id}`);
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData.length) : 0;

	const handleShowModal = (e, data) => {
		setOpen(true);
		let mdt = [];
		if (data.userBetsTimeAndType) {
			mdt.push(data.userBetsTimeAndType);
		}
		if (data.userInSideBetsTimeAndType) {
			mdt.push(data.userInSideBetsTimeAndType);
		}
		if (data.userOutSideBetsTimeAndType) {
			mdt.push(data.userOutSideBetsTimeAndType);
		}
		setModalData([...mdt]);
	};

	return (
		<>
			{/* <Header /> */}
			{/* Page content */}
			<Container className='mt-7' fluid>
				{/* Dark table */}

				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-default shadow'>
							<CardHeader className='bg-transparent border-0'>
								<h3 className='text-white mb-0'>All Winners</h3>
								<div
									className='d-flex mt-2'
									style={{ flexDirection: "column" }}>
									<InputGroup size='xl' className='w-100 mt-2 ml-2'>
										<Input
											type='select'
											autoComplete='new-name'
											value={filter.filterS.game_id}
											onChange={handleFilterChange}
											id='game_id'
											placeholder='Select Game'
											name='game_id'
											required>
											<option key={"select"} value={""}>
												Select Game
											</option>
											{games && games.length
												? games.map((list, index) => {
													return (
														<option
															key={index + 1}
															value={list._id}>
															{list?.game_name}
														</option>
													);
												})
												: ""}
										</Input>
									</InputGroup>
									<FormGroup>
										<label>Start Time</label>
										<InputGroup size='xl' className='w-100 mt-2'>
											<InputGroupAddon
												addonType='prepend'
												className='d-inline'>
												<Button className='bg-default shadow'>
													<i className='ni ni-calendar-grid-58 text-white' />
												</Button>
											</InputGroupAddon>
											<Input
												type='datetime-local'
												autoComplete='new-sdate'
												id='startDate'
												placeholder='Start Date'
												name='startDate'
												value={filter.filterS.startDate}
												onChange={handleFilterChange}
												required
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<label>End Time</label>
										<InputGroup size='xl' className='w-100 mt-2'>
											<InputGroupAddon
												addonType='prepend'
												className='d-inline'>
												<Button className='bg-default shadow'>
													<i className='ni ni-calendar-grid-58 text-white' />
												</Button>
											</InputGroupAddon>
											<Input
												type='datetime-local'
												autoComplete='new-sdate'
												id='endDate'
												placeholder='Start Date'
												name='endDate'
												// disabled={true}
												value={filter.filterS.endDate}
												onChange={handleFilterChange}
												required
											/>
										</InputGroup>
									</FormGroup>
									<InputGroup size='xl' className='ml-2 w-100 mt-2'>
										<Input
											id='phone_number'
											type='text'
											name='phone_number'
											value={filter.filterS.phone_number || ""}
											onChange={handleFilterChange}
											placeholder='Search Phone Number'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											id='amount'
											type='number'
											name='amount'
											value={filter.filterS.amount || ""}
											onChange={handleFilterChange}
											placeholder='Search Amount'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									{/* <InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											id='createdAt'
											type='date'
											name='createdAt'
											value={filter.filterS.createdAt || ""}
											onChange={handleFilterChange}
											placeholder='Search for Date'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup> */}
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='select'
											autoComplete='new-name'
											value={filter.filterS.transaction_status}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_status'
											placeholder='Select Transaction Status'
											name='transaction_status'
											required>
											<option key='' value=''>
												Select Status
											</option>
											<option key='pending' value='pending'>
												pending
											</option>
											<option key='approved' value='approved'>
												approved
											</option>
											<option key='rejected' value='rejected'>
												rejected
											</option>
										</Input>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='Button'
											onClick={() =>
												getUserData({
													...filter.filterS,
													pageNumber: page || 1,
													pageSize: rowsPerPage || 10,

												})
											}
											className='bg-default text-white'
											value={"Search"}></Input>
									</InputGroup>
								</div>
							</CardHeader>
							<TableContainer>
								<Table
									className='align-items-center table-dark table-flush'
									responsive>
									<TableHead>
										<TableRow>
											<TableCell scope='row' align='center'>
												<span className='mb-0 text-sm text-white'>
													Name
												</span>
											</TableCell>
											<TableCell className='text-white'>
												Phone
											</TableCell>

											<TableCell
												className='text-white'
												align='center'>
												Type
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Win Amount
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Mode
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Wallet
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Date
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Status
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Game
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Action
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{userData && userData.requestList && userData.requestList.length
											? userData.requestList
												// .slice(
												// 	page * rowsPerPage,
												// 	page * rowsPerPage + rowsPerPage,
												// )
												.map((list, index) => {
													return (
														<TableRow key={index + 1}>
															<TableCell
																scope='row'
																align='center'>
																<span className='mb-0 text-sm text-white'>
																	{walletsList?.length >
																		0 &&
																		getuserNamefromWalletId(
																			list,
																		)}
																</span>
															</TableCell>
															<TableCell scope='row'>
																<span className='mb-0 ml-2 text-sm text-white'>
																	{
																		list
																			?.wallet_id
																			?.phone_number
																	}
																</span>
															</TableCell>
															{/* <TableCell
																	className='text-white'
																	align='center'>
																	{
																		list?.user
																			?.user_name
																	}
																</TableCell> */}
															<TableCell
																className='text-white'
																align='center'>
																{
																	list?.transaction_mode
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{list?.amount}
															</TableCell>
															<TableCell
																className={`${list?.transaction_type ===
																	"debit"
																	? "text-danger"
																	: "text-success"
																	}`}
																align='center'>
																{
																	list?.transaction_type
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{
																	list?.wallet_id
																		?.total_amount.toFixed(2)
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{moment(
																	list?.createdAt,
																).format(
																	"DD-MM-YYYY, h:mm a",
																)}
															</TableCell>

															{list?.transaction_status ===
																"rejected" ? (
																<TableCell
																	className='text-danger'
																	align='center'>
																	{
																		list?.transaction_status
																	}
																</TableCell>
															) : list?.transaction_status ===
																"pending" ? (
																<TableCell
																	className='text-warning'
																	align='center'>
																	{
																		list?.transaction_status
																	}
																</TableCell>
															) : (
																<TableCell
																	className='text-success'
																	align='center'>
																	{
																		list?.transaction_status
																	}
																</TableCell>
															)}

															<TableCell
																className='text-white'
																align='center'>
																{list?.game_name}
															</TableCell>
															<TableCell>
																<React.Fragment>
																	{list.transaction_status ===
																		"pending" && (
																			<>
																				<button
																					className={
																						"btn btn-success"
																					}
																					onClick={() =>
																						onUpdate(
																							list,
																							"approved",
																						)
																					}>
																					Approve
																				</button>
																				<button
																					className={
																						"btn btn-danger"
																					}
																					style={{
																						marginLeft: 8,
																					}}
																					onClick={() =>
																						onUpdate(
																							list,
																							"rejected",
																						)
																					}>
																					Reject
																				</button>
																			</>
																		)}
																	{list.transaction_status ===
																		"approved" && (
																			<>
																				<button
																					className={
																						"btn btn-secondary"
																					}
																					style={{
																						marginLeft: 8,
																					}}
																					onClick={() =>
																						onUpdate(
																							list,
																							"rejected",
																						)
																					}>
																					Reject
																				</button>
																			</>
																		)}
																</React.Fragment>
																<Button
																	onClick={(e) =>
																		handleNavidateToSummary(
																			e,
																			list
																				?.wallet_id
																				?.user_id,
																		)
																	}
																	variant='contained'
																	color='secondary'>
																	SUMMARY
																</Button>
																<Button
																	onClick={(e) =>
																		handleShowModal(
																			e,
																			list,
																		)
																	}
																	variant='contained'
																	color='secondary'>
																	VIEW
																</Button>
																<SimpleModal
																	setOpen={setOpen}
																	setModalData={
																		setModalData
																	}
																	open={open}
																	data={modalData}
																/>
															</TableCell>
														</TableRow>
													);
												})
											: ""}
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td>
												Total :{" "}
												{userData &&
													userData?.requestList?.length &&
													userData?.requestList
														// ?.slice(
														// 	page * rowsPerPage,
														// 	page * rowsPerPage +
														// 	rowsPerPage,
														// )
														.reduce(
															(totalValue, item) =>
																totalValue +
																Number(item?.amount),
															0,
														)
														.toFixed(2)}
											</td>
										</tr>
										{/* {emptyRows > 0 && (
											<TableRow
												style={{
													height: 53 * emptyRows,
												}}>
												<TableCell colSpan={6} />
											</TableRow>
										)} */}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TablePagination
												className='text-white'
												rowsPerPageOptions={[
													5,
													10,
													25,
													50,
													100,
													// { label: "All", value: -1 },
												]}
												colSpan={5}
												count={totalCount}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onRowsPerPageChange={
													handleChangeRowsPerPage
												}
											/>
											{/* <TablePagination
												className='text-white'
												rowsPerPageOptions={[
													5,
													10,
													25,
													{ label: "All", value: -1 },
												]}
												colSpan={5}
												count={userData.length}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onRowsPerPageChange={
													handleChangeRowsPerPage
												}
											/> */}
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

function mapStateToProps(state) {
	return {
		user: state.session.user,
		wallets: state.wallets,
		transactionHistory: state.transactionHistory,
	};
}

export default withRouter(connect(mapStateToProps, {})(PaymentRequest));
