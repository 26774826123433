/** @format */

import AllBets from "views/examples/AllBets";
import CommissionRequests from "views/examples/CommissionRequests";
import Summary from "views/examples/Summary";
import Index from "views/Index.js";
import Dashboard from "views/Dashboard";
import {
	Users,
	Game,
	GameBets,
	GameResults,
	Result,
	Wallet,
	Profile,
	Maps,
	Register,
	Login,
	Tables,
	Icons,
	TransactionsHistory,
	PaymentRequest,
	WinningResult,
	PaymentRequestRecharge,
	WithdrawRequest,
	AddUpiId,
	UpdateNews,
	UpdateMessages,
	Commission,
} from "./views/examples";

var routes = [
	{
		path: "/index",
		name: "Dashboard",
		icon: "ni ni-tv-2 text-primary",
		component: Dashboard,
		layout: "/admin",
	},
	// {
	// 	path: "/index",
	// 	name: "Dashboard",
	// 	icon: "ni ni-tv-2 text-primary",
	// 	component: Index,
	// 	layout: "/admin",
	// },
	// {
	// 	path: "/dashboard",
	// 	name: "Analytics",
	// 	icon: "ni ni-tv-2 text-primary",
	// 	component: Dashboard,
	// 	layout: "/admin",
	// },
	{
		path: "/user-profile/:id",
		name: "Profile",
		icon: "ni ni-circle-08 text-red",
		component: Profile,
		layout: "/admin",
	},
	{
		path: "/users",
		name: "All Users",
		icon: "ni ni-circle-08 text-red",
		component: Users,
		layout: "/admin",
	},
	{
		path: "/games",
		name: "All Games",
		icon: "ni ni-planet text-info",
		component: Game,
		layout: "/admin",
	},
	{
		path: "/gameBets/:id",
		name: "Jantary",
		icon: "ni ni-planet text-green",
		component: GameBets,
		layout: "/admin",
	},
	{
		path: "/gameResults",
		name: "Update Result",
		icon: "ni ni-pin-3 text-indigo",
		component: GameResults,
		layout: "/admin",
	},
	{
		path: "/winners",
		name: "All Winners",
		icon: "ni ni-pin-3 text-indigo",
		component: Result,
		layout: "/admin",
	},
	{
		path: "/summary/:id",
		name: "Summary",
		icon: "ni ni-pin-3 text-indigo",
		component: Summary,
		layout: "/admin",
	},
	{
		path: "/winningResults",
		name: "Recent Winners",
		icon: "ni ni-key-25 text-black",
		component: WinningResult,
		layout: "/admin",
	},
	{
		path: "/wallet",
		name: "Wallet Details",
		icon: "ni ni-single-02 text-yellow",
		component: Wallet,
		layout: "/admin",
	},
	{
		path: "/withdrawRequest",
		name: "withdraw Request",
		icon: "ni ni-single-02 text-yellow",
		component: WithdrawRequest,
		layout: "/admin",
	},
	{
		path: "/paymentRequest",
		name: "Add Money",
		icon: "ni ni-bullet-list-67 text-orange",
		component: PaymentRequest,
		layout: "/admin",
	},
	{
		path: "/commissionRequest",
		name: "Commission Request",
		icon: "ni ni-bullet-list-67 text-orange",
		component: CommissionRequests,
		layout: "/admin",
	},
	{
		path: "/allbets",
		name: "All Bets",
		icon: "ni ni-bullet-list-67 text-orange",
		component: AllBets,
		layout: "/admin",
	},
	// {
	// 	path: "/recharge",
	// 	name: "Recharge",
	// 	icon: "ni ni-bullet-list-67 text-orange",
	// 	component: PaymentRequestRecharge,
	// 	layout: "/admin",
	// },
	{
		path: "/UPI",
		name: "Add UPI ID",
		icon: "ni ni-key-25 text-blue",
		component: AddUpiId,
		layout: "/admin",
	},
	{
		path: "/updateNews",
		name: "Update News",
		icon: "ni ni-key-25 text-blue",
		component: UpdateNews,
		layout: "/admin",
	},
	{
		path: "/updateMessage",
		name: "Update Message",
		icon: "ni ni-key-25 text-blue",
		component: UpdateMessages,
		layout: "/admin",
	},
	{
		path: "/transactionsHistory",
		name: "Transaction History",
		icon: "ni ni-key-25 text-blue",
		component: TransactionsHistory,
		layout: "/admin",
	},
	{
		path: "/commission",
		name: "Commission Request",
		icon: "ni ni-key-25 text-blue",
		component: Commission,
		layout: "/admin",
	},
];
export default routes;
