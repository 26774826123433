/** @format */

import { sessionService } from "redux-react-session";
import { apiCall, apiNoTokenCall, createQueryParams } from "../index";
import hostConfig from "./../../../config/hostConfig";
import API from "../serviceList";

export function login(email, password) {
	return apiNoTokenCall({
		method: "POST",
		url: `${API.login}`,
		data: {
			email: email,
			password: password,
		},
	});
}

export function logoutAdminSession(reqData) {
	return apiCall({
		method: "POST",
		url: `${API.userlogout}`,
		data: reqData,
	});
}
export function refreshAdminSession(reqData) {
	return apiCall({
		method: "POST",
		url: `${API.sessionclient}`,
		data: reqData,
	});
}

export function registerUser(reqData) {
	return apiNoTokenCall({
		method: "POST",
		url: `${API.register}`,
		data: reqData,
	});
}

export function updateUser(reqData) {
	return apiCall({
		method: "PUT",
		url: `${API.updateUser}`,
		data: reqData,
	});
}
export function addMoneyToWallet(reqData) {
	return apiCall({
		method: "POST",
		url: `${API.addMoneyToWallet}`,
		data: reqData,
	});
}

export function forgotPassword(email) {
	let url = `${API.forgotpassword}`;
	return apiNoTokenCall({
		method: "POST",
		url: url,
		data: {
			email: email,
			origin: `${hostConfig.origin}`,
		},
	});
}
export function getUser(queryParm) {
	let query = createQueryParams(queryParm);
	const id = localStorage.getItem("id");
	return apiCall({
		method: "GET",
		url: `${API.usersList}${query}?aid=${id}`,
		// data: {
		// 	id: JSON.parse(localStorage.getItem("id")),
		// },
	});
}
export function getUserById(reqData) {
	// let query = createQueryParams(reqData);
	return apiCall({
		method: "POST",
		url: `${API.usersListById}`,
		data: reqData,
	});
}
export function deleteUserServices(reqData) {
	let query = createQueryParams(reqData);
	return apiCall({
		method: "DELETE",
		// url: `${API.deleteuser}`,
		url: `${API.deleteuserhard}${query}`,
		data: reqData,
	});
}

export function changeUserStatus(reqData) {
	let query = createQueryParams(reqData);
	return apiCall({
		method: "PUT",
		// url: `${API.deleteuser}`,
		url: `${API.softdeleteuser}${query}`,
		data: reqData,
	});
}

export function getFilteredUserList(reqData) {
	return apiCall({
		method: "POST",
		url: `${API.usersFilteredList}`,
		data: reqData,
	});
}
export function getUserTransactions(reqData) {
	return apiCall({
		method: "POST",
		url: `${API.usersTransactionsList}`,
		data: reqData,
	});
}
