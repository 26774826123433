/** @format */

import UserHeader from "components/Headers/UserHeader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// reactstrap components
import {
	Badge,
	Card,
	CardHeader,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Media,
	Progress,
	Table,
	Container,
	Row,
	FormGroup,
	UncontrolledTooltip,
	InputGroup,
	Input,
	InputGroupAddon,
	Button,
} from "reactstrap";
import { getWallets } from "./../../redux/actions";

import {
	getGameBetsWithUserId,
	getUserListWithId,
	getGames,
} from "./../../redux/actions";

// core components

const Summary = (props) => {
	const dispatch = useDispatch();
	const [bets, setBets] = useState([]);
	const [games, setGames] = useState([]);
	const [totalAmount, setTotalAmount] = useState(0);
	const [state, setState] = useState({
		user_name: "",
		first_name: "",
		last_name: "",
		email: "",
		phone_number: "",
		refer_code: "",
		status: "",
		password: "",
		amount: 0,
	});

	// let currentDate = moment().format("YYYY-MM-DD");
	// let nextDay = moment().add(1, "day").format("YYYY-MM-DD");
	// let startTime = moment(currentDate + "T06:00").format("HH:mm:ss");
	// let endTime = moment(currentDate + "T05:59").format("HH:mm:ss");

	let currentDate = moment().format("YYYY-MM-DD");
	let prevDay = moment().subtract(1, "day").format("YYYY-MM-DD");
	let nextDay = moment().add(1, "day").format("YYYY-MM-DD");
	let startTime = moment(currentDate + "T06:00").format("HH:mm:ss");
	let endTime = moment(currentDate + "T05:30").format("HH:mm:ss");
	const [filter, setFilter] = useState({
		filterS: {
			game_id: "",
			user_id: "",
			amount: "",
			min: "",
			max: "",
			// createdDate: moment(currentDate + " " + startTime).format(
			// 	"YYYY-MM-DD HH:mm:ss",
			// ),
			// startDate: moment().format("YYYY-MM-DD HH:mm:ss"),
			// endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
			// startDate: moment(currentDate).format("YYYY-MM-DD"),
			// endDate: moment(nextDay + " " + endTime).format("YYYY-MM-DD HH:mm:ss"),

			createdDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
			startDate: moment(prevDay + " " + startTime).format("YYYY-MM-DD HH:mm:ss"),
			endDate: moment(nextDay + " " + endTime).format("YYYY-MM-DD HH:mm:ss"),
			// result_time: moment().format("YYYY-MM-DD HH:mm:ss"),
		},
	});

	const user_Id = props.match.params.id;
	useEffect(() => {
		// console.log("profile props", props);
		if (moment().format("HH") >= 6) {
			setFilter({
				filterS: {
					...filter?.filterS,
					startDate: moment(currentDate + " " + startTime).format(
						"YYYY-MM-DD HH:mm:ss",
					),
					endDate: moment(nextDay + " " + endTime).format(
						"YYYY-MM-DD HH:mm:ss",
					),
				},
			});
		} else {
			setFilter({
				filterS: {
					...filter?.filterS,
					startDate: moment(prevDay + " " + startTime).format(
						"YYYY-MM-DD HH:mm:ss",
					),
					endDate: moment(currentDate + " " + endTime).format(
						"YYYY-MM-DD HH:mm:ss",
					),
				},
			});
		}
		const userId = props.match.params.id;
		if (
			userId !== null &&
			userId !== undefined &&
			userId !== "" &&
			userId !== ":id"
		) {
			getUserBetDetails({ id: userId, createdAt: filter.filterS.startDate });
			getUserDetails({ id: userId });
			getCustomerBalance({ id: userId });
		}
		getAllGames();
	}, []);

	const getCustomerBalance = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		// console.log(query);
		dispatch(
			getWallets(query, (errors, res) => {
				if (errors) {
					console.log(errors);
					dispatch({ type: "LOADING_SUCCESS" });
				} else {
					// console.log(res);
					setTotalAmount(res?.data[0]?.total_amount);
					setState((prev) => ({
						...prev,
						amount: res?.data[0]?.total_amount,
					}));
				}

				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};

	const getUserDetails = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getUserListWithId(query, (errors, res) => {
				if (errors) {
					console.log(errors);
					dispatch({ type: "LOADING_SUCCESS" });
				}
				setState(res?.data?.data);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
		dispatch({ type: "LOADING_SUCCESS" });
	};
	const getUserBetDetails = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getGameBetsWithUserId(query, (errors, res) => {
				if (errors) {
					console.log(errors);
					dispatch({ type: "LOADING_SUCCESS" });
				}
				setBets(res?.data);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
		dispatch({ type: "LOADING_SUCCESS" });
	};

	const getAllGames = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getGames(query, (errors, res) => {
				// console.log("getGames=>", res);
				setGames(res?.data?.response);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};

	const getAllGamesWithId = (id) => {
		// console.log(id);
		let game = games?.filter((gm) => gm._id === id);
		return game[0]?.game_name || "NA";
	};

	// const handleChange = (e) => {
	// 	const { id, value } = e.target;

	// 	setFilter((prevState) => ({
	// 		...prevState,
	// 		filterS: {
	// 			...filter.filterS,
	// 			[id]: value,
	// 		},
	// 	}));
	// };

	const handleChange = (e) => {
		const { id, value } = e.target;
		// let
		// console.log(id, value);
		if (id === "game_id" && value !== "") {
			// console.log("game", value);
			let findGame = games.find((game) => game._id === value);
			// console.log("findGame", findGame);
			let next = findGame.next_date_status === "1" ? true : false;

			let startTime = moment(findGame.start_date).format("HH:mm:ss");
			let endTime = moment(findGame.end_date).format("HH:mm:ss");
			let resultTime = moment(findGame.result_time).format("HH:mm:ss");
			// console.log("start", startTime);
			// console.log("end", endTime);
			let currentDate = moment().format("YYYY-MM-DD");
			// console.log("currentDate", currentDate);
			let yesterDay = moment().subtract(1, "day").format("YYYY-MM-DD");
			// console.log("yesterDay", yesterDay);
			let dates = next ? yesterDay : currentDate;

			let startDate = moment(dates + " " + startTime).format("YYYY-MM-DD HH:mm:ss");

			let endDate = moment(currentDate + " " + endTime).format(
				"YYYY-MM-DD HH:mm:ss",
			);
			let resultDate = moment(currentDate + " " + resultTime).format(
				"YYYY-MM-DD HH:mm:ss",
			);

			console.log("startDate", startDate);
			let result_time = resultDate || moment().format("YYYY-MM-DD HH:mm:ss");
			// setState((prevState) => ({
			// 	...prevState,
			// 	[id]: value,
			// 	startDate: startDate,
			// 	endDate: endDate,
			// 	result_time: result_time,
			// }));

			setFilter((prevState) => ({
				...prevState,
				filterS: {
					...filter.filterS,
					[id]: value,
					startDate: startDate,
					endDate: endDate,
					// result_time: result_time,
				},
			}));
		} else {
			// console.log(new Date(moment(value).format("YYYY,MM,DD")));
			// setState((prevState) => ({
			// 	...prevState,
			// 	[id]: value,
			// }));
			setFilter((prevState) => ({
				...prevState,
				filterS: {
					...filter.filterS,
					[id]: value,
				},
			}));
		}
		// console.log(id, value);
	};
	// console.log(filter.filterS);
	return (
		<>
			{/* <Header /> */}
			<UserHeader state={state} />
			{/* Page content */}
			<Container className='mt--7' fluid>
				{/* Dark table */}
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-default shadow'>
							<CardHeader className='bg-transparent border-0 mt-7'>
								<h3 className='text-white mb-0'>Summary</h3>
								<div
									className='d-flex  mt-2'
									style={{ flexDirection: "column" }}>
									<InputGroup size='xl' className='w-100 mt-2 ml-2'>
										<Input
											type='select'
											autoComplete='new-name'
											value={filter.filterS.game_id}
											onChange={handleChange}
											id='game_id'
											placeholder='Select Game'
											name='game_id'
											required>
											<option key={"select"} value={""}>
												Select Game
											</option>
											{games && games.length
												? games.map((list, index) => {
														return (
															<option
																key={index + 1}
																value={list._id}>
																{list?.game_name}
															</option>
														);
												  })
												: ""}
										</Input>
									</InputGroup>
									<FormGroup>
										<label>Start Time</label>
										<InputGroup size='xl' className='w-100 mt-2'>
											<InputGroupAddon
												addonType='prepend'
												className='d-inline'>
												<Button className='bg-default shadow'>
													<i className='ni ni-calendar-grid-58 text-white' />
												</Button>
											</InputGroupAddon>
											<Input
												type='datetime-local'
												autoComplete='new-sdate'
												id='startDate'
												placeholder='Start Date'
												name='startDate'
												value={filter.filterS.startDate}
												onChange={handleChange}
												required
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<label>End Time</label>
										<InputGroup size='xl' className='w-100 mt-2'>
											<InputGroupAddon
												addonType='prepend'
												className='d-inline'>
												<Button className='bg-default shadow'>
													<i className='ni ni-calendar-grid-58 text-white' />
												</Button>
											</InputGroupAddon>
											<Input
												type='datetime-local'
												autoComplete='new-sdate'
												id='endDate'
												placeholder='Start Date'
												name='endDate'
												// disabled={true}
												value={filter.filterS.endDate}
												onChange={handleChange}
												required
											/>
										</InputGroup>
									</FormGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='Button'
											onClick={() =>
												getUserBetDetails({
													id: user_Id,
													...filter.filterS,
													createdAt: filter.filterS.startDate,
												})
											}
											className='bg-default text-white'
											value={"Search"}></Input>
									</InputGroup>
								</div>
							</CardHeader>

							<Table
								className='align-items-center table-dark table-flush'
								responsive>
								<thead className='thead-dark'>
									<tr>
										<th scope='col' className='text-center'>
											User
										</th>
										<th scope='col' className='text-center'>
											Date
										</th>
										<th scope='col' className='text-center'>
											Before
										</th>
										<th scope='col' className='text-center'>
											Amount
										</th>
										<th scope='col' className='text-center'>
											After
										</th>
										<th scope='col' className='text-center'>
											Details
										</th>
										{/* <th scope='col' className='text-center'>Result</th>
										<th scope='col' className='text-center'>Date</th> */}
										<th scope='col' />
									</tr>
								</thead>
								<tbody>
									{bets?.length > 0 &&
										bets?.map((items, index) => {
											return (
												<tr key={index + 1}>
													<th scope='row'>
														<Media className='align-items-center'>
															<a
																className='avatar rounded-circle mr-3'
																href='#pablo'
																onClick={(e) =>
																	e.preventDefault()
																}>
																{/* <img
																	alt='...'
																	src={
																		require("../../assets/img/theme/bootstrap.jpg")
																			.default
																	}
																/> */}
																{state?.user_name
																	.substring(0, 2)
																	.toUpperCase()}
															</a>
															<Media>
																<span className='mb-0 text-sm'>
																	{state?.user_name}
																</span>
															</Media>
														</Media>
													</th>
													<td>
														{moment(items?.createdAt).format(
															"dddd",
														)}
													</td>
													<td className='text-yellow'>
														{items?.current_amount}
													</td>
													<td className='text-danger'>
														{items?.total_ammount_spend}
													</td>
													<td className='text-success'>
														{items?.after_amount}
													</td>
													<td className='border-1'>
														{/* <Badge
															color=''
															className='badge-dot mr-4'>
															<i className='bg-warning' />
															pending
														</Badge> */}

														<Table
															className='align-items-center table-dark table-flush'
															responsive>
															<tbody>
																<tr>
																	<th className='text-center'>
																		Game
																	</th>
																	<th className='text-center'>
																		NUM
																	</th>
																	<th className='text-center text-danger'>
																		AMT
																	</th>
																	<th className='text-center'>
																		TYPE
																	</th>
																	<th className='text-center'>
																		TIME
																	</th>
																	<th className='text-center'>
																		DATE
																	</th>
																</tr>
																{items?.bets?.length >
																	0 &&
																	items?.bets?.map(
																		(item, ind) => {
																			return (
																				<tr
																					key={
																						ind +
																						1
																					}>
																					<td>
																						{getAllGamesWithId(
																							items?.game_id,
																						)}
																					</td>
																					<td className='text-center'>
																						{
																							item?.bet_number
																						}
																					</td>
																					<td className='text-center text-danger'>
																						{
																							item?.bet_amount
																						}
																					</td>
																					<td className='text-center'>
																						J
																					</td>
																					<td className='text-center'>
																						{moment(
																							item?.createdDate,
																						).format(
																							"hh:mm:ss a",
																						)}
																					</td>
																					<td className='text-center'>
																						{moment(
																							item?.createdDate,
																						).format(
																							"DD-MM-YY",
																						)}
																					</td>
																				</tr>
																			);
																		},
																	)}
																{items?.inside_bets
																	.length > 0 &&
																	items?.inside_bets?.map(
																		(item, ind) => {
																			return (
																				<tr
																					key={
																						ind +
																						1
																					}>
																					<td className='text-center'>
																						{getAllGamesWithId(
																							items?.game_id,
																						)}
																					</td>
																					<td className='text-center'>
																						{
																							item?.bet_number
																						}
																					</td>
																					<td className='text-center text-danger'>
																						{
																							item?.bet_amount
																						}
																					</td>
																					<td className='text-center'>
																						A
																					</td>
																					<td className='text-center'>
																						{moment(
																							item?.createdDate,
																						).format(
																							"hh:mm:ss a",
																						)}
																					</td>
																					<td className='text-center'>
																						{moment(
																							item?.createdDate,
																						).format(
																							"DD-MM-YY",
																						)}
																					</td>
																				</tr>
																			);
																		},
																	)}

																{items?.outside_bets
																	.length > 0 &&
																	items?.outside_bets?.map(
																		(item, ind) => {
																			return (
																				<tr
																					key={
																						ind +
																						1
																					}>
																					<td className='text-center'>
																						{getAllGamesWithId(
																							items?.game_id,
																						)}
																					</td>
																					<td className='text-center'>
																						{
																							item?.bet_number
																						}
																					</td>
																					<td className='text-center text-danger'>
																						{
																							item?.bet_amount
																						}
																					</td>
																					<td className='text-center'>
																						B
																					</td>
																					<td className='text-center'>
																						{moment(
																							item?.createdDate,
																						).format(
																							"hh:mm:ss a",
																						)}
																					</td>
																					<td className='text-center'>
																						{moment(
																							item?.createdDate,
																						).format(
																							"DD-MM-YY",
																						)}
																					</td>
																				</tr>
																			);
																		},
																	)}
															</tbody>
														</Table>
													</td>
													{/* <td>
														<div className='avatar-group'>
															<a
																className='avatar avatar-sm'
																href='#pablo'
																id='tooltip731399078'
																onClick={(e) =>
																	e.preventDefault()
																}>
																<img
																	alt='...'
																	className='rounded-circle'
																	src={
																		require("../../assets/img/theme/team-1-800x800.jpg")
																			.default
																	}
																/>
															</a>
															<UncontrolledTooltip
																delay={0}
																target='tooltip731399078'>
																Ryan Tompson
															</UncontrolledTooltip>
															<a
																className='avatar avatar-sm'
																href='#pablo'
																id='tooltip491083084'
																onClick={(e) =>
																	e.preventDefault()
																}>
																<img
																	alt='...'
																	className='rounded-circle'
																	src={
																		require("../../assets/img/theme/team-2-800x800.jpg")
																			.default
																	}
																/>
															</a>
															<UncontrolledTooltip
																delay={0}
																target='tooltip491083084'>
																Romina Hadid
															</UncontrolledTooltip>
															<a
																className='avatar avatar-sm'
																href='#pablo'
																id='tooltip528540780'
																onClick={(e) =>
																	e.preventDefault()
																}>
																<img
																	alt='...'
																	className='rounded-circle'
																	src={
																		require("../../assets/img/theme/team-3-800x800.jpg")
																			.default
																	}
																/>
															</a>
															<UncontrolledTooltip
																delay={0}
																target='tooltip528540780'>
																Alexander Smith
															</UncontrolledTooltip>
															<a
																className='avatar avatar-sm'
																href='#pablo'
																id='tooltip237898869'
																onClick={(e) =>
																	e.preventDefault()
																}>
																<img
																	alt='...'
																	className='rounded-circle'
																	src={
																		require("../../assets/img/theme/team-4-800x800.jpg")
																			.default
																	}
																/>
															</a>
															<UncontrolledTooltip
																delay={0}
																target='tooltip237898869'>
																Jessica Doe
															</UncontrolledTooltip>
														</div>
													</td>
													<td>
														<div className='d-flex align-items-center'>
															<span className='mr-2'>
																60%
															</span>
															<div>
																<Progress
																	max='100'
																	value='60'
																	barClassName='bg-warning'
																/>
															</div>
														</div>
													</td>
													<td className='text-right'>
														<UncontrolledDropdown>
															<DropdownToggle
																className='btn-icon-only text-light'
																href='#pablo'
																role='button'
																size='sm'
																color=''
																onClick={(e) =>
																	e.preventDefault()
																}>
																<i className='fas fa-ellipsis-v' />
															</DropdownToggle>
															<DropdownMenu
																className='dropdown-menu-arrow'
																right>
																<DropdownItem
																	href='#pablo'
																	onClick={(e) =>
																		e.preventDefault()
																	}>
																	Action
																</DropdownItem>
																<DropdownItem
																	href='#pablo'
																	onClick={(e) =>
																		e.preventDefault()
																	}>
																	Another action
																</DropdownItem>
																<DropdownItem
																	href='#pablo'
																	onClick={(e) =>
																		e.preventDefault()
																	}>
																	Something else here
																</DropdownItem>
															</DropdownMenu>
														</UncontrolledDropdown>
													</td> */}
												</tr>
											);
										})}
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td className='text-danger text-center'>
											Total :{" "}
											{bets?.reduce(
												(totalValue, item) =>
													totalValue +
													Number(item?.total_ammount_spend),
												0,
											)}
										</td>
									</tr>
								</tbody>
							</Table>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

export default Summary;
