/** @format */

import { apiCall, createQueryParams } from "../index";
import API from "../serviceList";

export function getGameBets(queryParm) {
	let query = createQueryParams(queryParm);
	return apiCall({
		method: "GET",
		url: `${API.getGameBets}${query}`,
	});
}
export function getGameBetsWithUserId(reqData) {
	// let query = createQueryParams(queryParm);
	return apiCall({
		method: "POST",
		url: `${API.getGameBetsWithUserId}`,
		data: reqData,
	});
}

export function deleteGameBets(reqData) {
	// let query = createQueryParams(queryParm);
	return apiCall({
		method: "POST",
		url: `${API.deleteGameBetsApi}`,
		data: reqData,
	});
}
