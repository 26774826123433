/** @format */

import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
// reactstrap components
import {
	Card,
	CardHeader,
	Button,
	Input,
	Container,
	Row,
	UncontrolledDropdown,
	InputGroup,
	InputGroupAddon,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { getMessagesDataListAction, getUserList } from "../../redux/actions";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	TablePagination,
} from "@material-ui/core";
import {
	changeStatusOfUser,
	deleteUserAction,
	getUserListWithId,
} from "redux/actions/userActions";
import { toastr } from "react-redux-toastr";
import moment from "moment";

const Users = (props) => {
	// console.log(props, "PROPSSS");
	const history = useHistory();
	const { userList, totalCount } = props;
	const dispatch = useDispatch();
	const [whatsappMessage, setWhatsappMessage] = useState({});
	const [filter, setFilter] = useState({
		filterS: {
			status: "",
			phone_number: "",
			searchText: "",
			pageNumber: 1,
			pageSize: 10,
			sortOrder: -1,
			sortField: "_id"
		},
	});
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleFilterChange = (e) => {
		e.preventDefault();
		const { id, value } = e.target;
		setFilter((prevState) => ({
			...prevState,
			filterS: {
				...filter.filterS,
				[id]: value,
			},
		}));
	};
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const [searchText, setSearchText] = useState("");
	const [userData, setUserData] = useState([]);

	useEffect(() => {
		getUserData({
			...filter.filterS,
			pageNumber: page || 1,
			pageSize: rowsPerPage || 10
		})
	}, []);
	useEffect(() => {
		getUserData({
			...filter.filterS,
			pageNumber: page || 1,
			pageSize: rowsPerPage || 10
		})

	}, [page, rowsPerPage]);

	const getUserData = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getUserList(query, (errors, res) => {
				dispatch({ type: "LOADING_SUCCESS" });
				if (res.data) {
					setUserData({ userList: res.data.response, totalCount: res.data.totalCount })
				}
			}),
		);
	};

	const getSearchUser = (e) => {
		e.preventDefault();
		setSearchText(e.target.value);
		// let user = shortListedUser.filter(user => {
		//     let userName = user.shortlistedUser.first_name + user.shortlistedUser.last_name;
		//     return userName.indexOf(e.target.value) > 0;
		// });
		// setSearchedShortListedUser(user);
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

	const handleStatusChange = (id = 0, status = "active") => {
		let query = { id: id, status: status, role: "admin" };
		dispatch({ type: "LOADING_START" });
		dispatch(
			changeStatusOfUser(query, (errors, res) => {
				if (res?.data.success) {
					toastr.success("Status Changed", res?.data?.message);
				}
				dispatch(
					getUserList((errors, res) => {
						dispatch({ type: "LOADING_SUCCESS" });
						if (res.data) {
							setUserData({ userList: res.data.response, totalCount: res.data.totalCount })
						}
					}),
				);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
		// console.log("STATUS");
	};

	const handleUserDelete = (id = 0) => {
		// console.log("DELETED");
		let query = { id: id, role: "admin" };
		dispatch({ type: "LOADING_START" });
		dispatch(
			deleteUserAction(query, (errors, res) => {
				// console.log("res", res);
				// console.log("error", errors);

				dispatch(
					getUserList((errors, res) => {
						dispatch({ type: "LOADING_SUCCESS" });
						if (res.data) {
							setUserData({ userList: res.data.response, totalCount: res.data.totalCount })
						}
					}),
				);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};

	const getMessagesData = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getMessagesDataListAction(query, (errors, res) => {
				// setUPIData(res?.data?.response);
				let response = res.data.response[0];

				setWhatsappMessage({
					...response,
				});

				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};
	// console.log(whatsappMessage?.notice_msg);
	const handleSendWelcomeEmail = (e, phone_number) => {
		// console.log(e);
		let message = whatsappMessage?.notice_msg
			? whatsappMessage?.notice_msg
			: "Welcome to the Matka Game";

		let number = "+91" + phone_number.replace(/[^\w\s]/gi, "").replace(/ /g, "");

		window.mobileCheck = function () {
			let check = false;
			(function (a) {
				if (
					/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
						a,
					) ||
					/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
						a.substr(0, 4),
					)
				)
					check = true;
			})(navigator.userAgent || navigator.vendor || window.opera);
			return check;
		};
		console.log(window.mobileCheck());

		let url = window.mobileCheck()
			? `whatsapp://send?phone=${number}`
			: `https://web.whatsapp.com/send?phone=${number}`;
		url += `&text=${encodeURI(message)}&app_absent=0`;
		window.open(url);
	};

	const getCountry = () => {
		const $ = window;
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(function (position) {
				$.getJSON(
					"http://ws.geonames.org/countryCode",
					{
						lat: position.coords.latitude,
						lng: position.coords.longitude,
						type: "JSON",
					},
					function (result) {
						alert(result.countryName);
						console.log(result.countryName);
					},
				);
			});
		}
	};

	const handleNavidateToProfile = (e, list) => {
		// console.log("list", list);
		history.push(`/admin/user-profile/${list?._id}`, { data: list });
	};
	const handleNavidateToSummary = (e, list) => {
		// console.log("list", list);
		history.push(`/admin/summary/${list?._id}`, { data: list });
	};

	return (
		<>
			{/* <Header /> */}
			{/* Page content */}
			<Container className='mt-7' fluid>
				{/* Dark table */}
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-default shadow'>
							<CardHeader className='bg-transparent border-0 mt-7'>
								<h3 className='text-white mb-0'>Users Details</h3>
								<div
									className='d-flex mt-2'
									style={{ flexDirection: "column" }}>
									<InputGroup size='xl' className='ml-2 mt-2 w-100'>
										<Input
											id='searchText'
											type='text'
											name='searchText'
											value={filter.filterS.searchText}
											onChange={handleFilterChange}
											placeholder='Search...'
										/>
									</InputGroup>
									{/* <InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											id='phone_number'
											step='1'
											type='number'
											name='phone_number'
											value={filter.filterS.phone_number}
											onChange={handleFilterChange}
											placeholder='Search Phone Number'
										/>
									</InputGroup> */}
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											type='Button'
											onClick={() =>
												getUserData({
													...filter.filterS,
													pageNumber: page || 1,
													pageSize: rowsPerPage || 10,

												})
											}
											className='bg-default text-white'
											value={"Search"}></Input>
									</InputGroup>
								</div>
							</CardHeader>
							<TableContainer>
								<Table
									className='align-items-center table-dark table-flush'
									responsive>
									<TableHead>
										<TableRow>
											<TableCell className='text-white'>
												Name
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Phone Number
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Referral
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Refered By
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												{" "}
												Status
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												{" "}
												created
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												{" "}
												Action
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{userData?.userList && userData?.userList.length
											? userData?.userList
												// .slice(
												// 	page * rowsPerPage,
												// 	page * rowsPerPage + rowsPerPage,
												// )
												.map((list, index) => {
													return (
														<TableRow key={index}>
															<TableCell className='text-white' style={{ maxWidth: "100px", fontSize: "12px" }}>
																{`${list?.first_name} ${list?.last_name}`}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{list?.phone_number}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{list?.refer_code}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{list?.refered_by ||
																	"NOT REFERED"}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																<Button
																	onClick={() =>
																		handleStatusChange(
																			list?._id,
																			list?.status,
																		)
																	}
																	variant='contained'
																	color={
																		list?.status ===
																			"active"
																			? "success"
																			: "danger"
																	}>
																	{list?.status ===
																		"active"
																		? "BLOCK"
																		: "UNBLOCK"}
																</Button>
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{moment(list.createdAt)?.format("DD-ddd-MMM-YYYY HH:MM a")}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																<Button
																	onClick={() =>
																		handleUserDelete(
																			list?._id,
																		)
																	}
																	variant='contained'
																	color='danger'>
																	Delete
																</Button>
																{/* <Button
																		onClick={() =>
																			handleStatusChange(
																				list?._id,
																				list?.status,
																			)
																		}
																		variant='contained'
																		color='primary'>
																		BLOCK
																	</Button> */}
																<Button
																	onClick={(e) =>
																		handleSendWelcomeEmail(
																			e,
																			list?.phone_number,
																		)
																	}
																	variant='contained'
																	color='secondary'>
																	send
																</Button>
																<Button
																	onClick={(e) =>
																		handleNavidateToProfile(
																			e,
																			list,
																		)
																	}
																	variant='contained'
																	color='secondary'>
																	Edit
																</Button>
																<Button
																	onClick={(e) =>
																		handleNavidateToSummary(
																			e,
																			list,
																		)
																	}
																	variant='contained'
																	color='secondary'>
																	SUMMARY
																</Button>
															</TableCell>
														</TableRow>
													);
												})
											: ""}
										{/* {emptyRows > 0 && (
											<TableRow
												style={{
													height: 53 * emptyRows,
												}}>
												<TableCell colSpan={6} />
											</TableRow>
										)} */}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TablePagination
												className='text-white'
												rowsPerPageOptions={[
													5,
													10,
													25,
													50,
													100,
													// { label: "All", value: -1 },
												]}
												colSpan={5}
												count={userData.totalCount}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onRowsPerPageChange={
													handleChangeRowsPerPage
												}
											// showFirstButton={true}
											// showLastButton={true}
											/>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

function mapStateToProps(state) {
	return {
		user: state.session.user,
		userList: state.user.userList,
		totalCount: state.user.totalCount
	};
}

export default withRouter(connect(mapStateToProps, {})(Users));
