/** @format */

import { apiCall, createQueryParams } from "../index";
import API from "../serviceList";

export function getWallets(reqData) {
	// let query = createQueryParams(queryParm);
	const id = localStorage.getItem("id");
	return apiCall({
		method: "POST",
		url: `${API.getWallets}`,
		data: {
			...reqData,
			aid: id,
		},
	});
}

export function updateWallets(queryParm) {
	let query = createQueryParams(queryParm);
	return apiCall({
		method: "PUT",
		url: `${API.updateWallets}${query}`,
	});
}
