/** @format */
/** @format */

import React, { useState, useEffect } from "react";
import moment from "moment";
import TextField from "@material-ui/core/TextField";

import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import {
	getWallets,
	updateUPIDataRequest,
	createUpidActions,
	getNewsDataListAction,
	createNewsFirstAction,
	deleteUpiData,
	updateNewsAction,
} from "../../redux/actions";
import { toastr } from "react-redux-toastr";

// reactstrap components
import {
	Card,
	CardHeader,
	Container,
	Row,
	Button,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { getTransactionHistory } from "../../redux/actions";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	TablePagination,
	TextareaAutosize,
} from "@material-ui/core";

const UpdateNews = (props) => {
	// console.log(props, "props");
	const { walletsList } = props.wallets;
	let walletData = walletsList.filter((wallet) => {
		return wallet?.phone_number;
	});
	const filterOptions = createFilterOptions({
		ignoreCase: false,
		ignoreAccents: false,
	});
	// const { transactionHistory, userRequests } = props.transactionHistory;
	// console.log(userRequests, "transactionHistory");
	const dispatch = useDispatch();
	const [newsData, setNewsData] = useState([]);
	const [state, setState] = useState({
		submitted: false,
		home_page_msg: "",
		add_money_msg: "",
		withdraw_money_msg: "",
		terms_and_condtions_msg: "",
		_id: "",
	});
	const [filter, setFilter] = useState({
		filterS: {
			sttus: false,
			upi_id: "",
			upi_name: "",
			created_at: "",
			updated_at: "",
		},
	});
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		getNewsData({});
		// dispatch(
		// 	getWallets({}, (errors, res) => {
		// 		dispatch({ type: "LOADING_SUCCESS" });
		// 	}),
		// );
	}, []);
	const handleChange = (e) => {
		const { id, value, name } = e.target;
		if (name) {
			setState((prevState) => ({
				...prevState,
				[name]: value,
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				[id]: value,
			}));
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setState((prevState) => ({
			...prevState,
			submitted: true,
		}));
		let reqData = {
			home_page_msg: state.home_page_msg,
			add_money_msg: state.add_money_msg,
			withdraw_money_msg: state.withdraw_money_msg,
			terms_and_condtions_msg: state.terms_and_condtions_msg,
			_id: state._id,
			status: "1",
		};
		dispatch(
			updateNewsAction(reqData, (res, errors) => {
				toastr.success("Success", "Udpated Requested Successfully");
				dispatch(
					getNewsDataListAction({}, (errors, res) => {
						dispatch({ type: "LOADING_SUCCESS" });
					}),
				);
			}),
		);
	};

	const handleCreateNews = () => {
		setState((prevState) => ({
			...prevState,
			submitted: false,
		}));
		let reqData = {
			home_page_msg: state.home_page_msg,
			add_money_msg: state.add_money_msg,
			withdraw_money_msg: state.withdraw_money_msg,
			terms_and_condtions_msg: state.terms_and_condtions_msg,
			status: "1",
		};
		dispatch(
			createNewsFirstAction(reqData, (res, errors) => {
				toastr.success("Success", "Created Requested Successfully");
				dispatch(
					getNewsDataListAction({}, (errors, res) => {
						dispatch({ type: "LOADING_SUCCESS" });
					}),
				);
			}),
		);
	};

	const onUpdate = (e, list, status) => {
		let updatData = {
			...list,
			status: status,
		};
		dispatch(
			updateUPIDataRequest(updatData, (err, res) => {
				if (res.data.message) {
					toastr.success("error", res.data.message);
				} else {
					toastr.success("Success", "Request Updated Successfully");
				}
				getNewsData({});
			}),
		);
	};
	const getNewsData = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getNewsDataListAction(query, (errors, res) => {
				let response = res.data.response[0];

				setState((prevState) => ({ ...prevState, ...response }));
				// setNewsData(res?.data?.response[0]);

				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};
	const status = {
		debit: "Debit",
		credit: "Credit",
	};
	const handleFilterChange = (e) => {
		e.preventDefault();
		const { id, value } = e.target;
		setFilter((prevState) => ({
			...prevState,
			filterS: {
				...filter.filterS,
				[id]: value,
			},
		}));
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - newsData.length) : 0;

	const onDelete = (e, ids) => {
		// console.log(e);

		e.preventDefault();
		let id = {
			_id: ids,
		};
		dispatch(
			deleteUpiData(id, (errors, res) => {
				if (res.data.message) {
					toastr.success("error", res.data.message);
				} else {
					toastr.success("Success", "UPI Deleted Successfully");
				}
				getNewsData({});
			}),
		);
	};

	return (
		<>
			{/* <Header /> */}
			{/* Page content */}
			<Container className='mt-7' fluid>
				{/* Dark table */}
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-secondary shadow'>
							<CardHeader className='bg-transparent border-0 mt-7'>
								<h3 className='mb-0 text-center'>
									Update News For User Account
								</h3>
							</CardHeader>
							<CardBody>
								<Form role='form'>
									<FormGroup>
										<label>Home Page</label>
										<Input
											className='form-control-alternative'
											variant='outlined'
											id='home_page_msg'
											name='home_page_msg'
											placeholder='Home Page Message'
											rows='4'
											defaultValue=''
											type='textarea'
											value={state.home_page_msg}
											onChange={handleChange}
										/>

										{state.submitted && !state.home_page_msg && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<label>Add Money</label>

										<Input
											className='form-control-alternative'
											variant='outlined'
											id='add_money_msg'
											placeholder='Add Money Message'
											name='add_money_msg'
											rows='4'
											defaultValue=''
											type='textarea'
											value={state.add_money_msg}
											onChange={handleChange}
										/>

										{state.submitted && !state.add_money_msg && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>
									<FormGroup>
										<label>Withdraw Money</label>
										<Input
											className='form-control-alternative'
											placeholder='Withdraw Money Message'
											id='withdraw_money_msg'
											name='withdraw_money_msg'
											rows='4'
											defaultValue=''
											type='textarea'
											value={state.withdraw_money_msg}
											onChange={handleChange}
										/>
										{state.submitted && !state.withdraw_money_msg && (
											<div className='error'>
												This message is required
											</div>
										)}
									</FormGroup>

									<FormGroup>
										<label>Terms and Conditions</label>
										<Input
											className='form-control-alternative'
											placeholder='Terms and Conditions'
											id='terms_and_condtions_msg'
											name='terms_and_condtions_msg'
											rows='4'
											defaultValue=''
											type='textarea'
											value={state.terms_and_condtions_msg}
											onChange={handleChange}
										/>

										{state.submitted &&
											!state.terms_and_condtions_msg && (
												<div className='error'>
													This message is required
												</div>
											)}
									</FormGroup>

									<div className='text-center'>
										{state?._id !== "" ? (
											<Button
												// disabled={
												// 	!(state.upi_id && state.upi_name)
												// 	// state.transaction_type !== "none" &&
												// }
												onClick={handleSubmit}
												className='my-4'
												color='primary'
												style={{
													backgroundColor: "#9b5de5",
													border: "none",
												}}
												type='button'>
												Update
											</Button>
										) : (
											<Button
												// disabled={
												// 	!(state.upi_id && state.upi_name)
												// 	// state.transaction_type !== "none" &&
												// }
												onClick={handleCreateNews}
												className='my-4'
												color='primary'
												style={{
													backgroundColor: "#9b5de5",
													border: "none",
												}}
												type='button'>
												CREATE
											</Button>
										)}
									</div>
								</Form>
							</CardBody>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

function mapStateToProps(state) {
	return {
		user: state.session.user,
		wallets: state.wallets,
		transactionHistory: state.transactionHistory,
	};
}

export default withRouter(connect(mapStateToProps, {})(UpdateNews));
