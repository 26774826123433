/** @format */

import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { Link, withRouter, useHistory } from "react-router-dom";
// reactstrap components
import {
	Card,
	CardHeader,
	Container,
	Row,
	UncontrolledDropdown,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Input,
	InputGroup,
	InputGroupAddon,
	Button,
	FormGroup,
	Label,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import {
	deleteTransactionHistoryWithId,
	getTransactionHistory,
} from "../../redux/actions";

import {
	TableBody,
	TableCell,
	TableRow,
	TableContainer,
	TablePagination,
	Table,
	TableHead,
	TableFooter,
} from "@material-ui/core";

const TransactionsHistory = (props) => {
	// const { transactionHistory } = props.transactionHistory;
	console.log(props)
	const { transactionHistoryEnd, userRequests, transactions, totalCountEnd: totalCount } = props.transactionHistory;
	const dispatch = useDispatch();
	const [transactionHistorySet, setTransactionHistory] = useState([]);
	const [idsArray, setIdsArray] = useState([]);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const [filter, setFilter] = useState({
		filterS: {
			transaction_type: "",
			transaction_mode: "",
			phone_number: "",
			amount: "",
			createdAt: moment(new Date()).format("YYYY-MM-DD"),
			sortField: "_id",
			pageNumber: page || 1,
			pageSize: rowsPerPage || 10,
			sortOrder: -1,
		},
	});

	useEffect(() => {
		getUserData();
	}, []);

	useEffect(() => {
		getUserData({
			...filter.filterS,
			pageNumber: page || 1,
			pageSize: rowsPerPage || 10
		});
	}, [page, rowsPerPage]);

	useEffect(() => {
		console.log(transactionHistoryEnd)
		setTransactionHistory({ List: transactionHistoryEnd, totalCount: totalCount })
	}, [transactionHistoryEnd]);

	const handleFilterChange = (e) => {
		e.preventDefault();
		const { id, value } = e.target;
		setFilter((prevState) => ({
			...prevState,
			filterS: {
				...filter.filterS,
				[id]: value,
			},
		}));
	};
	const getUserData = (
		query = {
			...filter.filterS,
		},
	) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getTransactionHistory(query, (errors, res) => {

				if (res.data) {
					setTransactionHistory({ List: res.data.response, totalCount: res.data.totalCount })

				}
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};

	const handleDeleteTransaction = (list) => {
		if (window.confirm("Are you sure you want to Delete Transation?")) {
			// dispatch({ type: "LOADING_START" });
			// dispatch(
			// 	deleteTransactionHistoryWithId({ _id: list?._id }, (errors, res) => {
			// 		getUserData({
			// 			...filter.filterS,
			// 		});
			// 		dispatch({ type: "LOADING_SUCCESS" });
			// 	}),
			// );
		}
	};
	const handleDeleteMultiTransaction = (ids) => {
		if (window.confirm("Are you sure you want to Delete All selected transaction?")) {
			dispatch({ type: "LOADING_START" });
			dispatch(
				deleteTransactionHistoryWithId({ idsArray: ids }, (errors, res) => {
					getUserData({
						...filter.filterS,
					});
					dispatch({ type: "LOADING_SUCCESS" });
				}),
			);
		}
	};

	const selectRowForAction = (e, dt = []) => {
		// setIdsArray
		console.log(dt);
		if (dt.length > 0) {
			if (e.target.checked) {
				let ids = [];
				dt.forEach((item) => {
					ids.push(item._id);
				});
				ids.length > 0 && setIdsArray(ids);
			} else {
				setIdsArray([]);
			}
		} else if (dt._id) {
			let id = dt._id;
			// console.log(id);
			e.target.checked && setIdsArray((prev) => [...prev, id]);
			!e.target.checked && setIdsArray((prev) => prev.filter((i) => i !== id));
		}
	};

	console.log(idsArray);

	const handleLoopOverCheckbox = (list) => {
		// console.log(idsArray.filter((i) => i === list?._id));
		let da = idsArray.filter((i) => i === list?._id);
		return (
			<FormGroup check>
				<Label check>
					<Input
						type='checkbox'
						id='checkbox'
						className='form-control'
						checked={da[0] === list?._id ? true : false}
						defaultChecked={da === list?._id ? true : false}
						onChange={(e) => selectRowForAction(e, list)}
					/>
				</Label>
			</FormGroup>
		);
	};

	const status = {
		debit: "Debit",
		credit: "Credit",
	};
	const transaction_mode = {
		gpay: "Gpay",
		paytm: "Paytm",
		card: "Card",
		bets: "Bets",
	};
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - transactionHistorySet.List.length) : 0;
	return (
		<>
			{/* <Header /> */}
			{/* Page content */}
			<Container className='mt-7' fluid>
				{/* Dark table */}
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-default shadow'>
							<CardHeader className='bg-transparent border-0'>
								<h3 className='text-white mb-0'>Transaction History</h3>
								<div
									className='d-flex mt-2'
									style={{ flexDirection: "column" }}>
									<InputGroup size='xl' className='ml-2 w-100 mt-2'>
										<Input
											id='phone_number'
											type='text'
											name='phone_number'
											value={filter.filterS.phone_number || ""}
											onChange={handleFilterChange}
											placeholder='Search Phone Number'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											id='amount'
											type='number'
											name='amount'
											value={filter.filterS.amount || ""}
											onChange={handleFilterChange}
											placeholder='Search Amount'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											id='createdAt'
											type='date'
											name='createdAt'
											value={filter.filterS.createdAt || ""}
											onChange={handleFilterChange}
											placeholder='Search for Date'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='select'
											value={filter.filterS.transaction_type}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_type'
											name='transaction_type'
											required>
											<option key='select' value=''>
												Select Transaction Type
											</option>
											<option key='gpay' value='debit'>
												Debit
											</option>
											<option key='paytm' value='credit'>
												Credit
											</option>
										</Input>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='select'
											autoComplete='new-name'
											value={filter.filterS.transaction_mode}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_mode'
											placeholder='Select Payment Mothod'
											name='transaction_mode'
											required>
											<option key='select' value=''>
												Select Payment Mothod
											</option>
											<option key='gpay' value='gpay'>
												Gpay
											</option>
											<option key='paytm' value='paytm'>
												Paytm
											</option>
											<option key='card' value='card'>
												Card
											</option>
											<option key='bets' value='bets'>
												Bets
											</option>
											<option key='win' value='win'>
												Win
											</option>
											<option key='commission' value='commission'>
												Commission
											</option>
										</Input>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='Button'
											onClick={() =>
												getUserData({
													...filter.filterS,
													pageNumber: page || 1,
													pageSize: rowsPerPage || 10,
												})
											}
											className='bg-default text-white'
											value={"Search"}></Input>
									</InputGroup>
								</div>
							</CardHeader>
							<TableContainer>
								<Table className='align-items-center ' responsive>
									<TableHead>
										<TableRow>
											<TableCell className='text-white'>
												<FormGroup check>
													<Label check>
														<Input
															type='checkbox'
															id='checkbox2'
															className='form-control'
															onChange={(e) => {
																let dt =
																	transactionHistorySet &&
																		transactionHistorySet?.List?.length
																		? transactionHistorySet?.List
																		// 	?.slice(
																		// 	page *
																		// 	rowsPerPage,
																		// 	page *
																		// 	rowsPerPage +
																		// 	rowsPerPage,
																		// )
																		: transactionHistorySet?.List;
																selectRowForAction(e, dt);
															}}
														/>{" "}
													</Label>
												</FormGroup>
											</TableCell>
											<TableCell className='text-white'>
												Phone Number
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Payment Method
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Bofore
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Amount
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												wallet balance
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Payment Mode
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												UTR/ID
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Date
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Status
											</TableCell>
											{idsArray.length > 0 && (
												<Button
													className='bg-danger text-white'
													onClick={() =>
														handleDeleteMultiTransaction(
															idsArray,
														)
													}>
													Delete All
												</Button>
											)}
											<TableCell
												className='text-white'
												align='center'></TableCell>
											<TableCell className='text-white'></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{transactionHistorySet && transactionHistorySet?.List?.length
											? transactionHistorySet?.List
												// .slice(
												// 	page * rowsPerPage,
												// 	page * rowsPerPage + rowsPerPage,
												// )
												.map((list, index) => {
													return (
														<TableRow key={index}>
															<TableCell
																scope='row'
																align='center'>
																{handleLoopOverCheckbox(
																	list,
																)}
															</TableCell>
															<TableCell className='text-white'>
																{
																	list?.wallet_id
																		?.phone_number || list?.phone_number
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{
																	list?.transaction_mode
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{list?.current_amount?.toFixed(
																	2,
																)}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{list?.amount?.toFixed(
																	2,
																)}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{list?.after_amount?.toFixed(
																	2,
																)}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{
																	list?.transaction_type
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{
																	list?.transfer_number
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{moment(
																	list.updatedAt,
																).format(
																	"DD-MM-YYYY, h:mm a",
																)}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{
																	list.transaction_status
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																<Button
																	className='bg-danger text-white'
																	onClick={() =>
																		handleDeleteTransaction(
																			list,
																		)
																	}>
																	Delete
																</Button>
															</TableCell>
														</TableRow>
													);
												})
											: ""}
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td>
												Total :{" "}
												{transactionHistorySet &&
													transactionHistorySet?.List?.length &&
													transactionHistorySet?.List
														// ?.slice(
														// 	page * rowsPerPage,
														// 	page * rowsPerPage +
														// 	rowsPerPage,
														// )
														.reduce(
															(totalValue, item) =>
																totalValue +
																Number(item?.amount),
															0,
														)
														.toFixed(2)}
											</td>
										</tr>
										{/* {emptyRows > 0 && (
											<TableRow
												style={{
													height: 53 * emptyRows,
												}}>
												<TableCell colSpan={6} />
											</TableRow>
										)} */}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TablePagination
												className='text-white'
												rowsPerPageOptions={[
													5,
													10,
													25,
													50,
													100,
													{ label: "All", value: totalCount },
												]}
												colSpan={5}
												count={totalCount}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onRowsPerPageChange={
													handleChangeRowsPerPage
												}
											/>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
							{/* <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Payment Method</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Payment Mode</th>
                      <th scope="col">Date</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {transactionHistorySet && transactionHistorySet.length
                      ? transactionHistorySet.map((list, index) => {
                        return (
                          <tr key={index}>
                            <td>{list?.wallet_id?.phone_number}</td>
                            <td>{list?.transaction_mode}</td>
                            <td>{list?.amount}</td>
                            <td>{list?.transaction_type}</td>
                            <td>
                              {moment(list.updatedAt).format(
                                "DD-MM-YYYY, h:mm a"
                              )}
                            </td>
                          </tr>
                        );
                      })
                      : ""}
                  </tbody>
                </Table> */}
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

function mapStateToProps(state) {
	return {
		user: state.session.user,
		transactionHistory: state.transactionHistory,
	};
}

export default withRouter(connect(mapStateToProps, {})(TransactionsHistory));
