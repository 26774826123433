/** @format */

import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { Link, withRouter, useHistory } from "react-router-dom";
// reactstrap components
import {
	Card,
	CardHeader,
	Container,
	Row,
	UncontrolledDropdown,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Input,
	InputGroup,
	InputGroupAddon,
	Button,
	FormGroup,
	Label,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import {
	getTransactionHistory,
	getCommissionListAction,
	updateCommissionListAction,
	addMoneyToWallet,
} from "../../redux/actions";
import {
	TableBody,
	TableCell,
	TableRow,
	TableContainer,
	TablePagination,
	Table,
	TableHead,
	TableFooter,
} from "@material-ui/core";
import { toastr } from "react-redux-toastr";
import { deleteCommissionAction } from "redux/actions/transactionHistory";

const Commission = (props) => {
	// const { commissionHistory } = props.commissionHistory;
	const dispatch = useDispatch();
	const [commissionHistory, setCommissionHistory] = useState([]);
	const [addMoneyArray, setAddMoneyArray] = useState([]);

	const [page, setPage] = React.useState(0);
	const [idsArray, setIdsArray] = useState([]);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [totalCount, setTotalCount] = React.useState(0);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const [filter, setFilter] = useState({
		filterS: {
			transaction_type: "",
			transaction_mode: "",
			phone_number: "",
			amount: "",
			createdAt: "",
			status: "0",
			sortField: "_id",
			pageNumber: page || 1,
			pageSize: rowsPerPage || 10,
			sortOrder: -1,
		},
	});

	useEffect(() => {
		getCommissionData(
			{
				...filter.filterS,
				pageNumber: page || 1,
				pageSize: rowsPerPage || 10
			}
		);
	}, []);

	useEffect(() => {
		getCommissionData({
			...filter.filterS,
			pageNumber: page || 1,
			pageSize: rowsPerPage || 10
		});
	}, [page, rowsPerPage]);

	const handleFilterChange = (e) => {
		e.preventDefault();
		const { id, value } = e.target;
		setFilter((prevState) => ({
			...prevState,
			filterS: {
				...filter.filterS,
				[id]: value,
			},
		}));
	};
	const getCommissionData = (query = { status: "0" }) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getCommissionListAction(query, (errors, res) => {
				// console.log(res);
				if (res.data) {
					setCommissionHistory(res?.data?.response);
					setTotalCount(res?.data?.totalCount || 0)
				}
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};
	const updateStatusOfCommission = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			updateCommissionListAction(query, (errors, res) => {


				if (res.status === 200) {
					toastr.success("Success", "Commission updated successfully");
					getCommissionData(
						{
							...filter.filterS,
							pageNumber: page || 1,
							pageSize: rowsPerPage || 10
						}
					);
				}
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};

	const onDelete = (id) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			deleteCommissionAction(id._id, (errors, res) => {
				// console.log(res);
				dispatch({ type: "LOADING_SUCCESS" });
				if (res.status === 200) {
					toastr.success("Success", "Commission deleted successfully");
					getCommissionData({
						...filter.filterS,
						pageNumber: page || 1,
						pageSize: rowsPerPage || 10
					});
				}
			}),
		);
	};

	const onUpdate = (state, status) => {
		if (state?.length > 0) {
			updateStatusOfCommission({ idsArray: idsArray, status: status });
			dispatch(
				addMoneyToWallet(
					{
						idsArray: addMoneyArray,
					},
					(res, errors) => {
						getCommissionData({
							...filter.filterS,
							pageNumber: page || 1,
							pageSize: rowsPerPage || 10
						});
					},
				),
			);
		} else {
			let reqData = {
				wallet_id: state.wallet_id,
				amount: state.commission_amount,
				transaction_status: "pending",
				transaction_type: state.transaction_type,
				transaction_mode: state.transaction_mode,
			};
			updateStatusOfCommission({ _id: state._id, status: status });
			dispatch(
				addMoneyToWallet(reqData, (res, errors) => {
					getCommissionData({
						...filter.filterS,
						pageNumber: page || 1,
						pageSize: rowsPerPage || 10
					});
				}),
			);
		}
	};
	const status = {
		debit: "Debit",
		credit: "Credit",
	};
	const transaction_mode = {
		gpay: "Gpay",
		paytm: "Paytm",
		card: "Card",
		bets: "Bets",
	};

	const selectRowForAction = (e, dt = []) => {
		// setIdsArray

		// console.log(dt);
		if (dt.length > 0) {
			if (e.target.checked) {
				let ids = [];
				let AddM = [];
				dt.forEach((item) => {
					let addMoneyData = {
						wallet_id: item.wallet_id,
						amount: item.commission_amount,
						transaction_status: "pending",
						transaction_type: item.transaction_type,
						transaction_mode: item.transaction_mode,
					};
					if (item?.status === "0") {
						ids.push(item._id);
						AddM.push(addMoneyData);
					}
				});
				ids.length > 0 && setIdsArray(ids);
				ids.length > 0 && setAddMoneyArray(AddM);
			} else {
				setIdsArray([]);
				setAddMoneyArray([]);
			}
		} else if (dt._id) {
			let addMoneyData = {
				wallet_id: dt.wallet_id,
				amount: dt.commission_amount,
				transaction_status: "pending",
				transaction_type: dt.transaction_type,
				transaction_mode: dt.transaction_mode,
			};
			// console.log(id);
			let id = dt?._id;

			e.target.checked && setAddMoneyArray((prev) => [...prev, addMoneyData]);
			!e.target.checked && setAddMoneyArray((prev) => prev.filter((i) => i !== id));

			// ids below
			e.target.checked && setIdsArray((prev) => [...prev, id]);
			!e.target.checked && setIdsArray((prev) => prev.filter((i) => i !== id));
		}
	};

	// console.log(idsArray);
	// console.log(addMoneyArray);

	const handleLoopOverCheckbox = (list) => {
		// console.log(idsArray.filter((i) => i === list?._id));
		let da = idsArray.filter((i) => i === list?._id);
		return (
			<FormGroup check>
				<Label check>
					<Input
						type='checkbox'
						id='checkbox'
						className='form-control'
						checked={da[0] === list?._id ? true : false}
						defaultChecked={da === list?._id ? true : false}
						onChange={(e) => selectRowForAction(e, list)}
					/>
				</Label>
			</FormGroup>
		);
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - commissionHistory?.length) : 0;
	// console.log(filter.filterS, "FILTERS"),
	return (
		<>
			{/* <Header /> */}
			{/* Page content */}
			<Container className='mt-7' fluid>
				{/* Dark table */}
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-default shadow'>
							<CardHeader className='bg-transparent border-0 mt-7'>
								<h3 className='text-white mb-0'>Commission History</h3>
								<div
									className='d-flex mt-2'
									style={{ flexDirection: "column" }}>
									<InputGroup size='xl' className='ml-2 mt-2 w-100'>
										<Input
											id='phone_number'
											type='text'
											name='phone_number'
											value={filter.filterS.phone_number || ""}
											onChange={handleFilterChange}
											placeholder='Search Phone Number'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											id='amount'
											type='number'
											name='amount'
											value={filter.filterS.amount || ""}
											onChange={handleFilterChange}
											placeholder='Search Amount'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											id='createdAt'
											type='date'
											name='createdAt'
											value={filter.filterS.createdAt || ""}
											onChange={handleFilterChange}
											placeholder='Search for Date'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											type='select'
											value={filter.filterS.transaction_type}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_type'
											name='transaction_type'
											required>
											<option key='select' value=''>
												Select Transaction Type
											</option>
											<option key='gpay' value='debit'>
												Debit
											</option>
											<option key='paytm' value='credit'>
												Credit
											</option>
										</Input>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											type='select'
											value={filter.filterS.status}
											onChange={handleFilterChange}
											className='form-control'
											id='status'
											name='status'
											required>
											<option key='select' value=''>
												Select Status
											</option>
											<option key='0' value='0'>
												pending
											</option>
											<option key='1' value='1'>
												approved
											</option>
										</Input>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											type='select'
											autoComplete='new-name'
											value={filter.filterS.transaction_mode}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_mode'
											placeholder='Select Payment Mothod'
											name='transaction_mode'
											required>
											<option key='select' value=''>
												Select Payment Mothod
											</option>
											<option key='gpay' value='gpay'>
												Gpay
											</option>
											<option key='paytm' value='paytm'>
												Paytm
											</option>
											<option key='card' value='card'>
												Card
											</option>
											<option key='bets' value='bets'>
												Bets
											</option>
											<option key='win' value='win'>
												Win
											</option>
										</Input>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 mt-2 w-100 ml-2'>
										<Input
											type='Button'
											onClick={() =>
												getCommissionData({
													...filter.filterS,
													pageNumber: page || 1,
													pageSize: rowsPerPage || 10,
												})
											}
											className='bg-default text-white'
											value={"Search"}></Input>
									</InputGroup>
								</div>
							</CardHeader>
							<TableContainer>
								<Table className='align-items-center ' responsive>
									<TableHead>
										<TableRow>
											<TableCell className='text-white'>
												<FormGroup check>
													<Label check>
														<Input
															type='checkbox'
															id='checkbox2'
															className='form-control'
															onChange={(e) => {
																let dt =
																	commissionHistory &&
																		commissionHistory?.length
																		? commissionHistory.slice(
																			page *
																			rowsPerPage,
																			page *
																			rowsPerPage +
																			rowsPerPage,
																		)
																		: commissionHistory;
																selectRowForAction(e, dt);
															}}
														/>{" "}
													</Label>
												</FormGroup>
											</TableCell>
											<TableCell className='text-white'>
												Type
											</TableCell>
											<TableCell className='text-white'>
												percentage
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												status
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												commission
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												user
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Referred By
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Referred By Phone
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Bet Number
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Bet Amount
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Date
											</TableCell>
											<TableCell className='text-white'>
												{idsArray.length > 0 ? (
													<button
														className={"btn btn-success"}
														onClick={() =>
															onUpdate(addMoneyArray, "1")
														}>
														Approve
														{"(" + idsArray.length + ")"}
													</button>
												) : (
													"Action"
												)}
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{commissionHistory && commissionHistory?.length
											? commissionHistory
												// .slice(
												// 	page * rowsPerPage,
												// 	page * rowsPerPage + rowsPerPage,
												// )
												.map((list, index) => {
													return (
														<TableRow key={index}>
															<TableCell
																scope='row'
																align='center'>
																{/* {handleLoopOverCheckbox(
																		list,
																	)} */}
																{list?.status ===
																	"0" &&
																	handleLoopOverCheckbox(
																		list,
																	)}
															</TableCell>

															<TableCell className='text-white'>
																{
																	list?.transaction_mode
																}
															</TableCell>
															<TableCell className='text-white'>
																{
																	list?.commission_percentage
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{list?.status == "1"
																	? "Approved"
																	: "pending"}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{
																	list?.commission_amount.toFixed(2)
																}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{list?.user_name}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{list?.refered_by_id?.first_name}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{list?.refered_by_id?.phone_number}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{list?.bet_number}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{list?.bet_amount.toFixed(2)}
															</TableCell>
															<TableCell
																className='text-white'
																align='center'>
																{moment(
																	list.created_at,
																).format(
																	"DD-MM-YYYY, h:mm a",
																)}
															</TableCell>
															<TableCell>
																<React.Fragment>
																	{list?.status ===
																		"0" && (
																			<Fragment>
																				<button
																					className={
																						"btn btn-success"
																					}
																					onClick={() =>
																						onUpdate(
																							list,
																							"1",
																						)
																					}>
																					Approve
																				</button>

																				<button
																					className={
																						"btn btn-secondary"
																					}
																					style={{
																						marginLeft: 8,
																					}}
																					onClick={() =>
																						onUpdate(
																							list,
																							"0",
																						)
																					}>
																					Reject
																				</button>
																			</Fragment>
																		)}
																	<button
																		className={
																			"btn btn-danger"
																		}
																		style={{
																			marginLeft: 8,
																		}}
																		onClick={() =>
																			onDelete(
																				list,
																				"0",
																			)
																		}>
																		Delete
																	</button>
																</React.Fragment>
															</TableCell>
														</TableRow>
													);
												})
											: ""}
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td>
												Total :{" "}
												{commissionHistory &&
													commissionHistory.length &&
													commissionHistory
														// ?.slice(
														// 	page * rowsPerPage,
														// 	page * rowsPerPage +
														// 	rowsPerPage,
														// )
														.reduce(
															(totalValue, item) =>
																totalValue +
																Number(
																	item?.commission_amount,
																),
															0,
														)
														.toFixed(2)}
											</td>
										</tr>
										{/* {emptyRows > 0 && (
											<TableRow
												style={{
													height: 53 * emptyRows,
												}}>
												<TableCell colSpan={6} />
											</TableRow>
										)} */}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TablePagination
												className='text-white'
												rowsPerPageOptions={[
													5,
													10,
													25,
													50,
													100,
													200,
													500,
													{ label: "All", value: totalCount },
												]}
												colSpan={5}
												count={totalCount}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onRowsPerPageChange={
													handleChangeRowsPerPage
												}
											/>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
							{/* <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Payment Method</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Payment Mode</th>
                      <th scope="col">Date</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {commissionHistory && commissionHistory?.length
                      ? commissionHistory?.map((list, index) => {
                        return (
                          <tr key={index}>
                            <td>{list?.wallet_id?.phone_number}</td>
                            <td>{list?.transaction_mode}</td>
                            <td>{list?.amount}</td>
                            <td>{list?.transaction_type}</td>
                            <td>
                              {moment(list.updatedAt).format(
                                "DD-MM-YYYY, h:mm a"
                              )}
                            </td>
                          </tr>
                        );
                      })
                      : ""}
                  </tbody>
                </Table> */}
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

function mapStateToProps(state) {
	return {
		user: state.session.user,
		commissionHistory: state.commissionHistory,
	};
}

export default withRouter(connect(mapStateToProps, {})(Commission));
