/**
 * User Constants
 *
 * @format
 */

//LOGIN
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

//LOGOUT
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
//REFRESH
export const REFRESH_START = "REFRESH_START";
export const REFRESH_SUCCESS = "REFRESH_SUCCESS";
export const REFRESH_FAILURE = "REFRESH_FAILURE";

//USER REGISTRATION
export const USER_REGISTRATION_START = "USER_REGISTRATION_START";
export const USER_REGISTRATION_SUCCESS = "USER_REGISTRATION_SUCCESS";
export const USER_REGISTRATION_FAILURE = "USER_REGISTRATION_FAILURE";

//USER GET
export const USER_GET_START = "USER_GET_START";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_FAILURE = "USER_GET_FAILURE";

//USER GET
export const USER_DETAIL_GET_START = "USER_DETAIL_GET_START";
export const USER_DETAIL_GET_SUCCESS = "USER_DETAIL_GET_SUCCESS";
export const USER_DETAIL_GET_FAILURE = "USER_DETAIL_GET_FAILURE";

// user transactions get
export const USER_TRANSACTIONS_GET_START = "USER_TRANSACTIONS_GET_START";
export const USER_TRANSACTIONS_GET_SUCCESS = "USER_TRANSACTIONS_GET_SUCCESS";
export const USER_TRANSACTIONS_GET_FAILURE = "USER_TRANSACTIONS_GET_FAILURE";

//USER UPDATE
export const USER_UPDATE_START = "USER_UPDATE_START";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";

//USER UPDATE
export const ADD_MONEY_WALLET_START = "ADD_MONEY_WALLET_START";
export const ADD_MONEY_WALLET_SUCCESS = "ADD_MONEY_WALLET_SUCCESS";
export const ADD_MONEY_WALLET_FAILURE = "ADD_MONEY_WALLET_FAILURE";

// FORGOT PASSWORD
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const LOGOUT = "LOGOUT";

export const SELECTED_USER = "SELECTED_USER";

// GET WALLETS DATA
export const GET_WALLETS_START = "GET_WALLETS_START";
export const GET_WALLETS_SUCCESS = "GET_WALLETS_SUCCESS";
export const GET_WALLETS_FAILURE = "GET_WALLETS_FAILURE";
export const SET_WALLETS_DATA = "SET_WALLETS_DATA";

// ADD WALLETS DATA
export const ADD_WALLETS_START = "ADD_WALLETS_START";
export const ADD_WALLETS_SUCCESS = "ADD_WALLETS_SUCCESS";
export const ADD_WALLETS_FAILURE = "ADD_WALLETS_FAILURE";

// ACCEPT WALLETS DATA
export const ACCEPT_WALLETS_START = "ACCEPT_WALLETS_START";
export const ACCEPT_WALLETS_SUCCESS = "ACCEPT_WALLETS_SUCCESS";
export const ACCEPT_WALLETS_FAILURE = "ACCEPT_WALLETS_FAILURE";

// GET TRANSACTIONS_HISTORY DATA
export const GET_TRANSACTIONS_HISTORY_START = "GET_TRANSACTIONS_HISTORY_START";
export const GET_TRANSACTIONS_HISTORY_SUCCESS = "GET_TRANSACTIONS_HISTORY_SUCCESS";
export const GET_TRANSACTIONS_HISTORY_FAILURE = "GET_TRANSACTIONS_HISTORY_FAILURE";
// DELETE TRANSACTIONS_HISTORY DATA
export const DELETE_TRANSACTIONS_HISTORY_START = "DELETE_TRANSACTIONS_HISTORY_START";
export const DELETE_TRANSACTIONS_HISTORY_SUCCESS = "DELETE_TRANSACTIONS_HISTORY_SUCCESS";
export const DELETE_TRANSACTIONS_HISTORY_FAILURE = "DELETE_TRANSACTIONS_HISTORY_FAILURE";

// GET User Reqyest DATA
export const GET_USER_REQUEST_START = "GET_USER_REQUEST_START";
export const GET_USER_REQUEST_SUCCESS = "GET_USER_REQUEST_SUCCESS";
export const GET_USER_REQUEST_FAILURE = "GET_USER_REQUEST_FAILURE";

// GET Dashboard Reqyest DATA
export const GET_DASHBOARD_REQUEST_START = "GET_DASHBOARD_REQUEST_START";
export const GET_DASHBOARD_REQUEST_SUCCESS = "GET_DASHBOARD_REQUEST_SUCCESS";
export const GET_DASHBOARD_REQUEST_FAILURE = "GET_DASHBOARD_REQUEST_FAILURE";

// Update User Reqyest DATA
export const UPDATE_USER_REQUEST_START = "UPDATE_USER_REQUEST_START";
export const UPDATE_USER_REQUEST_SUCCESS = "UPDATE_USER_REQUEST_SUCCESS";
export const UPDATE_USER_REQUEST_FAILURE = "UPDATE_USER_REQUEST_FAILURE";

export const SET_TRANSACTIONS_HISTORY_DATA = "SET_TRANSACTIONS_HISTORY_DATA";

// GAMES DATA
export const GET_TODAY_RESULT_START = "GET_TODAY_RESULT_START";
export const GET_TODAY_RESULT_SUCCESS = "GET_TODAY_RESULT_SUCCESS";
export const GET_TODAY_RESULT_FAILURE = "GET_TODAY_RESULT_FAILURE";

export const GET_GAMES_START = "GET_GAMES_START";
export const GET_GAMES_SUCCESS = "GET_GAMES_SUCCESS";
export const GET_GAMES_FAILURE = "GET_GAMES_FAILURE";

export const GET_GAMES_RESULT_START = "GET_GAMES_RESULT_START";
export const GET_GAMES_RESULT_SUCCESS = "GET_GAMES_RESULT_SUCCESS";
export const GET_GAMES_RESULT_FAILURE = "GET_GAMES_RESULT_FAILURE";

// GAMES BETS DATA
export const GET_GAMES_BETS_START = "GET_GAMES_BETS_START";
export const GET_GAMES_BETS_SUCCESS = "GET_GAMES_BETS_SUCCESS";
export const GET_GAMES_BETS_FAILURE = "GET_GAMES_BETS_FAILURE";
// GAMES BETS DATA
export const GET_GAMES_BETS_START_WITH_USER = "GET_GAMES_BETS_START_WITH_USER";
export const GET_GAMES_BETS_SUCCESS_WITH_USER = "GET_GAMES_BETS_SUCCESS_WITH_USER";
export const GET_GAMES_BETS_FAILURE_WITH_USER = "GET_GAMES_BETS_FAILURE_WITH_USER";

//ADD GAMES
export const ADD_GAMES_START = "ADD_GAMES_START";
export const ADD_GAMES_SUCCESS = "ADD_GAMES_SUCCESS";
export const ADD_GAMES_FAILURE = "ADD_GAMES_FAILURE";

//UPDATE GAMES
export const UPDATE_GAMES_START = "UPDATE_GAMES_START";
export const UPDATE_GAMES_SUCCESS = "UPDATE_GAMES_SUCCESS";
export const UPDATE_GAMES_FAILURE = "UPDATE_GAMES_FAILURE";

// GAME_BETS DATA
export const GET_GAME_BETS_START = "GET_GAME_BETS_START";
export const GET_GAME_BETS_SUCCESS = "GET_GAME_BETS_SUCCESS";
export const GET_GAME_BETS_FAILURE = "GET_GAME_BETS_FAILURE";

export const ADD_GAME_BETS_START = "ADD_GAME_BETS_START";
export const ADD_GAME_BETS_SUCCESS = "ADD_GAME_BETS_SUCCESS";
export const ADD_GAME_BETS_FAILURE = "ADD_GAME_BETS_FAILURE";

export const UPDATE_GAME_BETS_START = "UPDATE_GAME_BETS_START";
export const UPDATE_GAME_BETS_SUCCESS = "UPDATE_GAME_BETS_SUCCESS";
export const UPDATE_GAME_BETS_FAILURE = "UPDATE_GAME_BETS_FAILURE";

// GAME_RESULTS DATA
export const GET_GAME_RESULTS_START = "GET_GAME_RESULTS_START";
export const GET_GAME_RESULTS_SUCCESS = "GET_GAME_RESULTS_SUCCESS";
export const GET_GAME_RESULTS_FAILURE = "GET_GAME_RESULTS_FAILURE";
// GAME_DELETE DATA
export const GET_GAME_DELETE_START = "GET_GAME_DELETE_START";
export const GET_GAME_DELETE_SUCCESS = "GET_GAME_DELETE_SUCCESS";
export const GET_GAME_DELETE_FAILURE = "GET_GAME_DELETE_FAILURE";

export const ADD_GAME_RESULTS_START = "ADD_GAME_RESULTS_START";
export const ADD_GAME_RESULTS_SUCCESS = "ADD_GAME_RESULTS_SUCCESS";
export const ADD_GAME_RESULTS_FAILURE = "ADD_GAME_RESULTS_FAILURE";

export const UPDATE_GAME_RESULTS_START = "UPDATE_GAME_RESULTS_START";
export const UPDATE_GAME_RESULTS_SUCCESS = "UPDATE_GAME_RESULTS_SUCCESS";
export const UPDATE_GAME_RESULTS_FAILURE = "UPDATE_GAME_RESULTS_FAILURE";

// GET SHORT_LIST_USER DATA
export const GET_SHORT_LIST_USER_START = "GET_SHORT_LIST_USER_START";
export const GET_SHORT_LIST_USER_SUCCESS = "GET_SHORT_LIST_USER_SUCCESS";
export const GET_SHORT_LIST_USER_FAILURE = "GET_SHORT_LIST_USER_FAILURE";

// ADD SHORT_LIST_USER DATA
export const ADD_SHORT_LIST_USER_START = "ADD_SHORT_LIST_USER_START";
export const ADD_SHORT_LIST_USER_SUCCESS = "ADD_SHORT_LIST_USER_SUCCESS";
export const ADD_SHORT_LIST_USER_FAILURE = "ADD_SHORT_LIST_USER_FAILURE";

// ADD SHORT_LIST_USER DATA
export const REMOVE_SHORT_LIST_USER_START = "REMOVE_SHORT_LIST_USER_START";
export const REMOVE_SHORT_LIST_USER_SUCCESS = "REMOVE_SHORT_LIST_USER_SUCCESS";
export const REMOVE_SHORT_LIST_USER_FAILURE = "REMOVE_SHORT_LIST_USER_FAILURE";

// GET MESSAGES
export const GET_MESSAGES_LIST_START = "GET_MESSAGES_LIST_START";
export const GET_MESSAGES_LIST_SUCCESS = "GET_MESSAGES_LIST_SUCCESS";
export const GET_MESSAGES_LIST_FAILURE = "GET_MESSAGES_LIST_FAILURE";

// ADD MESSAGES
export const ADD_MESSAGES_LIST_START = "ADD_MESSAGES_LIST_START";
export const ADD_MESSAGES_LIST_SUCCESS = "ADD_MESSAGES_LIST_SUCCESS";
export const ADD_MESSAGES_LIST_FAILURE = "ADD_MESSAGES_LIST_FAILURE";

// GET PRICING PLANS
export const GET_PLANS_LIST_START = "GET_PLANS_LIST_START";
export const GET_PLANS_LIST_SUCCESS = "GET_PLANS_LIST_SUCCESS";
export const GET_PLANS_LIST_FAILURE = "GET_PLANS_LIST_FAILURE";
export const SET_PLAN_DETAIL = "SET_PLAN_DETAIL";

// GET UPI LIST
export const GET_UPI_LIST_START = "GET_UPI_LIST_START";
export const GET_UPI_LIST_SUCCESS = "GET_UPI_LIST_SUCCESS";
export const GET_UPI_LIST_FAILURE = "GET_UPI_LIST_FAILURE";
export const SET_UPI_DETAIL = "SET_UPI_DETAIL";

// GET UPI LIST
export const CREATE_UPI_ID_START = "CREATE_UPI_ID_START";
export const CREATE_UPI_LIST_SUCCESS = "CREATE_UPI_LIST_SUCCESS";
export const CREATE_UPI_LIST_FAILURE = "CREATE_UPI_LIST_FAILURE";
export const SET_NEW_UPI_ID = "SET_NEW_UPI_ID";

// GET UPI LIST
export const DELETE_UPI_LIST_START = "DELETE_UPI_LIST_START";
export const DELETE_UPI_LIST_SUCCESS = "DELETE_UPI_LIST_SUCCESS";
export const DELETE_UPI_LIST_FAILURE = "DELETE_UPI_LIST_FAILURE";

// GET UPI LIST
export const UPDATE_UPI_LIST_START = "UPDATE_UPI_LIST_START";
export const UPDATE_UPI_LIST_SUCCESS = "UPDATE_UPI_LIST_SUCCESS";
export const UPDATE_UPI_LIST_FAILURE = "UPDATE_UPI_LIST_FAILURE";
// GET UPI LIST
export const UPDATE_NEWS_LIST_START = "UPDATE_NEWS_LIST_START";
export const UPDATE_NEWS_LIST_SUCCESS = "UPDATE_NEWS_LIST_SUCCESS";
export const UPDATE_NEWS_LIST_FAILURE = "UPDATE_NEWS_LIST_FAILURE";
// GET UPI LIST
export const GET_NEWS_LIST_START = "GET_NEWS_LIST_START";
export const GET_NEWS_LIST_SUCCESS = "GET_NEWS_LIST_SUCCESS";
export const GET_NEWS_LIST_FAILURE = "GET_NEWS_LIST_FAILURE";
// GET UPI LIST
export const CREATE_NEWS_LIST_START = "CREATE_NEWS_LIST_START";
export const CREATE_NEWS_LIST_SUCCESS = "CREATE_NEWS_LIST_SUCCESS";
export const CREATE_NEWS_LIST_FAILURE = "CREATE_NEWS_LIST_FAILURE";
// GET UPI LIST
export const CREATE_MESSAGES_LIST_START = "CREATE_MESSAGES_LIST_START";
export const CREATE_MESSAGES_LIST_SUCCESS = "CREATE_MESSAGES_LIST_SUCCESS";
export const CREATE_MESSAGES_LIST_FAILURE = "CREATE_MESSAGES_LIST_FAILURE";
// GET UPI LIST
export const UPDATE_MESSAGE_LIST_START = "UPDATE_MESSAGE_LIST_START";
export const UPDATE_MESSAGE_LIST_SUCCESS = "UPDATE_MESSAGE_LIST_SUCCESS";
export const UPDATE_MESSAGE_LIST_FAILURE = "UPDATE_MESSAGE_LIST_FAILURE";
// GET UPI LIST
export const GET_MESSAGE_LIST_START = "GET_MESSAGE_LIST_START";
export const GET_MESSAGE_LIST_SUCCESS = "GET_MESSAGE_LIST_SUCCESS";
export const GET_MESSAGE_LIST_FAILURE = "GET_MESSAGE_LIST_FAILURE";
// GET UPI LIST
export const GET_COMMISSION_LIST_START = "GET_COMMISSION_LIST_START";
export const GET_COMMISSION_LIST_SUCCESS = "GET_COMMISSION_LIST_SUCCESS";
export const GET_COMMISSION_LIST_FAILURE = "GET_COMMISSION_LIST_FAILURE";
// GET UPI LIST
export const UPDATE_COMMISSION_LIST_START = "UPDATE_COMMISSION_LIST_START";
export const UPDATE_COMMISSION_LIST_SUCCESS = "UPDATE_COMMISSION_LIST_SUCCESS";
export const UPDATE_COMMISSION_LIST_FAILURE = "UPDATE_COMMISSION_LIST_FAILURE";
// GET UPI LIST
export const GET_COMMISSION_DELETE_START = "GET_COMMISSION_DELETE_START";
export const GET_COMMISSION_DELETE_SUCCESS = "GET_COMMISSION_DELETE_SUCCESS";
export const GET_COMMISSION_DELETE_FAILURE = "GET_COMMISSION_DELETE_FAILURE";
// GET GAME BET DELETE LIST
export const GET_GAME_BET_DELETE_START = "GET_GAME_BET_DELETE_START";
export const GET_GAME_BET_DELETE_SUCCESS = "GET_GAME_BET_DELETE_SUCCESS";
export const GET_GAME_BET_DELETE_FAILURE = "GET_GAME_BET_DELETE_FAILURE";
