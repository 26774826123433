/** @format */

import React, { useState, useEffect } from "react";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import {
	getWallets,
	getUserRequest,
	updateUserRequest,
	addMoneyToWallet,
	getGameBets,
	getGamesBets,
	deleteGameBetsAction,
} from "../../redux/actions";
import { toastr } from "react-redux-toastr";

// reactstrap components
import {
	Card,
	CardHeader,
	Container,
	Row,
	Button,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Label,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { getTransactionHistory } from "../../redux/actions";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	TablePagination,
} from "@material-ui/core";

const AllBets = (props) => {
	// console.log(props, "props");
	const { walletsList } = props.wallets;
	let walletData = walletsList.filter((wallet) => {
		return wallet?.phone_number;
	});
	const filterOptions = createFilterOptions({
		ignoreCase: false,
		ignoreAccents: false,
	});
	const { transactionHistory, userRequests } = props.transactionHistory;
	// console.log(userRequests, "transactionHistory");
	const dispatch = useDispatch();
	let currentDate = moment().format("YYYY-MM-DD");
	let prevDay = moment().subtract(1, "day").format("YYYY-MM-DD");
	let prevTenDays = moment().subtract(10, "day").format("YYYY-MM-DD");
	let prev20Days = moment().subtract(20, "day").format("YYYY-MM-DD");
	let nextDay = moment().add(1, "day").format("YYYY-MM-DD");
	let startTime = moment(prevTenDays + "T06:00").format("HH:mm:ss");
	let endTime = moment(prevTenDays + "T05:30").format("HH:mm:ss");

	const [userData, setUserData] = useState(userRequests);
	const [idsArray, setIdsArray] = useState([]);
	const [gameBetsArray, setGameBetsArray] = useState([]);

	const [state, setState] = useState({
		wallet_id: "",
		transaction_mode: "commission",
		transaction_status: "pending",
		transaction_type: "credit",
		amount: null,
		password: null,
		submitted: false,
	});
	const [filter, setFilter] = useState({
		filterS: {
			transaction_mode: "",
			transaction_status: "",
			transaction_type: "",
			phone_number: "",
			amount: "",
			createdAt: moment(prev20Days + " " + startTime).format("YYYY-MM-DD HH:mm:ss"),
			endDate: moment(prevTenDays + " " + endTime).format("YYYY-MM-DD HH:mm:ss"),
			startDate: moment(prev20Days + " " + startTime).format("YYYY-MM-DD HH:mm:ss"),
		},
	});
	const [allGameBets, setAllGameBets] = useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// useEffect(() => {
	// 	getGameBetsData({ ...filter.filterS });
	// }, [filter.filterS]);

	useEffect(() => {
		// getUserData({ transaction_type: "credit", transaction_status: "" });
		// getUserData({ ...filter.filterS });
		getGameBetsData({
			...filter.filterS,
		});
		dispatch(
			getWallets({}, (errors, res) => {
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	}, []);
	const handleChange = (e) => {
		const { id, value, name } = e.target;
		if (name) {
			setState((prevState) => ({
				...prevState,
				[name]: value,
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				[id]: value,
			}));
		}
	};

	const onUpdate = (item, status) => {
		let updatData = {
			_id: item._id,
			role: "admin",
		};

		if (idsArray?.length > 0) {
			dispatch(
				deleteGameBetsAction(
					{
						role: "admin",
						idsArray: gameBetsArray,
					},
					(err, res) => {
						if (res.data.error) {
							toastr.success("error", res.data.message);
						} else {
							toastr.success("Success", "Delete Successfully", 400);
						}

						setIdsArray([]);
						setGameBetsArray([]);

						setState({
							submitted: false,
						});
						getGameBetsData({
							...filter.filterS,
						});
					},
				),
			);
		} else {
			dispatch(
				deleteGameBetsAction(updatData, (err, res) => {
					if (res.data.message) {
						toastr.success("error", res.data.message);
					} else {
						toastr.success("Success", "Delete Successfully", 400);
					}

					setIdsArray([]);
					setGameBetsArray([]);
					setState({
						submitted: false,
					});
					getGameBetsData({
						...filter.filterS,
					});
				}),
			);
		}
	};
	const getUserData = (query = {}) => {
		dispatch({ type: "LOADING_START" });
		dispatch(
			getUserRequest(query, (errors, res) => {
				let dat = res?.data?.filter((item) => {
					if (item?.transaction_mode === "commission") {
						return item;
					}
				});
				setUserData(dat);
				dispatch({ type: "LOADING_SUCCESS" });
			}),
		);
	};
	const status = {
		debit: "Debit",
		credit: "Credit",
	};
	const handleFilterChange = (e) => {
		e.preventDefault();
		const { id, value } = e.target;
		setFilter((prevState) => ({
			...prevState,
			filterS: {
				...filter.filterS,
				[id]: value,
			},
		}));
	};

	const getGameBetsData = (query = {}) => {
		dispatch(
			getGamesBets(query, (errors, res) => {
				console.log(res.data);
				setUserData(res?.data);
				setAllGameBets(res?.data);
			}),
		);
	};

	// const getGamesBetAmount = (query = {}) => {
	// 	// let totalAmountSpend = 0;
	// 	dispatch({ type: "LOADING_START" });
	// 	dispatch(
	// 		getGameBets(query, (errors, res) => {
	// 			console.log("getGamesBets=>", res);
	// 			// setAllGameBets(res?.data);
	// 			dispatch({ type: "LOADING_SUCCESS" });
	// 		}),
	// 	);

	// 	// return totalAmountSpend;
	// };

	const getuserNamefromWalletId = (list) => {
		let data =
			walletsList?.length > 0 &&
			walletsList?.filter((item) => item?._id === list?.wallet_id);

		var username = "";
		username =
			data?.length > 0 &&
			data[0]?.user_id?.first_name + " " + data[0].user_id?.last_name;
		return username || "NO FOUND";
	};

	const selectRowForAction = (e, dt = []) => {
		if (dt.length > 0) {
			if (e.target.checked) {
				let ids = [];
				let AddM = [];
				dt.forEach((item) => {
					let addMoneyReqData = {
						_id: item._id,
					};
					if (item?._id) {
						ids.push(item._id);
						AddM.push(addMoneyReqData);
					}
				});
				ids.length > 0 && setIdsArray(ids);
				AddM.length > 0 && setGameBetsArray(AddM);
			} else {
				setIdsArray([]);
				setGameBetsArray([]);
			}
		} else if (dt._id) {
			// console.log(dt);
			let addMoneyData = {
				_id: dt._id,
			};
			// console.log(id);
			let id = dt?._id;

			e.target.checked && setGameBetsArray((prev) => [...prev, addMoneyData]);
			!e.target.checked && setGameBetsArray((prev) => prev.filter((i) => i !== id));

			// ids below
			e.target.checked && setIdsArray((prev) => [...prev, id]);
			!e.target.checked && setIdsArray((prev) => prev.filter((i) => i !== id));
		}
	};

	const handleLoopOverCheckbox = (list) => {
		// console.log(idsArray.filter((i) => i === list?._id));
		let da = idsArray.filter((i) => i === list?._id);
		return (
			<FormGroup check>
				<Label check>
					<Input
						type='checkbox'
						id='checkbox'
						className='form-control'
						checked={da[0] === list?._id ? true : false}
						defaultChecked={da === list?._id ? true : false}
						onChange={(e) => selectRowForAction(e, list)}
					/>
				</Label>
			</FormGroup>
		);
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData.length) : 0;
	return (
		<>
			{/* <Header /> */}
			{/* Page content */}
			<Container className='mt-7' fluid>
				{/* Dark table */}
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-secondary shadow'>
							<CardHeader className='bg-transparent border-0 mt-7'>
								<h3 className='mb-0 text-center'>All Bets History</h3>
							</CardHeader>
							<CardBody>
								<Form role='form'></Form>
							</CardBody>
						</Card>
					</div>
				</Row>
				<Row className='mt-5'>
					<div className='col'>
						<Card className='bg-default shadow'>
							<CardHeader className='bg-transparent border-0'>
								<h3 className='text-white mb-0'>All Bets</h3>
								<div
									className='d-flex mt-2'
									style={{ flexDirection: "column" }}>
									<InputGroup size='xl' className='ml-2 w-100 mt-2'>
										<Input
											id='phone_number'
											type='text'
											name='phone_number'
											value={filter.filterS.phone_number || ""}
											onChange={handleFilterChange}
											placeholder='Search Phone Number'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											id='amount'
											type='number'
											name='amount'
											value={filter.filterS.amount || ""}
											onChange={handleFilterChange}
											placeholder='Search Amount'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											id='startDate'
											type='datetime-local'
											name='startDate'
											value={filter.filterS.startDate || ""}
											onChange={handleFilterChange}
											placeholder='Search for Date'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											id='endDate'
											disabled
											type='datetime-local'
											name='endDate'
											value={filter.filterS.endDate || ""}
											onChange={handleFilterChange}
											placeholder='Search for Date'
										/>
										<InputGroupAddon addonType='append'>
											<Button className='bg-default shadow'>
												<i className='fas fa-search text-white' />
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='select'
											value={filter.filterS.transaction_type}
											onChange={handleFilterChange}
											className='form-control'
											id='transaction_type'
											name='transaction_type'
											required>
											<option key='select' value=''>
												Select Transaction Type
											</option>
											<option key='debit' value='debit'>
												Debit
											</option>
											<option key='credit' value='credit'>
												Credit
											</option>
										</Input>
									</InputGroup>

									<InputGroup
										size='xl'
										className='ml-2 w-100 mt-2 ml-2'>
										<Input
											type='Button'
											onClick={() =>
												getGameBetsData({ ...filter.filterS })
											}
											className='bg-default text-white'
											value={"Search"}></Input>
									</InputGroup>
								</div>
							</CardHeader>
							<TableContainer>
								<Table
									className='align-items-center table-dark table-flush'
									responsive>
									<TableHead>
										<TableRow>
											<TableCell scope='row' align='center'>
												<FormGroup check>
													<Label check>
														<Input
															type='checkbox'
															id='checkbox2'
															className='form-control'
															onChange={(e) => {
																let dt =
																	userData &&
																	userData.length
																		? userData.slice(
																				page *
																					rowsPerPage,
																				page *
																					rowsPerPage +
																					rowsPerPage,
																		  )
																		: userData;
																selectRowForAction(e, dt);
															}}
														/>{" "}
													</Label>
												</FormGroup>
											</TableCell>
											<TableCell scope='row' align='center'>
												<span className='mb-0 text-sm text-white'>
													Name
												</span>
											</TableCell>
											<TableCell className='text-white'>
												Phone
											</TableCell>

											<TableCell
												className='text-white'
												align='center'>
												Before Bet
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Bet Amt
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Wallet
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Date
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Status
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												Action
											</TableCell>
											<TableCell
												className='text-white'
												align='center'>
												{idsArray.length > 0 ? (
													<button
														className={"btn btn-danger"}
														onClick={() =>
															onUpdate(gameBetsArray, "1")
														}>
														Delete{" "}
														{"(" + idsArray.length + ")"}
													</button>
												) : (
													"Action"
												)}
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{userData && userData.length
											? userData
													.slice(
														page * rowsPerPage,
														page * rowsPerPage + rowsPerPage,
													)
													.map((list, index) => {
														return (
															<TableRow key={index + 1}>
																<TableCell
																	scope='row'
																	align='center'>
																	<FormGroup check>
																		{list?._id &&
																			handleLoopOverCheckbox(
																				list,
																			)}
																	</FormGroup>
																</TableCell>
																<TableCell
																	scope='row'
																	align='center'>
																	<span className='mb-0 text-sm text-white'>
																		{walletsList?.length >
																			0 &&
																			getuserNamefromWalletId(
																				list,
																			)}
																	</span>
																</TableCell>
																<TableCell scope='row'>
																	<span className='mb-0 ml-2 text-sm text-white'>
																		{
																			list?.phone_number
																		}
																	</span>
																</TableCell>
																{/* <TableCell
																	className='text-white'
																	align='center'>
																	{
																		list?.user
																			?.user_name
																	}
																</TableCell> */}

																<TableCell
																	className='text-white'
																	align='center'>
																	{list?.current_amount}
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	{
																		list?.total_ammount_spend
																	}
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	{list?.after_amount}
																</TableCell>
																<TableCell
																	className='text-white'
																	align='center'>
																	{moment(
																		list?.createdAt,
																	).format(
																		"DD-MM-YYYY, h:mm a",
																	)}
																</TableCell>
																{list?.transaction_status ===
																"rejected" ? (
																	<TableCell
																		className='text-danger'
																		align='center'>
																		{
																			list?.transaction_status
																		}
																	</TableCell>
																) : list?.transaction_status ===
																  "pending" ? (
																	<TableCell
																		className='text-warning'
																		align='center'>
																		{
																			list?.transaction_status
																		}
																	</TableCell>
																) : (
																	<TableCell
																		className='text-success'
																		align='center'>
																		{
																			list?.transaction_status
																		}
																	</TableCell>
																)}
																<TableCell>
																	<React.Fragment>
																		{list.transaction_status ===
																			"pending" && (
																			<>
																				<button
																					className={
																						"btn btn-success"
																					}
																					onClick={() =>
																						onUpdate(
																							list,
																							"delete",
																						)
																					}>
																					Delete
																				</button>
																				<button
																					className={
																						"btn btn-secondary"
																					}
																					style={{
																						marginLeft: 8,
																					}}
																					onClick={() =>
																						onUpdate(
																							list,
																							"rejected",
																						)
																					}>
																					Reject
																				</button>
																			</>
																		)}
																		{list._id && (
																			<>
																				<button
																					className={
																						"btn btn-danger"
																					}
																					style={{
																						marginLeft: 8,
																					}}
																					onClick={() =>
																						onUpdate(
																							list,
																							"delete",
																						)
																					}>
																					DELETE
																				</button>
																			</>
																		)}
																	</React.Fragment>
																</TableCell>
															</TableRow>
														);
													})
											: ""}
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td>
												Total :{" "}
												{userData &&
													userData.length &&
													userData
														?.slice(
															page * rowsPerPage,
															page * rowsPerPage +
																rowsPerPage,
														)
														.reduce(
															(totalValue, item) =>
																totalValue +
																Number(
																	item?.total_ammount_spend,
																),
															0,
														)
														.toFixed(2)}
											</td>
										</tr>
										{emptyRows > 0 && (
											<TableRow
												style={{
													height: 53 * emptyRows,
												}}>
												<TableCell colSpan={6} />
											</TableRow>
										)}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TablePagination
												className='text-white'
												rowsPerPageOptions={[
													5,
													10,
													25,
													{ label: "All", value: -1 },
												]}
												colSpan={5}
												count={userData.length}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onRowsPerPageChange={
													handleChangeRowsPerPage
												}
											/>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

function mapStateToProps(state) {
	return {
		user: state.session.user,
		wallets: state.wallets,
		transactionHistory: state.transactionHistory,
	};
}

export default withRouter(connect(mapStateToProps, {})(AllBets));
